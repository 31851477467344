export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
};

export type AddressDetailsInput = {
  city: Scalars['String']['input'];
  flat: Scalars['String']['input'];
  home: Scalars['String']['input'];
  street: Scalars['String']['input'];
};

export type AddressDetailsType = {
  __typename?: 'AddressDetailsType';
  city: Scalars['String']['output'];
  flat: Scalars['String']['output'];
  home: Scalars['String']['output'];
  street: Scalars['String']['output'];
};

export type AddressType = {
  __typename?: 'AddressType';
  address: AddressDetailsType;
  country: UserCountry;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  isFavorite: Scalars['Boolean']['output'];
  isUsed: Scalars['Boolean']['output'];
};

export type AdminCreatePackageInput = {
  boxNumber?: InputMaybe<Scalars['Int']['input']>;
  shipmentId?: InputMaybe<Scalars['Int']['input']>;
  size?: InputMaybe<PackageSizeInput>;
  track: Scalars['String']['input'];
  weight?: InputMaybe<Scalars['Float']['input']>;
};

export type AdminLoginInput = {
  /** Non-empty string, max 16 symbols */
  login: Scalars['String']['input'];
  /** Non-empty string, max 16 symbols */
  password: Scalars['String']['input'];
};

export enum AdminRole {
  StockKeeper = 'stock_keeper',
  StoreKeeper = 'store_keeper',
  SuperAdmin = 'super_admin'
}

export type AdminType = {
  __typename?: 'AdminType';
  id: Scalars['Int']['output'];
  login: Scalars['String']['output'];
  role: AdminRole;
  stockId?: Maybe<Scalars['Int']['output']>;
};

export type AdminUpdatePackageInput = {
  boxNumber?: InputMaybe<Scalars['Int']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  deliveryAddressId?: InputMaybe<Scalars['Int']['input']>;
  deliveryCost?: InputMaybe<Scalars['Float']['input']>;
  deliveryRateId?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  insuranceCost?: InputMaybe<Scalars['Float']['input']>;
  isDamaged?: InputMaybe<Scalars['Boolean']['input']>;
  isPaid?: InputMaybe<Scalars['Boolean']['input']>;
  packageCount?: InputMaybe<Scalars['Int']['input']>;
  pickupPointId?: InputMaybe<Scalars['Int']['input']>;
  rateCost?: InputMaybe<Scalars['Float']['input']>;
  shipmentId?: InputMaybe<Scalars['Int']['input']>;
  size?: InputMaybe<PackageSizeInput>;
  status?: InputMaybe<DeliveryStatus>;
  storeStatus?: InputMaybe<StoreStatus>;
  weight?: InputMaybe<Scalars['Float']['input']>;
};

export type AuthPayloadType = {
  __typename?: 'AuthPayloadType';
  accessToken: Scalars['String']['output'];
  expiresIn?: Maybe<Scalars['DateTime']['output']>;
};

/** Buyout statuses */
export enum BuyoutStatus {
  Created = 'created',
  Done = 'done',
  InProgress = 'in_progress'
}

/** Buyout strategies */
export enum BuyoutStrategy {
  AnyPrice = 'any_price',
  StatedPrice = 'stated_price',
  UpTo_10 = 'up_to_10',
  UpTo_20 = 'up_to_20',
  UpTo_30 = 'up_to_30'
}

export type BuyoutSummaryInput = {
  cost: Scalars['Float']['input'];
  count: Scalars['Int']['input'];
  description: Scalars['String']['input'];
  link: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type BuyoutSummaryType = {
  __typename?: 'BuyoutSummaryType';
  cost: Scalars['Float']['output'];
  count: Scalars['Int']['output'];
  description: Scalars['String']['output'];
  link: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type BuyoutType = {
  __typename?: 'BuyoutType';
  created: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  status: BuyoutStatus;
  stock: StockType;
  strategy: BuyoutStrategy;
  summary: BuyoutSummaryType;
  user: UserType;
};

export type BuyoutsFilterInput = {
  q?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<BuyoutStatus>;
  stockId?: InputMaybe<Scalars['Int']['input']>;
  strategy?: InputMaybe<BuyoutStrategy>;
};

export type BuyoutsOrderInput = {
  createdAt?: InputMaybe<SortEnum>;
};

export type BuyoutsPaginationInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: BuyoutsOrderInput;
};

/** Package cargo type */
export enum CargoType {
  Cosmetic = 'cosmetic',
  Other = 'other',
  Spares = 'spares',
  Wear = 'wear'
}

export enum CompleteStatus {
  Incomplete = 'incomplete',
  StockCompleted = 'stock_completed',
  StoreCompleted = 'store_completed'
}

export type CreateAddressInput = {
  address: AddressDetailsInput;
  country: UserCountry;
};

export type CreateBuyoutInput = {
  stockId: Scalars['Int']['input'];
  strategy: BuyoutStrategy;
  summary: BuyoutSummaryInput;
};

export type CreatePackageInput = {
  cargoType?: InputMaybe<CargoType>;
  deliveryAddressId?: InputMaybe<Scalars['Int']['input']>;
  deliveryRateId: Scalars['Int']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  insured?: InputMaybe<Scalars['Boolean']['input']>;
  packageCost?: InputMaybe<Scalars['Float']['input']>;
  packageCount?: InputMaybe<Scalars['Int']['input']>;
  pickupPointId?: InputMaybe<Scalars['Int']['input']>;
  track: Scalars['String']['input'];
  weight?: InputMaybe<Scalars['Float']['input']>;
};

export type CreateShipmentInput = {
  stockId: Scalars['Int']['input'];
};

export type CreateUserInput = {
  captchaCode?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<UserCountry>;
  email: Scalars['String']['input'];
  govId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameEn?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type DeliveryRateType = {
  __typename?: 'DeliveryRateType';
  cost: Scalars['Int']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  stock: StockType;
  time: Scalars['String']['output'];
};

export type DeliveryRatesFilterInput = {
  stockId?: InputMaybe<Scalars['Int']['input']>;
};

/** Package delivery status */
export enum DeliveryStatus {
  Created = 'created',
  Done = 'done',
  InTransit = 'in_transit',
  ReadyForDelivery = 'ready_for_delivery'
}

export type EditAddressInput = {
  address?: InputMaybe<AddressDetailsInput>;
  country?: InputMaybe<UserCountry>;
  isFavorite?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ImportPackageInput = {
  packageCount?: InputMaybe<Scalars['Int']['input']>;
  size?: InputMaybe<PackageSizeInput>;
  track: Scalars['String']['input'];
  weight?: InputMaybe<Scalars['Float']['input']>;
};

export type ImportShipmentInput = {
  packages: Array<ImportPackageInput>;
};

export type LoginUserInput = {
  captchaCode?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  adminCreatePackage: PackageType;
  adminLogin: AuthPayloadType;
  adminUpdatePackage: PackageType;
  confirmPhone: AuthPayloadType;
  createAddress: AddressType;
  createBuyouts: Scalars['Int']['output'];
  createPackages: Scalars['Int']['output'];
  createShipment: ShipmentType;
  deleteAddress: Scalars['Boolean']['output'];
  deleteNotification: Scalars['Boolean']['output'];
  deletePackage: Scalars['Boolean']['output'];
  deleteShipment: Scalars['Boolean']['output'];
  editAddress: AddressType;
  importShipment: Scalars['Boolean']['output'];
  login: AuthPayloadType;
  readNotification: Scalars['Boolean']['output'];
  register: AuthPayloadType;
  resetPassword: AuthPayloadType;
  sendConfirmationCode: Scalars['Boolean']['output'];
  sendResetCode: Scalars['Boolean']['output'];
  updateBuyout: BuyoutType;
  updateDeliveryRate: DeliveryRateType;
  updatePackage: PackageType;
  updateShipment: ShipmentType;
  updateUser: UserType;
  updateUserProfile: UserType;
};


export type MutationAdminCreatePackageArgs = {
  input: AdminCreatePackageInput;
};


export type MutationAdminLoginArgs = {
  input: AdminLoginInput;
};


export type MutationAdminUpdatePackageArgs = {
  id: Scalars['Int']['input'];
  input: AdminUpdatePackageInput;
};


export type MutationConfirmPhoneArgs = {
  code: Scalars['String']['input'];
};


export type MutationCreateAddressArgs = {
  input: CreateAddressInput;
};


export type MutationCreateBuyoutsArgs = {
  input: Array<CreateBuyoutInput>;
};


export type MutationCreatePackagesArgs = {
  input: Array<CreatePackageInput>;
};


export type MutationCreateShipmentArgs = {
  input: CreateShipmentInput;
};


export type MutationDeleteAddressArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteNotificationArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeletePackageArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteShipmentArgs = {
  id: Scalars['Int']['input'];
};


export type MutationEditAddressArgs = {
  id: Scalars['Int']['input'];
  input: EditAddressInput;
};


export type MutationImportShipmentArgs = {
  id: Scalars['Int']['input'];
  input: ImportShipmentInput;
};


export type MutationLoginArgs = {
  input: LoginUserInput;
};


export type MutationReadNotificationArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationRegisterArgs = {
  input: CreateUserInput;
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};


export type MutationSendResetCodeArgs = {
  input: RequestResetCodeInput;
};


export type MutationUpdateBuyoutArgs = {
  id: Scalars['Int']['input'];
  input: UpdateBuyoutInput;
};


export type MutationUpdateDeliveryRateArgs = {
  id: Scalars['Int']['input'];
  input: UpdateDeliveryRateInput;
};


export type MutationUpdatePackageArgs = {
  id: Scalars['Int']['input'];
  input: UpdatePackageInput;
};


export type MutationUpdateShipmentArgs = {
  id: Scalars['Int']['input'];
  input: UpdateShipmentInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationUpdateUserProfileArgs = {
  id: Scalars['Int']['input'];
  input: UpdateUserInput;
};

export type NotificationType = {
  __typename?: 'NotificationType';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  read: Scalars['Boolean']['output'];
  text: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type NumberRangeInput = {
  max?: InputMaybe<Scalars['Float']['input']>;
  min?: InputMaybe<Scalars['Float']['input']>;
};

export type PackageSizeInput = {
  height?: InputMaybe<Scalars['Float']['input']>;
  length?: InputMaybe<Scalars['Float']['input']>;
  width?: InputMaybe<Scalars['Float']['input']>;
};

export type PackageSizeType = {
  __typename?: 'PackageSizeType';
  height?: Maybe<Scalars['Float']['output']>;
  length?: Maybe<Scalars['Float']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
};

export type PackageType = {
  __typename?: 'PackageType';
  boxNumber?: Maybe<Scalars['Int']['output']>;
  cargoType: CargoType;
  comment?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deliveryAddress?: Maybe<AddressType>;
  deliveryCost?: Maybe<Scalars['Float']['output']>;
  deliveryRate: DeliveryRateType;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  insuranceCost?: Maybe<Scalars['Float']['output']>;
  isDamaged: Scalars['Boolean']['output'];
  isPaid: Scalars['Boolean']['output'];
  packageCost?: Maybe<Scalars['Float']['output']>;
  packageCount?: Maybe<Scalars['Float']['output']>;
  pickupPoint?: Maybe<PickupPointType>;
  rateCost: Scalars['Int']['output'];
  shipment?: Maybe<ShipmentType>;
  size?: Maybe<PackageSizeType>;
  status: DeliveryStatus;
  storeStatus: StoreStatus;
  track: Scalars['String']['output'];
  user?: Maybe<UserType>;
  weight?: Maybe<Scalars['Float']['output']>;
};

export type PackagesFilterInput = {
  isPaid?: InputMaybe<Scalars['Boolean']['input']>;
  packageCount?: InputMaybe<NumberRangeInput>;
  q?: InputMaybe<Scalars['String']['input']>;
  shipmentId?: InputMaybe<Scalars['Int']['input']>;
  statuses?: InputMaybe<Array<DeliveryStatus>>;
  stockCode?: InputMaybe<StockCode>;
  stockId?: InputMaybe<Scalars['Int']['input']>;
  track?: InputMaybe<Scalars['String']['input']>;
  weight?: InputMaybe<NumberRangeInput>;
};

export type PackagesOrderInput = {
  createdAt?: InputMaybe<SortEnum>;
  deliveryCost?: InputMaybe<SortEnum>;
  insuranceCost?: InputMaybe<SortEnum>;
  packageCount?: InputMaybe<SortEnum>;
  rateCost?: InputMaybe<SortEnum>;
  weight?: InputMaybe<SortEnum>;
};

export type PackagesPaginationInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: PackagesOrderInput;
};

export type PackagesStatInput = {
  from: Scalars['DateTime']['input'];
  stockId?: InputMaybe<Scalars['Int']['input']>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PackagesStatType = {
  __typename?: 'PackagesStatType';
  activeBuyouts: Scalars['Int']['output'];
  summary: Array<PackagesSummaryType>;
  totalWeightInTransit: Scalars['Float']['output'];
  weightByDates: Array<PackagesWeightByDatesType>;
};

export type PackagesSummaryType = {
  __typename?: 'PackagesSummaryType';
  count: Scalars['Int']['output'];
  status: DeliveryStatus;
};

export type PackagesWeightByDatesType = {
  __typename?: 'PackagesWeightByDatesType';
  date: Scalars['String']['output'];
  weight: Scalars['Float']['output'];
};

export type PaginatedBuyoutsType = {
  __typename?: 'PaginatedBuyoutsType';
  items: Array<BuyoutType>;
  total: Scalars['Int']['output'];
};

export type PaginatedPackagesType = {
  __typename?: 'PaginatedPackagesType';
  items: Array<PackageType>;
  total: Scalars['Int']['output'];
};

export type PaginatedUsersType = {
  __typename?: 'PaginatedUsersType';
  items: Array<UserProfileType>;
  total: Scalars['Int']['output'];
};

export type PickupPointType = {
  __typename?: 'PickupPointType';
  country?: Maybe<UserCountry>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  useAddress: Scalars['Boolean']['output'];
};

export type PickupPointsFilterInput = {
  originStockId: Scalars['Int']['input'];
};

export type Query = {
  __typename?: 'Query';
  admin: AdminType;
  buyoutsList: PaginatedBuyoutsType;
  deliveryRatesList: Array<DeliveryRateType>;
  getPackage: PackageType;
  listAddresses: Array<AddressType>;
  me: UserType;
  notificationsList: Array<NotificationType>;
  packagesList: PaginatedPackagesType;
  packagesStat: PackagesStatType;
  packagesSummary: Array<PackagesSummaryType>;
  pickupPointsList: Array<PickupPointType>;
  shipmentStats: Array<ShipmentStatType>;
  shipmentsList: Array<ShipmentType>;
  stocksList: Array<StockType>;
  userProfileStat: UserProfileStatType;
  usersList: PaginatedUsersType;
  usersStat: UsersStatType;
};


export type QueryBuyoutsListArgs = {
  filter?: InputMaybe<BuyoutsFilterInput>;
  pagination?: BuyoutsPaginationInput;
};


export type QueryDeliveryRatesListArgs = {
  input: DeliveryRatesFilterInput;
};


export type QueryGetPackageArgs = {
  id: Scalars['Int']['input'];
};


export type QueryPackagesListArgs = {
  filter?: InputMaybe<PackagesFilterInput>;
  pagination?: PackagesPaginationInput;
};


export type QueryPackagesStatArgs = {
  filter: PackagesStatInput;
};


export type QueryPickupPointsListArgs = {
  input: PickupPointsFilterInput;
};


export type QueryShipmentStatsArgs = {
  id: Scalars['Int']['input'];
};


export type QueryShipmentsListArgs = {
  filter?: ShipmentsListFilterInput;
};


export type QueryUserProfileStatArgs = {
  id: Scalars['Int']['input'];
};


export type QueryUsersListArgs = {
  filter?: InputMaybe<UsersFilterInput>;
  pagination?: UsersPaginationInput;
};


export type QueryUsersStatArgs = {
  input: UsersStatInput;
};

export type RequestResetCodeInput = {
  email: Scalars['String']['input'];
};

export type ResetPasswordInput = {
  code: Scalars['ID']['input'];
  password: Scalars['String']['input'];
};

export type ShipmentStatType = {
  __typename?: 'ShipmentStatType';
  count: Scalars['Int']['output'];
  status: StoreStatus;
};

export type ShipmentType = {
  __typename?: 'ShipmentType';
  completeStatus: CompleteStatus;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  isArchive: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export type ShipmentsListFilterInput = {
  isArchive?: Scalars['Boolean']['input'];
  stockId?: InputMaybe<Scalars['Int']['input']>;
};

export enum SortEnum {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type StockAddressType = {
  __typename?: 'StockAddressType';
  addressFirst: Scalars['String']['output'];
  addressSecond?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  suite?: Maybe<Scalars['String']['output']>;
  zipCode: Scalars['String']['output'];
};

/** Stock code */
export enum StockCode {
  Eu = 'eu',
  Kr = 'kr',
  Us = 'us'
}

export type StockType = {
  __typename?: 'StockType';
  address: StockAddressType;
  code: StockCode;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

/** Package store status */
export enum StoreStatus {
  Checked = 'checked',
  Unchecked = 'unchecked'
}

export type UpdateBuyoutInput = {
  status: BuyoutStatus;
};

export type UpdateDeliveryRateInput = {
  cost?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  time?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePackageInput = {
  deliveryAddressId?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  pickupPointId?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateShipmentInput = {
  isArchive?: InputMaybe<Scalars['Boolean']['input']>;
  isCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUserInput = {
  country?: InputMaybe<UserCountry>;
  email?: InputMaybe<Scalars['String']['input']>;
  govId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameEn?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

/** User registration country */
export enum UserCountry {
  Kg = 'kg',
  Kz = 'kz',
  Ru = 'ru'
}

export type UserProfileStatType = {
  __typename?: 'UserProfileStatType';
  inTransitPackages: Scalars['Int']['output'];
  inTransitWeight: Scalars['Float']['output'];
  totalPackages: Scalars['Int']['output'];
  totalWeight: Scalars['Float']['output'];
};

export type UserProfileType = {
  __typename?: 'UserProfileType';
  country?: Maybe<UserCountry>;
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  govId?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  nameEn?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
};

export type UserType = {
  __typename?: 'UserType';
  country?: Maybe<UserCountry>;
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  govId?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isEmailConfirmed: Scalars['Boolean']['output'];
  isPhoneConfirmed: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  nameEn?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
};

export type UsersCountriesType = {
  __typename?: 'UsersCountriesType';
  count: Scalars['Int']['output'];
  country: UserCountry;
};

export type UsersFilterInput = {
  q?: InputMaybe<Scalars['String']['input']>;
};

export type UsersOrderInput = {
  createdAt?: InputMaybe<SortEnum>;
};

export type UsersPaginationInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: UsersOrderInput;
};

export type UsersStatInput = {
  from: Scalars['DateTime']['input'];
  to?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UsersStatType = {
  __typename?: 'UsersStatType';
  countries: Array<UsersCountriesType>;
  onlineInPeriod: Scalars['Int']['output'];
  registrationInPeriod: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};
