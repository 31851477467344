import styled from 'styled-components';

export const MenuWrapper = styled.div`
  .Card {
    background: var(--color-bg-default);
    height: 43px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    max-width: 750px;
    min-width: 650px;
    .che--Menu {
      width: 95%;
      ul {
        justify-content: space-between;
      }
    }
  }

  .che--Menu-Item_active:before {
    display: none;
  }
`;
