import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeliveryRatesListQueryVariables = Types.Exact<{
  input: Types.DeliveryRatesFilterInput;
}>;


export type DeliveryRatesListQuery = { __typename?: 'Query', deliveryRatesList: Array<{ __typename?: 'DeliveryRateType', id: number, name: string, cost: number, time: string, description?: string | null, stock: { __typename?: 'StockType', id: number, name: string, code: Types.StockCode, address: { __typename?: 'StockAddressType', suite?: string | null, addressFirst: string, addressSecond?: string | null, city: string, state?: string | null, zipCode: string, country: string, phone?: string | null } } }> };


export const DeliveryRatesListDocument = gql`
    query deliveryRatesList($input: DeliveryRatesFilterInput!) {
  deliveryRatesList(input: $input) {
    id
    name
    cost
    time
    description
    stock {
      id
      name
      code
      address {
        suite
        addressFirst
        addressSecond
        city
        state
        zipCode
        country
        phone
      }
    }
  }
}
    `;

/**
 * __useDeliveryRatesListQuery__
 *
 * To run a query within a React component, call `useDeliveryRatesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeliveryRatesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeliveryRatesListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeliveryRatesListQuery(baseOptions: Apollo.QueryHookOptions<DeliveryRatesListQuery, DeliveryRatesListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeliveryRatesListQuery, DeliveryRatesListQueryVariables>(DeliveryRatesListDocument, options);
      }
export function useDeliveryRatesListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeliveryRatesListQuery, DeliveryRatesListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeliveryRatesListQuery, DeliveryRatesListQueryVariables>(DeliveryRatesListDocument, options);
        }
export type DeliveryRatesListQueryHookResult = ReturnType<typeof useDeliveryRatesListQuery>;
export type DeliveryRatesListLazyQueryHookResult = ReturnType<typeof useDeliveryRatesListLazyQuery>;
export type DeliveryRatesListQueryResult = Apollo.QueryResult<DeliveryRatesListQuery, DeliveryRatesListQueryVariables>;