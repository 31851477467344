import { Navigate, useLocation } from 'react-router-dom';
import { Loader } from '@consta/uikit/Loader';
import { useUser } from 'entities/user';
import { useAdmin } from 'entities/admin';
import { AdminRole } from 'types';
import { getAuthToken } from 'shared/api/auth';

function RedirectToLogin() {
  const location = useLocation();

  return <Navigate to='/login' state={{ from: location }} replace />;
}

function RedirectToDetailed() {
  const location = useLocation();

  return <Navigate to='/detailed-information' state={{ from: location }} replace />;
}

function RedirectToPhoneConfirmation() {
  const location = useLocation();

  return <Navigate to='/confirm-phone' state={{ from: location }} replace />;
}

export function RequireAuth({ children }: { children: JSX.Element }) {
  const { user, isAuth, loading } = useUser();
  const token = getAuthToken();

  return isAuth
    ? (!user?.name || !user?.nameEn || !user?.phone
      ? <RedirectToDetailed />
      : !user?.isPhoneConfirmed
        ? <RedirectToPhoneConfirmation />
        : children
    )
    : (loading || token ? <Loader /> : <RedirectToLogin />);
}

function RedirectToMainPage() {
  const location = useLocation();

  return <Navigate to='/' state={{ from: location }} replace />;
}

export function RequireNotAuth({ children }: { children: JSX.Element }) {
  const { isAuth, loading } = useUser();
  const token = getAuthToken();

  return isAuth ? <RedirectToMainPage /> : (loading || token ? <Loader /> : children);
}

export function RequireToken({ children }: { children: JSX.Element }) {
  const { isAuth, loading } = useUser();
  const token = getAuthToken();

  return isAuth ? children : (loading || token ? <Loader /> : <RedirectToLogin />);
}

function RedirectToStockKeeper() {
  const location = useLocation();

  return <Navigate to='/stock-keeper-packages' state={{ from: location }} replace />;
}

function RedirectToStoreKeeper() {
  const location = useLocation();

  return <Navigate to='/store-keeper-packages' state={{ from: location }} replace />;
}

export function RequireSuperAdmin({ children }: { children: JSX.Element }) {
  const { isAuth, loading, admin } = useAdmin();
  const token = getAuthToken();

  if (isAuth && admin?.role) {
    switch (admin.role) {
      case AdminRole.StockKeeper:
        return <RedirectToStockKeeper />;
      case AdminRole.StoreKeeper:
        return <RedirectToStoreKeeper />;
      default:
        return children;
    }
  } else {
    return loading || token ? <Loader /> : <RedirectToLogin />;
  }
}

export function RequireStockKeeper({ children }: { children: JSX.Element }) {
  const { isAuth, loading, admin } = useAdmin();
  const token = getAuthToken();

  if (isAuth && admin?.role) {
    switch (admin.role) {
      case AdminRole.SuperAdmin:
        return <RedirectToMainPage />;
      case AdminRole.StoreKeeper:
        return <RedirectToStoreKeeper />;
      default:
        return children;
    }
  } else {
    return loading || token ? <Loader /> : <RedirectToLogin />;
  }
}

export function RequireStoreKeeper({ children }: { children: JSX.Element }) {
  const { isAuth, loading, admin } = useAdmin();
  const token = getAuthToken();

  if (isAuth && admin?.role) {
    switch (admin.role) {
      case AdminRole.SuperAdmin:
        return <RedirectToMainPage />;
      case AdminRole.StockKeeper:
        return <RedirectToStockKeeper />;
      default:
        return children;
    }
  } else {
    return loading || token ? <Loader /> : <RedirectToLogin />;
  }
}
