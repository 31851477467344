import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import Header from 'features/admin-header';
import { PageContainer, ContentContainer } from './styled';

const Layout: FC = () => {
  const items = [
    {
      label: 'Пользователи',
      href: '/users',
      active: location?.pathname === '/users',
    },
    {
      label: 'Формирование партии',
      href: '/shipments',
      active: location?.pathname === '/shipments',
    },
    {
      label: 'Посылки США / Европа',
      href: '/packages?country=usa-europe',
      active: location?.pathname === '/packages?country=usa-europe',
    },
    {
      label: 'Посылки Корея',
      href: '/packages?country=korea',
      active: location?.pathname === '/packages?country=korea',
    },
    {
      label: 'Аналитика',
      href: '/analytics',
      active: location?.pathname === '/analytics',
    },
    {
      label: 'Выкуп',
      href: '/repurchase',
      active: location?.pathname === '/repurchase',
    },
    {
      label: 'Тарифы',
      href: '/delivery-rates',
      active: location?.pathname === '/delivery-rates',
    },
  ];

  return (
    <PageContainer>
      <Header items={items} />
      <ContentContainer>
        <Outlet />
      </ContentContainer>
      <div id='footer' style={{ height: '40px' }} />
    </PageContainer>
  );
};

export default Layout;
