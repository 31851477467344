import styled from 'styled-components';

export const NotificationsBtnWrapper = styled.div`
  span {
    content: "";
    height: 32px;
    width: 32px;
    border-radius: 50%;
    transition: all 0.4s;
    cursor: pointer;
  }
  span.no_notifications_icon {
    color: rgba(250, 250, 250, 0.5);
    &:hover {
      background: rgba(255, 255, 255, 0.2);
      color: rgba(255, 255, 255, 0.75);
    }
  }
  svg {
    height: 16px;
  }
`;
