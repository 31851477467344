import styled from 'styled-components';

export const ScannerCardWrapper = styled.div`
  .Card {
    overflow: hidden;
    border-radius: 16px;
    background: var(--color-bg-default);
    width: 100%;
    max-width: 744px;
    margin: 0 auto;
    position: relative;
    min-height: 483px;

    .emptyLabel {
      margin: 150px auto;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .container {
      position: absolute;
    }
    
    .container,
    #interactive.viewport {
      width: 100%;
      max-width: 744px;
      height: 483px;
      left: 0;
      top: 0;
    }
    
    #interactive.viewport canvas,
    video {
      width: 100%;
      max-width: 744px;
      height: 483px;
      position: absolute;
      top: 0;
      left: 0;
    }
    
    #interactive.viewport canvas.drawingBuffer,
    video.drawingBuffer {
      width: 100%;
      max-width: 744px;
      height: 483px;
    }

    .flagWrapper {
      width: 80px !important;
      height: 60px !important;

      @media (max-width: 640px) {
        position: absolute;
        right: 12px;
        top: 16px;
      }
    }

    .Checkbox {
      .Checkbox-Input {
        border-radius: 4px;
        border-color: var(--color-typo-alert) !important;

        &:checked {
          background-color: var(--color-typo-alert) !important;

          &::before {
            border-color: var(--color-typo-primary) !important;
          }
        }
      }

      .Checkbox-Label {
        color: var(--color-typo-alert) !important;
      }
    }

    .GridItem {
      position: relative;

      .finishButton {
        position: absolute;
        bottom: 32px;
        right: 0;
      }

      @media (max-width: 640px) {
        position: static;

        .finishButton {
          position: relative;
          bottom: 0;
          right: 0;
          width: 100%;
        }
      }
    }
  }
`;