import { Text } from '@consta/uikit/Text';
import { Button } from '@consta/uikit/Button';
import { Grid, GridItem } from '@consta/uikit/Grid';
import { TextField } from '@consta/uikit/TextField';
import { useEffect, useMemo, useState } from 'react';
import ConfirmModal from 'entities/ui/confirmModal';
import { useAdminUpdatePackageMutation } from 'features/packages/api/adminUpdatePackage.generated';
import {
  ShipmentPackageDataType,
  getShipmentPackageInfoRows,
} from 'features/admin-package-row/utils';
import Margin from 'shared/ui/components/margin';
import Row from 'shared/ui/components/row';
import { InfoWithLabel } from 'shared/ui/components/infoWithLabel';
import { EditShipmentPackageWrapper, ModalHeader } from './styled';

const ShipmentPackageEditForm = ({
  package: packageData,
  onCloseModal,
}: {
  package: ShipmentPackageDataType | null;
  onCloseModal: () => void;
}) => {
  const [weight, setWeight] = useState<number | null>();
  const [length, setLength] = useState<number | null>();
  const [width, setWidth] = useState<number | null>();
  const [height, setHeight] = useState<number | null>();
  const [packageCount, setPackageCount] = useState<number | null>();
  const [formEdited, setFormEdited] = useState(false);

  const [weightError, setWeightError] = useState(false);
  const [lengthError, setLengthError] = useState(false);
  const [widthError, setWidthError] = useState(false);
  const [heightError, setHeightError] = useState(false);
  const [packageCountError, setPackageCountError] = useState(false);

  const [isConfirmCloseModalOpen, setIsConfirmCloseModalOpen] = useState(false);
  const [isConfirmSaveModalOpen, setIsConfirmSaveModalOpen] = useState(false);
  const [confirmModalData, setConfirmModalData] = useState({
    title: '',
    body: '',
    submitTitle: '',
  });

  const isDataCorrect = useMemo(() => {
    return [weight, length, height, packageCount].every(v => v !== null);
  }, [weight, length, height, packageCount]);

  const handleWeightChange = (value: number | null) => {
    setWeight(value);
    setFormEdited(true);
  };

  const handleWeightBlur = () => {
    setWeightError(!weight);
  };

  const handleLengthChange = (value: number | null) => {
    setLength(value);
    setFormEdited(true);
  };

  const handleLengthBlur = () => {
    setLengthError(!length);
  };

  const handleWidthChange = (value: number | null) => {
    setWidth(value);
    setFormEdited(true);
  };

  const handleWidthBlur = () => {
    setWidthError(!width);
  };

  const handleHeightChange = (value: number | null) => {
    setHeight(value);
    setFormEdited(true);
  };

  const handleHeightBlur = () => {
    setHeightError(!height);
  };

  const handlePackageCountChange = (value: number | null) => {
    setPackageCount(value);
    setFormEdited(true);
  };

  const handlePackageCountBlur = () => {
    setPackageCountError(!packageCount);
  };

  const setInitialPackageData = () => {
    if (packageData && packageData.id) {
      setWeight(packageData.weight);
      setLength(packageData.length);
      setWidth(packageData.width);
      setHeight(packageData.height);
      setPackageCount(packageData.packageCount);
    }
  };

  const clearData = () => {
    setWeight(null);
    setLength(null);
    setWidth(null);
    setHeight(null);
    setPackageCount(null);
  };

  const closeModal = () => {
    if (formEdited) {
      setConfirmModalData({
        title: 'Последние изменения не сохранены. Закрыть панель?',
        body: '',
        submitTitle: 'Да',
      });
      setIsConfirmCloseModalOpen(true);
    } else {
      clearData();
      onCloseModal();
    }
  };

  const onCloseConfirmModal = () => {
    setIsConfirmCloseModalOpen(false);
    setIsConfirmSaveModalOpen(false);
  };

  const onSubmitConfirmModal = () => {
    if (isConfirmSaveModalOpen) {
      onSubmit();
    }
    setIsConfirmCloseModalOpen(false);
    setIsConfirmSaveModalOpen(false);

    if (isConfirmCloseModalOpen) {
      clearData();
      onCloseModal();
    }
  };

  const showConfirmModal = () => {
    setConfirmModalData({
      title: 'Вы действительно хотите внести изменения?',
      body: '',
      submitTitle: 'Да',
    });
    setIsConfirmSaveModalOpen(true);
  };

  useEffect(() => {
    setInitialPackageData();
  }, [packageData]);

  const [updatePackage] = useAdminUpdatePackageMutation();

  const onSubmit = () => {
    updatePackage({
      variables: {
        id: Number(packageData?.id),
        input: {
          weight,
          size: {
            width,
            height,
            length,
          },
          packageCount,
        },
      },
    });
    setFormEdited(false);
  };

  return (
    <>
      <ModalHeader>
        <Text size='xl' view='primary' weight='medium'>
          Информация о посылке
        </Text>
        <Row justify='center' align='center'>
          <Button
            label='Сохранить'
            view='primary'
            className='buttonBlack'
            size='s'
            onlyIcon
            disabled={!isDataCorrect}
            onClick={showConfirmModal}
          />
          <Margin ml={8}>
            <Button
              label='Закрыть'
              view='ghost'
              size='s'
              onlyIcon
              onClick={() => closeModal()}
            />
          </Margin>
        </Row>
      </ModalHeader>
      <EditShipmentPackageWrapper>
        <Grid cols={2}>
          <GridItem col={1}>
            <TextField
              label='ВЕС КГ'
              type='number'
              placeholder='Ввведите значение'
              form='clearClear'
              width='full'
              size='m'
              required
              incrementButtons={false}
              value={weight && isNaN(weight) ? '' : `${weight}`}
              onChange={({ value }) =>
                handleWeightChange(Number(value) || null)
              }
              onBlur={handleWeightBlur}
              status={weightError ? 'alert' : undefined}
              caption={weightError ? 'Поле не заполнено' : undefined}
            />
            <TextField
              label='ДЛИНА (Д)'
              type='number'
              placeholder='Ввведите значение'
              form='clearClear'
              width='full'
              size='m'
              required
              incrementButtons={false}
              value={length && isNaN(length) ? '' : `${length}`}
              onChange={({ value }) =>
                handleLengthChange(Number(value) || null)
              }
              onBlur={handleLengthBlur}
              status={lengthError ? 'alert' : undefined}
              caption={lengthError ? 'Поле не заполнено' : undefined}
            />
            <TextField
              label='ШИРИНА (Д)'
              type='number'
              placeholder='Ввведите значение'
              form='clearClear'
              width='full'
              size='m'
              required
              incrementButtons={false}
              value={width && isNaN(width) ? '' : `${width}`}
              onChange={({ value }) => handleWidthChange(Number(value) || null)}
              onBlur={handleWidthBlur}
              status={widthError ? 'alert' : undefined}
              caption={widthError ? 'Поле не заполнено' : undefined}
            />
            <TextField
              label='Высота (Д)'
              type='number'
              placeholder='Ввведите значение'
              form='clearClear'
              width='full'
              size='m'
              required
              incrementButtons={false}
              value={height && isNaN(height) ? '' : `${height}`}
              onChange={({ value }) =>
                handleHeightChange(Number(value) || null)
              }
              onBlur={handleHeightBlur}
              status={heightError ? 'alert' : undefined}
              caption={heightError ? 'Поле не заполнено' : undefined}
            />
            <TextField
              label='Количество мест'
              type='number'
              placeholder='Ввведите значение'
              form='clearClear'
              width='full'
              size='m'
              required
              incrementButtons={false}
              value={
                packageCount && isNaN(packageCount) ? '' : `${packageCount}`
              }
              onChange={({ value }) =>
                handlePackageCountChange(Number(value) || null)
              }
              onBlur={handlePackageCountBlur}
              status={packageCountError ? 'alert' : undefined}
              caption={packageCountError ? 'Поле не заполнено' : undefined}
            />
          </GridItem>
          <GridItem col={1}>
            {getShipmentPackageInfoRows({ packageData }).map((row, index) => {
              return (
                <InfoWithLabel
                  key={index}
                  title={row.title}
                  value={row.value}
                />
              );
            })}
          </GridItem>
        </Grid>
      </EditShipmentPackageWrapper>
      <ConfirmModal
        isModalOpen={isConfirmCloseModalOpen || isConfirmSaveModalOpen}
        title={confirmModalData.title}
        body={confirmModalData.body}
        submitTitle={confirmModalData.submitTitle}
        onClose={onCloseConfirmModal}
        onSubmit={onSubmitConfirmModal}
        closeButtonTitle='Отмена'
        customMaxWidth={600}
      />
    </>
  );
};

export default ShipmentPackageEditForm;
