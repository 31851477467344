import { Button } from '@consta/uikit/Button';
import { useNavigate } from 'react-router-dom';
import NavigationMenu, { NavigationMenuItem } from 'entities/ui/navigationMenu';
import { CustomHeader } from 'features/header/styled';
import { useAdmin } from 'entities/admin';
import { AdminRole } from 'types';
import { removeAuthToken } from 'shared/api/auth';
import logo from '../../assets/footer-logo.png';

const Header = ({
  items,
}: {
  items: NavigationMenuItem[];
}) => {
  const navigate = useNavigate();

  const { admin } = useAdmin();

  const onLogout = () => {
    removeAuthToken();
    navigate('/login');
  };

  return (
    <CustomHeader
      rowCenter={{
        left: (
          <>
            <img src={logo} alt='logo' />
            {admin?.role === AdminRole.SuperAdmin && <NavigationMenu items={items} isAdmin />}
          </>
        ),
        center: '',
        right: (
          <Button
            size='s'
            form='round'
            view='clear'
            label='Выйти из системы'
            onClick={onLogout}
          />
        ),
      }}
    />
  );
};

export default Header;
