import styled from 'styled-components';

export const StockAddressesWrapper = styled.div`
  .Card {
    border-radius: 16px;
    background: var(--color-bg-default);
    height: 100%;

    .stocksList {
      flex-wrap: wrap;
    }
  }
`;