import React, { useEffect, useState } from 'react';
import { Card } from '@consta/uikit/Card';
import { DatePickerPropValue } from '@consta/uikit/DatePicker';
import { Text } from '@consta/uikit/Text';
import { Grid, GridItem } from '@consta/uikit/Grid';
import { useUsersStatQuery } from 'features/users/api/usersStat.generated';
import { useSignUpInPeriodQuery } from 'features/users/api/signUpInperiod.generated';
import { useOnlineInPeriodQuery } from 'features/users/api/onlineInPeriod.generated';
import { UserCountry, UsersCountriesType } from 'types';
import { DateRangePicker } from 'entities/stats/date-range-picker';
import { countriesMap } from 'shared/constants/countries.constants';
import ProgressChart from 'shared/ui/components/progressChart';
import { CountryStatWrapper, StatsWrapper } from './styled';

const UserStatCards: React.FC = () => {
  const currentDate = new Date();
  const monthAgo = currentDate.setMonth(currentDate.getMonth() - 1);
  const [signUpDateRange, setSignUpDateRange] = useState<[Date?, Date?] | null>(
    [new Date(monthAgo), new Date()],
  );
  const [onlineDateRange, setOnlineDateRange] = useState<[Date?, Date?] | null>(
    [new Date(monthAgo), new Date()],
  );

  const [startDate, endDate] = signUpDateRange || [];

  const [totalUsers, setTotalUsers] = useState(0);
  const [signUpInPeriod, setSignUpInPeriod] = useState(0);
  const [onlineInPeriod, setOnlineInPeriod] = useState(0);
  const [countryStat, setCountryStat] = useState<UsersCountriesType[] | null>(
    null,
  );

  const { data: stats } = useUsersStatQuery({
    variables: {
      input: {
        from: startDate,
        to: endDate,
      },
    },
  });

  const { data: signUpInPeriodData, refetch: refetchSignUp } =
    useSignUpInPeriodQuery({
      variables: {
        input: {
          from: startDate,
          to: endDate,
        },
      },
    });

  const { data: onlineInPeriodData, refetch: refetchOnline } =
    useOnlineInPeriodQuery({
      variables: {
        input: {
          from: startDate,
          to: endDate,
        },
      },
    });

  const onUpdateSignUpDateRange = async (
    dates: DatePickerPropValue<'date-range'>,
  ) => {
    if (!dates?.length) return;

    setSignUpDateRange(dates);

    refetchSignUp({
      input: {
        from: signUpDateRange?.[0]?.toISOString(),
        to: signUpDateRange?.[1]?.toISOString(),
      },
    });
  };

  const onUpdateOnlineDateRange = async (
    dates: DatePickerPropValue<'date-range'>,
  ) => {
    if (!dates?.length) return;

    setOnlineDateRange(dates);

    refetchOnline({
      input: {
        from: onlineDateRange?.[0]?.toISOString(),
        to: onlineDateRange?.[1]?.toISOString(),
      },
    });
  };

  useEffect(() => {
    setTotalUsers(stats?.usersStat?.total || 0);
    setCountryStat(stats?.usersStat?.countries || null);
  }, [stats]);

  useEffect(() => {
    setSignUpInPeriod(signUpInPeriodData?.usersStat?.registrationInPeriod || 0);
  }, [signUpInPeriodData]);

  useEffect(() => {
    setOnlineInPeriod(onlineInPeriodData?.usersStat?.onlineInPeriod || 0);
  }, [onlineInPeriodData]);

  const renderCountriesStats = () => {
    return Object.keys(countriesMap).map((country) => {
      return (
        <CountryStatWrapper key={country}>
          <Grid cols={3} rowGap='xl'>
            <GridItem col={1}>{countriesMap[country as UserCountry]}</GridItem>
            <GridItem col={1}>
              {
                <ProgressChart
                  total={totalUsers}
                  value={
                    countryStat?.find((c) => c.country === country)?.count || 0
                  }
                />
              }
            </GridItem>
            <GridItem col={1}>
              {countryStat?.find((c) => c.country === country)?.count || 0}
            </GridItem>
          </Grid>
        </CountryStatWrapper>
      );
    });
  };
  return (
    <StatsWrapper>
      <Grid cols={4} colGap='xl'>
        <GridItem col={1}>
          <Card>
            <Text size='xl' view='primary' weight='medium'>
              Всего зарегистрированных пользователей
            </Text>
            <Text size='4xl' view='primary' weight='medium'>
              {totalUsers}
            </Text>
          </Card>
        </GridItem>
        <GridItem col={1}>
          <Card>
            <Text size='xl' view='primary' weight='medium'>
              Распределение по странам
            </Text>
            <Text size='s' view='primary' weight='medium'>
              {renderCountriesStats()}
            </Text>
          </Card>
        </GridItem>
        <GridItem col={1}>
          <Card>
            <Text size='xl' view='primary' weight='medium'>
              Зарегистрировалось за период
            </Text>
            {DateRangePicker({
              range: signUpDateRange,
              setValue: onUpdateSignUpDateRange,
            })}
            <Text size='3xl' view='primary' weight='medium'>
              {signUpInPeriod}
            </Text>
          </Card>
        </GridItem>
        <GridItem col={1}>
          <Card>
            <Text size='xl' view='primary' weight='medium'>
              Зашло на сайт за период
            </Text>
            {DateRangePicker({
              range: onlineDateRange,
              setValue: onUpdateOnlineDateRange,
            })}
            <Text size='3xl' view='primary' weight='medium'>
              {onlineInPeriod}
            </Text>
          </Card>
        </GridItem>
      </Grid>
    </StatsWrapper>
  );
};

export default UserStatCards;
