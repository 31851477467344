import styled from 'styled-components';

export const DeliverRatesHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .Button {
    margin-left: 10px;
  }
  margin-bottom: 24px;
  .TextField {
    width: 30%;
  }
  .greenButton {
    background: var(--color-typo-success);
  }
`;

export const RatesListHeader = styled.div`
  .Card {
    display: flex;
    background: var(--color-bg-default);
    width: 50%;
    max-width: 530px;
    height: 42px;
    align-items: center;
    justify-content: space-between;
    padding-left: 40px;
    padding-right: 40px;
  }
`;

export const DeliveryRateRowContainer = styled.div`
  margin-top: 24px;
  padding-left: 40px;
  .Grid {
    width: 100%;
    display: flex;
    .Text {
      margin-bottom: 40px;
    }
    .TextField {
      margin-bottom: 30px;
      margin-left: 20px;
      width: 80px;
    }
    .GridItem {
      margin-right: 20px;
    }
  }
`;
