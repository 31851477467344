import styled from 'styled-components';

export const FlagWrapper = styled.div`
  width: 24px;
  height: 16px;
`;

export const AddressWrapper = styled.div`
  display: flex;
  width: 80%;
  @media (max-width: 500px) {
    width: 100%;
  }
`;

export const AddressActionsWrapper = styled.div`
  margin-left: auto;
  width: 90px;
`;
