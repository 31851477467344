import React, { FC, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@consta/uikit/Button';
import { Card } from '@consta/uikit/Card';
import { Text } from '@consta/uikit/Text';
import { TextField } from '@consta/uikit/TextField';
import { LoginContainer, LoginFormWrapper } from 'pages/login/styled';
import { useAdminLoginMutation } from 'features/admin-login/adminLogin.generated';
import logo from 'assets/logo.png';
import Margin from 'shared/ui/components/margin';
import { setAuthToken } from 'shared/api/auth';
 
const AdminLoginPage: FC = () => {
  const [login, setLogin] = useState('');
  const [loginError, setLoginError] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);

  const [adminLogin, {data, error}] = useAdminLoginMutation();

  const onLogin = async () => {
    if (login && password && !loginError && !passwordError) {
      await adminLogin({
        variables: {
          input: {
            login,
            password,
          },
        },
      })
    }
  }

  const handleLoginChange = (value: string) => {
    setLogin(value);
    setLoginError(false);
  };

  const handlePasswordChange = (value: string) => {
    setPassword(value);
    setPasswordError(false);
  };

  const handleLoginBlur = () => {
    if (!login) {
      setLoginError(true);
    }
  };

  const handlePasswordBlur = () => {
    if (!password) {
      setPasswordError(true);
    }
  };

  const navigate = useNavigate(); 

  useEffect(() => {
    if (data?.adminLogin?.accessToken && !error) {
      setAuthToken(data?.adminLogin?.accessToken, '', data?.adminLogin?.expiresIn);
      navigate('/');
    }
  }, [data, error]);

  return (
    <>
      <LoginContainer>
        <Card verticalSpace='l' horizontalSpace='l' shadow={false} style={{ maxWidth: '548px' }}>
          <LoginFormWrapper>
            <img src={logo} alt='logo' />
            <Margin mt={36} mb={12}>
              <Text size='3xl' view='primary' align='center'>
                Панель управления
              </Text>
            </Margin>
            <Margin mb={24}>
              <TextField
                label='Ваш логин'
                type='text'
                placeholder='admin'
                required
                form='round'
                width='full'
                size='m'
                value={login}
                onChange={({ value }) => handleLoginChange(value || '')}
                onBlur={handleLoginBlur}
                status={loginError ? 'alert' : undefined}
                caption={loginError ? 'Введите логин' : undefined}
              />
            </Margin>
            <Margin mb={32}>
              <TextField
                label='Пароль'
                type='password'
                placeholder='Введите пароль'
                required
                form='round'
                size='m'
                width='full'
                value={password}
                onChange={({ value }) => handlePasswordChange(value || '')}
                onBlur={handlePasswordBlur}
                status={passwordError ? 'alert' : undefined}
                caption={passwordError ? 'Неверный пароль' : undefined}
              />
            </Margin>
            <Margin mb={20}>
              <Button
                label='Войти'
                form='round'
                width='full'
                disabled={!login || !password || loginError || passwordError}
                onClick={onLogin}
              />
            </Margin>
          </LoginFormWrapper>
        </Card>
      </LoginContainer>
    </>
  );
};

export default AdminLoginPage;
