import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PackagesStatByDatesQueryVariables = Types.Exact<{
  filter: Types.PackagesStatInput;
}>;


export type PackagesStatByDatesQuery = { __typename?: 'Query', packagesStat: { __typename?: 'PackagesStatType', weightByDates: Array<{ __typename?: 'PackagesWeightByDatesType', date: string, weight: number }> } };


export const PackagesStatByDatesDocument = gql`
    query packagesStatByDates($filter: PackagesStatInput!) {
  packagesStat(filter: $filter) {
    weightByDates {
      date
      weight
    }
  }
}
    `;

/**
 * __usePackagesStatByDatesQuery__
 *
 * To run a query within a React component, call `usePackagesStatByDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePackagesStatByDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePackagesStatByDatesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function usePackagesStatByDatesQuery(baseOptions: Apollo.QueryHookOptions<PackagesStatByDatesQuery, PackagesStatByDatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PackagesStatByDatesQuery, PackagesStatByDatesQueryVariables>(PackagesStatByDatesDocument, options);
      }
export function usePackagesStatByDatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PackagesStatByDatesQuery, PackagesStatByDatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PackagesStatByDatesQuery, PackagesStatByDatesQueryVariables>(PackagesStatByDatesDocument, options);
        }
export type PackagesStatByDatesQueryHookResult = ReturnType<typeof usePackagesStatByDatesQuery>;
export type PackagesStatByDatesLazyQueryHookResult = ReturnType<typeof usePackagesStatByDatesLazyQuery>;
export type PackagesStatByDatesQueryResult = Apollo.QueryResult<PackagesStatByDatesQuery, PackagesStatByDatesQueryVariables>;