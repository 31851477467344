import styled from 'styled-components';

export const TableContainer = styled.div`
  .Table {
    .damaged {
      background: var(--color-typo-alert) !important;
    }

    &::-webkit-scrollbar {
      height: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--color-control-bg-border-default);
      border-radius: 20px;
      height: 6px;
    }

    .Table-CellsRow {
      .TableCell {
        cursor: pointer;
        background: var(--color-bg-secondary);

        .Badge_status_system {
          color: var(--color-typo-primary);
        }

        .Badge_status_normal {
          color: var(--color-bg-secondary);
        }
      }
    }

    .TableHeader-Row {
      .TableHeader-Cell {
        overflow: hidden;
        border-bottom: none;

        &:first-child {
          border-radius: 30px 0 0 30px;
        }

        &:last-child {
          border-radius: 0 30px 30px 0;
        }
      }

      &:hover {
        .TableHeader-Cell {
          &::after {
            display: none !important;
          }
        }
      }
    }

    .Table-RowWithoutCells {
      background: var(--color-bg-secondary);

      & > div {
        border-bottom: none;
      }
    }

    .Checkbox-Input {
      min-width: 16px;
      min-height: 16px;
      border-radius: 4px;
    }
  }
`;

export const TabsContainer = styled.div`
  .Tabs {
    .TabsFitModeDropdownWrapper-Tabs {
      width: 100%;

      .Tabs-Tab {
        margin: 0;
        width: 33.2%;
        text-align: center;
      }
    }
  }
`;

export const TabItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 7px;
  cursor: pointer;

  svg {
    width: 16px;
    height: 12px;
    margin-right: 8px;
    border-radius: 3px;
  }
`;

export const EmptyContainer = styled.div`
  .Card {
    width: 100%;
    background: var(--color-bg-default);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 60px;

    .Text {
      max-width: 600px;
    }

    .Button {
      margin-top: 32px;
    }

    @media (max-width: 1100px) {
      padding: 40px;
      max-width: 100%;

      .Button {
        margin-top: 28px;
      }
    }

    @media (max-width: 640px) {
      padding: 36px 55px;

      .Button {
        margin-top: 24px;
      }
    }
  }
`;
