import { Grid, GridItem } from '@consta/uikit/Grid';
import { Select } from '@consta/uikit/Select';
import { Text } from '@consta/uikit/Text';
import { TextField } from '@consta/uikit/TextField';
import Flag from 'entities/countries';
import { CitizenshipItem } from 'entities/countries/types';
import { UserCountry } from 'types';
import Margin from 'shared/ui/components/margin';
import { FlagWrapper, PhoneFlagWrapper } from './styled';

interface PhoneInputProps {
  label?: string;
  smallScreen: boolean;
  countries: CitizenshipItem[];
  phonePrefix: CitizenshipItem;
  phone: string;
  phoneError: boolean;
  disabled?: boolean;
  withDisabledPlaceholder?: boolean;
  handlePhonePrefixChange: (value: CitizenshipItem | null) => void;
  handlePhoneChange: (value: string) => void;
  handlePhoneBlur: () => void;
}

const PhoneInput = ({
  label,
  smallScreen,
  countries,
  phonePrefix,
  phone,
  phoneError,
  disabled,
  withDisabledPlaceholder,
  handlePhonePrefixChange,
  handlePhoneChange,
  handlePhoneBlur,
}: PhoneInputProps) => {
  return (
    <>
      {withDisabledPlaceholder && disabled ? (
        <TextField
          label={label}
          type='text'
          placeholder={'+7 800-555-35-35'}
          form='round'
          width='full'
          disabled={disabled}
          size={smallScreen ? 's' : 'm'}
          value={`+${phonePrefix.phonePrefix}${phone}`}
          incrementButtons={false}
        />
      ) : (
        <>
          <Margin mb={8}>
            <Text size={smallScreen ? 's' : 'm'} view='secondary' align='left'>
              {label} <span className={'Text_view_alert'}>*</span>
            </Text>
          </Margin>
          <Grid cols={12}>
            <GridItem col={4}>
              <Select
                form='roundBrick'
                size={smallScreen ? 's' : 'm'}
                required
                disabled={disabled}
                items={countries}
                value={phonePrefix}
                getItemLabel={(item) => item.phonePrefix}
                getItemKey={(item) => item.value}
                onChange={({ value }) => handlePhonePrefixChange(value)}
                renderItem={({
                  item,
                  active,
                  hovered,
                  onClick,
                  onMouseEnter,
                }) => (
                  <Grid
                    role='option'
                    aria-selected={active}
                    onMouseEnter={onMouseEnter}
                    onClick={onClick}
                    onKeyDown={onClick}
                    tabIndex={0}
                    cols={12}
                  >
                    <GridItem col={2}>
                      <Margin mt={10} ml={10}>
                        <FlagWrapper>
                          <Flag variant={item?.countryCode || UserCountry.Kz} />
                        </FlagWrapper>
                      </Margin>
                    </GridItem>
                    <GridItem col={10}>
                      <Text
                        align='center'
                        // TODO: унаследовать классы
                        // eslint-disable-next-line max-len
                        className={`Text Text_lineHeight_xs Text_size_m Text_view_primary ListItem_interactive MixSpace MixSpace_pT_xs MixSpace_pL_s MixSpace_pR_s MixSpace_pB_xs ListItemGrid SelectItem, ${
                          hovered ? 'ListItem_active' : ''
                        }, ${active ? 'ListItem_checked' : ''}`}
                      >
                        {item.label}
                      </Text>
                    </GridItem>
                  </Grid>
                )}
                renderValue={({ item }) => (
                  <Grid role='option' tabIndex={0} cols={12}>
                    <GridItem col={6}>
                      <Margin mt={smallScreen ? 1 : 3}>
                        <PhoneFlagWrapper>
                          <Flag variant={item?.countryCode || UserCountry.Kz} />
                        </PhoneFlagWrapper>
                      </Margin>
                    </GridItem>
                    <GridItem col={6}>
                      <Margin mt={smallScreen ? 5 : 8}>
                        <Text size={smallScreen ? 's' : 'm'} align='center'>
                          +{item.phonePrefix}
                        </Text>
                      </Margin>
                    </GridItem>
                  </Grid>
                )}
              />
            </GridItem>
            <GridItem col={8}>
              <TextField
                // TODO: добавить маску с 5 версией Consta
                type='number'
                placeholder='800-555-35-35'
                required
                form='brickRound'
                width='full'
                disabled={disabled}
                size={smallScreen ? 's' : 'm'}
                value={phone}
                incrementButtons={false}
                onChange={({ value }) => handlePhoneChange(value || '')}
                onBlur={handlePhoneBlur}
                status={phoneError ? 'alert' : undefined}
                caption={phoneError ? 'Поле не заполнено' : undefined}
              />
            </GridItem>
          </Grid>
        </>
      )}
    </>
  );
};

PhoneInput.defaultProps = {
  label: 'Ваш телефон',
  disabled: false,
  withDisabledPlaceholder: false,
};

export default PhoneInput;
