import StoreNotify from 'entities/notifications/model'
import { NotifyItem } from '../../../entities/notifications/types';

export const showErrorNotification = (message: string): void => {
  const notification: NotifyItem = {
    message,
    key: 0,
    status: 'alert',
  };

  StoreNotify.add(notification);
};
