import React, { FC, useState, useEffect } from 'react';
import { Modal } from '@consta/uikit/Modal';
import { Text } from '@consta/uikit/Text';
import { Button } from '@consta/uikit/Button';
import { IconEye } from '@consta/uikit/IconEye';
import { IconEyeClose } from '@consta/uikit/IconEyeClose';
import { ColumnHiddenType } from 'pages/admin/repurchase/types';
import Margin from 'shared/ui/components/margin';
import Row from 'shared/ui/components/row';
import { FieldsModalWrapper } from './styled';

type TableColumnType = Omit<ColumnHiddenType, 'renderCell'>;
interface IProps {
  columns: TableColumnType[];
  isModalOpen: boolean;
  onSubmit: (columns: TableColumnType[]) => void;
  onClose: () => void;
}

const FieldsModal: FC<IProps> = ({
  columns,
  isModalOpen,
  onSubmit,
  onClose,
}: IProps) => {
  const [columnsCopy, setColumnsCopy] = useState<TableColumnType[]>([]);

  const onClick = (column: TableColumnType) => {
    setColumnsCopy(
      columnsCopy.map((item) => {
        if (item.accessor === column.accessor) {
          const hiddenValue = !item.hidden;

          // По крайней мере 1 поле в таблице должно быть видимым
          if (
            hiddenValue &&
            columnsCopy.filter((c) => !c.hidden)?.length === 1
          ) {
            return { ...item, hidden: !hiddenValue };
          }

          return { ...item, hidden: !item.hidden };
        }
        return item;
      }),
    );
  };

  const onSave = () => {
    onSubmit(columnsCopy);
    onClose();
  };

  useEffect(() => {
    setColumnsCopy([...columns]);
  }, [columns, isModalOpen]);

  return (
    <Modal
      isOpen={isModalOpen}
      onClickOutside={() => onClose()}
      onEsc={() => onClose()}
    >
      <FieldsModalWrapper>
        <Margin mb={20}>
          <Text size='3xl' view='primary' weight='medium'>
            Настройки видимых полей
          </Text>
        </Margin>
        {columnsCopy.map((item) => (
          <Row
            className='field'
            justify='space-between'
            align='center'
            key={item.accessor}
          >
            <Text size='s' view='primary'>
              {item.title}
            </Text>
            <Button
              onlyIcon
              form='round'
              iconLeft={item.hidden ? IconEyeClose : IconEye}
              size='s'
              view='clear'
              onClick={() => onClick(item)}
              className={item.hidden ? undefined : 'active'}
            />
          </Row>
        ))}
        <Margin mt={24}>
          <Row justify='flex-start' align='center'>
            <Margin mr={24} style={{ width: 'unset' }}>
              <Button
                size='s'
                label='Отменить'
                view='secondary'
                form='round'
                onClick={onClose}
              />
            </Margin>
            <Button
              label='Сохранить'
              size='s'
              view='primary'
              form='round'
              className='buttonBlack'
              onClick={onSave}
            />
          </Row>
        </Margin>
      </FieldsModalWrapper>
    </Modal>
  );
};

export default FieldsModal;
