import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OnlineInPeriodQueryVariables = Types.Exact<{
  input: Types.UsersStatInput;
}>;


export type OnlineInPeriodQuery = { __typename?: 'Query', usersStat: { __typename?: 'UsersStatType', onlineInPeriod: number } };


export const OnlineInPeriodDocument = gql`
    query onlineInPeriod($input: UsersStatInput!) {
  usersStat(input: $input) {
    onlineInPeriod
  }
}
    `;

/**
 * __useOnlineInPeriodQuery__
 *
 * To run a query within a React component, call `useOnlineInPeriodQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnlineInPeriodQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnlineInPeriodQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOnlineInPeriodQuery(baseOptions: Apollo.QueryHookOptions<OnlineInPeriodQuery, OnlineInPeriodQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OnlineInPeriodQuery, OnlineInPeriodQueryVariables>(OnlineInPeriodDocument, options);
      }
export function useOnlineInPeriodLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OnlineInPeriodQuery, OnlineInPeriodQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OnlineInPeriodQuery, OnlineInPeriodQueryVariables>(OnlineInPeriodDocument, options);
        }
export type OnlineInPeriodQueryHookResult = ReturnType<typeof useOnlineInPeriodQuery>;
export type OnlineInPeriodLazyQueryHookResult = ReturnType<typeof useOnlineInPeriodLazyQuery>;
export type OnlineInPeriodQueryResult = Apollo.QueryResult<OnlineInPeriodQuery, OnlineInPeriodQueryVariables>;