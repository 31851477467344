import { DeliveryStatus } from 'types';

export const statusesLabels: Record<DeliveryStatus, string> = {
  [DeliveryStatus.Created]: 'Ожидается',
  [DeliveryStatus.InTransit]: 'В пути',
  [DeliveryStatus.ReadyForDelivery]: 'Готово к выдаче',
  [DeliveryStatus.Done]: 'Доставлено',
};

export const statusesBadges: Record<DeliveryStatus, 'system' | 'warning' | 'success' | 'normal' | 'error'> = {
  [DeliveryStatus.Created]: 'system',
  [DeliveryStatus.InTransit]: 'warning',
  [DeliveryStatus.ReadyForDelivery]: 'success',
  [DeliveryStatus.Done]: 'normal',
};