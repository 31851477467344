import styled from 'styled-components';

export const VerticalMenuContainer = styled.div<{ zIndex: number }>`
  position: fixed;
  height: calc(100vh - 60px);
  width: 100vw;
  top: 60px;
  left: 0;
  background: var(--color-bg-secondary);
  z-index: ${({ zIndex }) => zIndex};
  overflow-y: auto;
  .Button {
    padding-left: 24px;
  }
  .che--VerticalMenuLevel-Header {
    Button {
      color: var(--color-typo-link);
    }
  }
`;
