import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ShipmentStatsQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type ShipmentStatsQuery = { __typename?: 'Query', shipmentStats: Array<{ __typename?: 'ShipmentStatType', status: Types.StoreStatus, count: number }> };


export const ShipmentStatsDocument = gql`
    query shipmentStats($id: Int!) {
  shipmentStats(id: $id) {
    status
    count
  }
}
    `;

/**
 * __useShipmentStatsQuery__
 *
 * To run a query within a React component, call `useShipmentStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useShipmentStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShipmentStatsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useShipmentStatsQuery(baseOptions: Apollo.QueryHookOptions<ShipmentStatsQuery, ShipmentStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShipmentStatsQuery, ShipmentStatsQueryVariables>(ShipmentStatsDocument, options);
      }
export function useShipmentStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShipmentStatsQuery, ShipmentStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShipmentStatsQuery, ShipmentStatsQueryVariables>(ShipmentStatsDocument, options);
        }
export type ShipmentStatsQueryHookResult = ReturnType<typeof useShipmentStatsQuery>;
export type ShipmentStatsLazyQueryHookResult = ReturnType<typeof useShipmentStatsLazyQuery>;
export type ShipmentStatsQueryResult = Apollo.QueryResult<ShipmentStatsQuery, ShipmentStatsQueryVariables>;