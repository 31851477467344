import styled from 'styled-components';

export const AnalyticsStatCardWrapper = styled.div`
  .GridItem {
    align-items: center;
    display: flex;
    .Text:last-child {
      margin-left: auto;
    }
  }
`;
