import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UsersStatQueryVariables = Types.Exact<{
  input: Types.UsersStatInput;
}>;


export type UsersStatQuery = { __typename?: 'Query', usersStat: { __typename?: 'UsersStatType', total: number, countries: Array<{ __typename?: 'UsersCountriesType', country: Types.UserCountry, count: number }> } };


export const UsersStatDocument = gql`
    query usersStat($input: UsersStatInput!) {
  usersStat(input: $input) {
    countries {
      country
      count
    }
    total
  }
}
    `;

/**
 * __useUsersStatQuery__
 *
 * To run a query within a React component, call `useUsersStatQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersStatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersStatQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUsersStatQuery(baseOptions: Apollo.QueryHookOptions<UsersStatQuery, UsersStatQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersStatQuery, UsersStatQueryVariables>(UsersStatDocument, options);
      }
export function useUsersStatLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersStatQuery, UsersStatQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersStatQuery, UsersStatQueryVariables>(UsersStatDocument, options);
        }
export type UsersStatQueryHookResult = ReturnType<typeof useUsersStatQuery>;
export type UsersStatLazyQueryHookResult = ReturnType<typeof useUsersStatLazyQuery>;
export type UsersStatQueryResult = Apollo.QueryResult<UsersStatQuery, UsersStatQueryVariables>;