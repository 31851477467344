import React, { SyntheticEvent } from 'react';
import { Grid, GridItem } from '@consta/uikit/Grid';
import { Text } from '@consta/uikit/Text';
import Flag from 'entities/countries';
import { StockCode, UserCountry } from 'types';
import Margin from 'shared/ui/components/margin';
import { FlagWrapper } from './styled';

type CountryLabelProps = {
  active: boolean;
  onMouseEnter: (e: SyntheticEvent<Element, Event>) => void;
  onClick: (e: SyntheticEvent<Element, Event>) => void;
  item: {
    label: string;
    value: UserCountry | StockCode;
  };
  hovered: boolean;
};

const CountryLabel = ({
  active,
  onMouseEnter,
  onClick,
  item,
  hovered,
}: CountryLabelProps) => {
  return (
    <Grid
      role='option'
      aria-selected={active}
      onMouseEnter={onMouseEnter}
      onClick={onClick}
      onKeyDown={onClick}
      tabIndex={0}
      cols={9}
    >
      <GridItem col={1}>
        <Margin mt={10} ml={10}>
          <FlagWrapper>
            <Flag variant={item?.value || UserCountry.Kz} />
          </FlagWrapper>
        </Margin>
      </GridItem>
      <GridItem col={8}>
        <Text
          align='center'
          // TODO: унаследовать классы
          // eslint-disable-next-line max-len
          className={`Text Text_lineHeight_xs Text_size_m Text_view_primary ListItem_interactive MixSpace MixSpace_pT_xs MixSpace_pL_s MixSpace_pR_s MixSpace_pB_xs ListItemGrid SelectItem, ${
            hovered ? 'ListItem_active' : ''
          }, ${active ? 'ListItem_checked' : ''}`}
        >
          {item.label}
        </Text>
      </GridItem>
    </Grid>
  );
};

export default CountryLabel;
