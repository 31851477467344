import styled from 'styled-components';

export const VideoContainer = styled.div`
  video {
    border-radius: 16px;
  }
`;

export const TextContainer = styled.div`
  .Text {
    margin-bottom: 24px;
    width: 80%;

    &:nth-child(2) {
      width: 70%;
    }

    &:last-child {
      width: 60%;
      margin-bottom: 0;
    }

    ul {
      list-style-position: outside;
      padding-left: 20px;
    }
  }

  @media (max-width: 1100px) {
    .Text {
      width: 100% !important;
      margin-bottom: 16px;
    }
  }
`;

export const KnowledgeBaseContainer = styled.div`
  .Card {
    width: 100%;
    background: var(--color-bg-brand);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 60px;

    .Text {
      color: var(--color-bg-secondary);
      max-width: 600px;
      margin-bottom: 32px;
    }

    .Button, .Button:hover {
      background: var(--color-bg-secondary);
    }

    @media (max-width: 1100px) {
      padding: 40px 70px;

      .Text {
        max-width: 100%;
        margin-bottom: 32px;
      }
    }

    @media (max-width: 640px) {
      padding: 36px 17px;

      .Text {
        margin-bottom: 24px;
      }
    }
  }
`;