import { Text } from '@consta/uikit/Text';
import { Button } from '@consta/uikit/Button';
import { Grid, GridItem } from '@consta/uikit/Grid';
import { TextField } from '@consta/uikit/TextField';
import { useEffect, useState } from 'react';
import { Select } from '@consta/uikit/Select';
import { countries, governmentIdLengthMapper } from 'entities/countries/utils';
import { CitizenshipItem } from 'entities/countries/types';
import { UserCountry } from 'types';
import { useUpdateUserProfileMutation } from 'features/users/api/updateUserProfile.generated';
import { useUserProfileStatQuery } from 'features/users/api/userProfileStat.generated';
import ConfirmModal from 'entities/ui/confirmModal';
import { getInfoRows } from 'entities/user/utils';
import Margin from 'shared/ui/components/margin';
import Row from 'shared/ui/components/row';
import { emailRegex } from 'shared/constants';
import { InfoWithLabel } from 'shared/ui/components/infoWithLabel';
import { EditClientBodyWrapper, ModalHeader } from './styled';

export type UserDataType = {
  id: string;
  phone?: string | null;
  email: string;
  name?: string | null;
  nameEn?: string | null;
  govId?: string | null;
  createdAt: string;
  country?: UserCountry | null;
};

const ClientEditForm = ({
  user,
  onCloseModal,
}: {
  user: UserDataType | null;
  onCloseModal: () => void;
}) => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [nameEn, setNameEn] = useState('');
  const [phone, setPhone] = useState('');
  const [govId, setGovId] = useState('');
  const [country, setCountry] = useState<CitizenshipItem | null>();
  const [formEdited, setFormEdited] = useState(false);

  const [emailError, setEmailError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [nameEnError, setNameEnError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [govIdError, setGovIdError] = useState(false);
  const [countryError, setCountryError] = useState(false);
  const [isConfirmCloseModalOpen, setIsConfirmCloseModalOpen] = useState(false);
  const [isConfirmSaveModalOpen, setIsConfirmSaveModalOpen] = useState(false);
  const [confirmModalData, setConfirmModalData] = useState({
    title: '',
    body: '',
    submitTitle: '',
  });

  const { data: userStat } = useUserProfileStatQuery({
    variables: {
      id: Number(user?.id),
    },
    skip: !user?.id,
  });

  const handleEmailChange = (value: string) => {
    setEmail(value);
    setEmailError(false);
    setFormEdited(true);
  };

  const handleNameChange = (value: string) => {
    const cleanValue = value.replace(/[^а-яё\s]/i, '');
    setName(cleanValue);
    setNameError(false);
    setFormEdited(true);
  };

  const handleNameEnChange = (value: string) => {
    const cleanValue = value.replace(/[^A-Za-z\s]/gi, '');
    setNameEn(cleanValue);
    setNameEnError(false);
    setFormEdited(true);
  };

  const handlePhoneChange = (value: string) => {
    setPhone(value);
    setPhoneError(false);
    setFormEdited(true);
  };

  const handleGovIdChange = (value: string) => {
    if (
      value &&
      value?.length > governmentIdLengthMapper(country?.value || UserCountry.Kg)
    )
      return;

    setGovId(value);
    setGovIdError(false);
    setFormEdited(true);
  };

  const handleCountryChange = (value: CitizenshipItem | null) => {
    setCountry(value);
    setCountryError(false);
    setFormEdited(true);
  };

  const handleEmailBlur = () => {
    if (!emailRegex.test(email)) {
      setEmailError(true);
    }
  };

  const handleNameBlur = () => {
    if (!name) {
      setNameError(true);
    }
  };

  const handleNameEnBlur = () => {
    if (!nameEn) {
      setNameEnError(true);
    }
  };

  const handlePhoneBlur = () => {
    if (!phone) {
      setPhoneError(true);
    }
  };

  const handleGovIdBlur = () => {
    if (!govId) {
      setGovIdError(true);
    }
  };

  const handleCountryBlur = () => {
    if (!phone) {
      setCountryError(true);
    }
  };

  const setInitialUserData = () => {
    setEmail(user?.email || '');
    setName(user?.name || '');
    setNameEn(user?.nameEn || '');
    setPhone(user?.phone || '');
    setGovId(user?.govId?.replace(/\s/g, '') || '');
    setCountry(countries.find((c) => c.value === user?.country) || null);
  };

  const clearData = () => {
    setEmail('');
    setName('');
    setNameEn('');
    setPhone('');
    setGovId('');
    setCountry(null);
  };

  const closeModal = () => {
    if (formEdited) {
      setConfirmModalData({
        title: 'Последние изменения не сохранены. Закрыть панель?',
        body: '',
        submitTitle: 'Да',
      });
      setIsConfirmCloseModalOpen(true);
    } else {
      clearData();
      onCloseModal();
    }
  };

  const onCloseConfirmModal = () => {
    setIsConfirmCloseModalOpen(false);
    setIsConfirmSaveModalOpen(false);
  };

  const onSubmitConfirmModal = () => {
    if (isConfirmSaveModalOpen) {
      onSubmit();
    }
    setIsConfirmCloseModalOpen(false);
    setIsConfirmSaveModalOpen(false);

    if (isConfirmCloseModalOpen) {
      clearData();
      onCloseModal();
    }
  };

  const showConfirmModal = () => {
    setConfirmModalData({
      title: 'Вы действительно хотите внести изменения?',
      body: '',
      submitTitle: 'Да',
    });
    setIsConfirmSaveModalOpen(true);
  };

  useEffect(() => {
    setInitialUserData();
  }, [user]);

  const [updateUserProfile] = useUpdateUserProfileMutation();

  const onSubmit = () => {
    updateUserProfile({
      variables: {
        id: Number(user?.id),
        input: {
          email,
          name,
          nameEn,
          phone: phone.replace(/[^+\d]+/g, ''),
          govId,
          country: country?.value,
        },
      },
    });
    setFormEdited(false);
  };

  return (
    <>
      <ModalHeader>
        <Text size='xl' view='primary' weight='medium'>
          Информация о клиенте
        </Text>
        <Row justify='center' align='center'>
          <Button
            label='Сохранить'
            view='primary'
            className='buttonBlack'
            size='s'
            onlyIcon
            onClick={showConfirmModal}
          />
          <Margin ml={8}>
            <Button
              label='Закрыть'
              view='ghost'
              size='s'
              onlyIcon
              onClick={() => closeModal()}
            />
          </Margin>
        </Row>
      </ModalHeader>
      <EditClientBodyWrapper>
        <Grid cols={2}>
          <GridItem col={1}>
            <TextField
              label='ФИО КИРИЛЛИЦА'
              type='text'
              placeholder='Ввведите ФИО'
              form='clearClear'
              width='full'
              size='m'
              required
              value={name}
              onChange={({ value }) => handleNameChange(value || '')}
              onBlur={handleNameBlur}
              status={nameError ? 'alert' : undefined}
              caption={nameError ? 'Поле не заполнено' : undefined}
            />
            <TextField
              label='ФИО ЛАТИНИЦА'
              type='text'
              placeholder='Ввведите ФИО'
              form='clearClear'
              width='full'
              size='m'
              required
              value={nameEn}
              onChange={({ value }) => handleNameEnChange(value || '')}
              onBlur={handleNameEnBlur}
              status={nameEnError ? 'alert' : undefined}
              caption={nameEnError ? 'Поле не заполнено' : undefined}
            />
            <TextField
              label='ТЕЛЕФОН'
              type='text'
              placeholder='+7 999 999 99 99'
              form='clearClear'
              width='full'
              size='m'
              required
              value={phone}
              onChange={({ value }) => handlePhoneChange(value || '')}
              onBlur={handlePhoneBlur}
              status={phoneError ? 'alert' : undefined}
              caption={phoneError ? 'Поле не заполнено' : undefined}
            />
            <TextField
              label='EMAIL'
              type='text'
              placeholder='example@mail.com'
              form='clearClear'
              width='full'
              size='m'
              required
              value={email}
              onChange={({ value }) => handleEmailChange(value || '')}
              onBlur={handleEmailBlur}
              status={emailError ? 'alert' : undefined}
              caption={emailError ? 'Поле не заполнено' : undefined}
            />
            <Select
              label='Страна'
              placeholder='Выберите варинат'
              items={countries}
              form='clearClear'
              size='m'
              required
              value={country}
              getItemLabel={(item: CitizenshipItem) => item.label || ''}
              getItemKey={(item: CitizenshipItem) => item.value}
              status={countryError ? 'alert' : undefined}
              caption={countryError ? 'Поле не заполнено' : undefined}
              onBlur={handleCountryBlur}
              onChange={({ value }) => handleCountryChange(value)}
            />
            <TextField
              label='ИНН/ИИН'
              type='number'
              placeholder='123456789'
              form='clearClear'
              width='full'
              size='m'
              required
              incrementButtons={false}
              value={govId}
              onChange={({ value }) => handleGovIdChange(value || '')}
              onBlur={handleGovIdBlur}
              status={govIdError ? 'alert' : undefined}
              caption={govIdError ? 'Поле не заполнено' : undefined}
            />
          </GridItem>
          <GridItem col={1}>
            {getInfoRows(user?.id, userStat?.userProfileStat).map(
              (row, index) => {
                return (
                  <InfoWithLabel
                    key={index}
                    title={row.title}
                    value={row.value}
                  />
                );
              },
            )}
          </GridItem>
        </Grid>
      </EditClientBodyWrapper>
      <ConfirmModal
        isModalOpen={isConfirmCloseModalOpen || isConfirmSaveModalOpen}
        title={confirmModalData.title}
        body={confirmModalData.body}
        submitTitle={confirmModalData.submitTitle}
        onClose={onCloseConfirmModal}
        onSubmit={onSubmitConfirmModal}
        closeButtonTitle='Отмена'
        customMaxWidth={600}
      />
    </>
  );
};

export default ClientEditForm;
