import React, { FC } from 'react';
import { Card } from '@consta/uikit/Card';
import { Text } from '@consta/uikit/Text';
import { Button } from '@consta/uikit/Button';
import IconBox from 'assets/icons/box.svg';
import IconAirplane from 'assets/icons/airplane.svg';
import IconCheck from 'assets/icons/check.svg';
import IconCheckAll from 'assets/icons/check-all.svg';
import { DeliveryStatus, PackagesSummaryType } from 'types';
import Row from 'shared/ui/components/row';
import Margin from 'shared/ui/components/margin';
import { InfoCardWrapper } from './styled';

interface IProps {
  data: PackagesSummaryType,
  active: boolean,
};

const statuses: Record<DeliveryStatus, { title: string, link: string, icon: string }> = {
  [DeliveryStatus.Created]: { title: 'Ожидаемые посылки', link: '/packages', icon: IconBox },
  [DeliveryStatus.InTransit]: { title: 'Посылки в пути', link: '/packages', icon: IconAirplane },
  [DeliveryStatus.ReadyForDelivery]: { title: 'Готовы к выдаче или переданы курьеру', link: '/packages', icon: IconCheck },
  [DeliveryStatus.Done]: { title: 'Полученные посылки', link: '/packages?history=true', icon: IconCheckAll },
};

const InfoCard: FC<IProps> = ({ data, active }: IProps) => {
  const isMobile = window.innerWidth < 640;
  const isTablet = window.innerWidth < 1100 && window.innerWidth >= 640;
  const smallScreen = isMobile || isTablet;

  return (
    <InfoCardWrapper>
      <Card
        verticalSpace={isMobile ? 's' : 'l'}
        horizontalSpace='l'
        className={active ? 'active' : undefined}
        shadow={false}
      >
        <Row justify='space-between' align='flex-start'>
          <Margin mr={10}>
            <Text
              lineHeight='2xs'
              align='left'
              size={smallScreen ? 's' : 'm'}
              view='primary'
            >
              {statuses[data.status].title}
            </Text>
          </Margin>
          <div className='iconContainer'>
            <img src={statuses[data.status].icon} alt={data.status} />
          </div>
        </Row>
        <Margin mt={smallScreen ? isMobile ? 16 : 20 : 25}>
          <Row
            direction={isMobile ? 'row' : 'column'}
            align={isMobile ? 'center' : 'flex-start'}
            justify={isMobile ? 'space-between' : 'flex-start'}
          >
            <Margin mb={isMobile ? 0 : 12}>
              <Text
                align='left'
                size={smallScreen ? isMobile ? '2xl' : '3xl' : '4xl'}
                view='primary'
              >
                {data.count}
              </Text>
            </Margin>
            <Button
              as='a'
              size='xs'
              view='secondary'
              form='round'
              label={data.status === DeliveryStatus.Done ? 'Архив' : 'Смотреть все'}
              href={statuses[data.status].link}
            />
          </Row>
        </Margin>
      </Card>
    </InfoCardWrapper>
  );
};

export default InfoCard;