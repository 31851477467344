import React, { FC, useState, useEffect, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Text } from '@consta/uikit/Text';
import { Button } from '@consta/uikit/Button';
import { TextField } from '@consta/uikit/TextField';
import { Tabs } from '@consta/uikit/Tabs';
import { Card } from '@consta/uikit/Card';
import { IconSearch } from '@consta/uikit/IconSearch'
import { Table } from '@consta/uikit/Table';
import { Checkbox } from '@consta/uikit/Checkbox';
import { CompleteStatus, PackageType, StockCode, StoreStatus } from 'types';
import ConfirmModal from 'entities/ui/confirmModal';
import { useAdminPackagesListQuery } from 'features/packages/api/adminPackagesList.generated';
import { useShipmentsListQuery } from 'features/shipments/api/shipmentsList.generated';
import { useShipmentStatsQuery } from 'features/shipment-stats/api/shipmentStats.generated';
import { useUpdateShipmentMutation } from 'features/shipments/api/updateShipment.generated';
import { useStocksListQuery } from 'features/main/api/stocksList.generated';
import InfoField from 'features/shipment-stats/infoField';
import MobileStoreItem from 'features/mobile-store-item';
import { EmptyContainer, TableContainer } from 'pages/packages/styled';
import Margin from 'shared/ui/components/margin';
import Row from 'shared/ui/components/row';
import { useIsInViewport } from 'shared/hooks/useIsInViewport';
import { PackageRow, ShipmentsTab, RowClassProps } from './types';

const StoreKeeperPackages: FC = () => {
  const pageSize = 40;
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);

  const [packages, setPackages] = useState<PackageRow[]>([]);
  const [stockId, setStockId] = useState<number | undefined>();
  const [shipmentId, setShipmentId] = useState<number | undefined>();
  const [q, setQ] = useState<string | undefined>();
  const [debouncedQ, setDebouncedQ] = useState<string | undefined>();

  const footerRef = useRef(document.getElementById('footer'));
  const inViewport = useIsInViewport(footerRef);

  const { data: dataStocks } = useStocksListQuery();
  const stocks = useMemo(() => dataStocks?.stocksList.filter(s => s.code !== StockCode.Kr) || [], [dataStocks]);

  useEffect(() => {
    if (stocks.length) {
      setStockId(stocks[0].id);
    }
  }, [stocks]);

  const [shipmentsTabs, setShipmentsTabs] = useState<ShipmentsTab[]>([]);

  const { data: shipments, refetch: refetchShipments } = useShipmentsListQuery({
    fetchPolicy: 'no-cache',
    variables: {
      filter: {
        stockId,
      },
    },
  });

  const shipment = useMemo(() => {
    return shipments?.shipmentsList.find(item => item.id === shipmentId);
  }, [shipments, shipmentId]);

  useEffect(() => {
    if (shipments?.shipmentsList?.length) {
      setShipmentId(shipments.shipmentsList[0]?.id);
      setShipmentsTabs(shipments.shipmentsList.map(item => ({ label: item.name, value: item.id })));
    } else {
      setShipmentId(undefined);
      setShipmentsTabs([]);
    }
  }, [shipments]);

  const { data } = useAdminPackagesListQuery({
    fetchPolicy: 'no-cache',
    variables: {
      filter: {
        stockId,
        q: debouncedQ || undefined,
        shipmentId,
      },
      pagination: {
        offset: offset * pageSize,
        limit: pageSize,
      },
    },
  });

  useEffect(() => {
    const items = data?.packagesList?.items;

    if (items && stockId && shipmentId) {
      setRowsFromPackages([...items] as PackageType[]);
      if (!offset) {
        setTotal(data?.packagesList?.total);
      }
    } else {
      setRowsFromPackages([]);
      setTotal(0);
    }
  }, [data]);

  const { data: dataStats } = useShipmentStatsQuery({
    fetchPolicy: 'no-cache',
    skip: !shipmentId,
    variables: {
      id: +shipmentId!,
    },
  });

  const stats = useMemo(() => {
    return Object.fromEntries(dataStats?.shipmentStats.map(s => [s.status, s.count]) || []);
  }, [dataStats]);

  useEffect(() => {
    if (inViewport && ((offset + 1) * pageSize <= total)) {
      setOffset(offset + 1);
    }
  }, [inViewport]);

  const setRowsFromPackages = (items: PackageType[]) => {
    const packagesWithRows: PackageRow[] = items.map(({
      id, track, storeStatus, size, packageCount, user, weight, comment, isDamaged, boxNumber,
    }) => ({
      id: id.toString(),
      track,
      storeStatus,
      user: user?.name ?? undefined,
      boxNumber: boxNumber ?? undefined,
      weight: weight ?? undefined,
      length: size?.length ?? undefined,
      height: size?.height ?? undefined,
      width: size?.width ?? undefined,
      packageCount: packageCount ?? undefined,
      comment: comment ?? undefined,
      isDamaged: isDamaged ?? undefined,
    }));

    if (offset) {
      setPackages([...packages, ...packagesWithRows]);
    } else {
      setPackages(packagesWithRows);
    }
  };

  const columns = [
    {
      title: 'СТАТУС',
      accessor: 'storeStatus',
      sortable: false,
      renderCell: ({ storeStatus }: PackageRow) => (
        <Checkbox
          checked={storeStatus === StoreStatus.Checked}
          size='s'
        />
      ),
      width: 100,
    },
    {
      title: 'ТРЕК-НОМЕР',
      accessor: 'track',
      sortable: false,
      width: 150,
    },
    {
      title: 'ПОЛУЧАТЕЛЬ',
      accessor: 'user',
      sortable: false,
      width: 300,
    },
    {
      title: 'НОМЕР КОРОБКИ',
      accessor: 'boxNumber',
      sortable: false,
      width: 150,
    },
    {
      title: 'ВЕС (КГ)',
      accessor: 'weight',
      sortable: true,
      width: 150,
    },
    {
      title: 'ДЛИНА (Д)',
      accessor: 'length',
      sortable: false,
      width: 150,
    },
    {
      title: 'ШИРИНА (Д)',
      accessor: 'width',
      sortable: false,
      width: 150,
    },
    {
      title: 'ВЫСОТА (Д)',
      accessor: 'height',
      sortable: false,
      width: 150,
    },
    {
      title: 'КОЛИЧЕСТВО МЕСТ',
      accessor: 'packageCount',
      sortable: true,
      width: 200,
    },
    {
      title: 'КОММЕНТАРИЙ',
      accessor: 'comment',
      sortable: false,
      width: 300,
    },
  ];

  useEffect(() => {
    setOffset(0);
  }, [stockId, shipmentId]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedQ(q);
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [q]);

  const navigate = useNavigate();

  const [updateShipment] = useUpdateShipmentMutation();

  const getAdditionalClassName = ({ row }: RowClassProps): string => {
    return row.isDamaged ? 'damaged' : '';
  };

  const onFinishShipment = () => {
    setConfirmModalData({
      title: 'Вы действительно хотите завершить обработку партии?',
      body: 'После этого посылки партии станут недоступны для редактирования',
      submitTitle: 'Понятно',
    });
    setIsConfirmFinishShipmentModalOpen(true);
  };

  const [isConfirmFinishShipmentModalOpen, setIsConfirmFinishShipmentModalOpen] = useState(false);
  const [confirmModalData, setConfirmModalData] = useState({
    title: '',
    body: '',
    submitTitle: '',
  });

  const onCloseConfirmModal = () => {
    setIsConfirmFinishShipmentModalOpen(false);
    setConfirmModalData({
      title: '',
      body: '',
      submitTitle: '',
    });
  };

  const onSubmitConfirmModal = async () => {
    if (isConfirmFinishShipmentModalOpen && shipmentId) {
      updateShipment({
        variables: {
          id: shipmentId,
          input: {
            isCompleted: true,
          },
        },
      }).then(() => {
        refetchShipments();
        onCloseConfirmModal();
        setConfirmModalData({
          title: '',
          body: '',
          submitTitle: '',
        });
      });
    }
  };

  const isMobile = window.innerWidth < 640;
  const isTablet = window.innerWidth < 1100 && window.innerWidth >= 640;
  const smallScreen = isMobile || isTablet;

  return (
    <>
      <Margin mb={32}>
        <Row
          direction='row'
          justify='space-between'
          align='center'
        >
          <Text size={smallScreen ? isMobile ? '2xl' : '3xl' : '4xl'} view='primary' weight='medium'>
            Посылки
          </Text>
          {!smallScreen && <TextField
            type='text'
            placeholder='Трек-номер посылки'
            form='round'
            width='full'
            style={{width: '270px'}}
            size='s'
            value={q}
            leftSide={IconSearch}
            onChange={({ value }) => setQ(value || undefined)}
          />}
        </Row>
      </Margin>
      {stocks.length && <Margin mb={40}>
        <Row justify='flex-start' align='center' style={{ flexWrap: 'wrap' }}>
          {stocks.map(item => (
            <Margin key={item.id} mr={8} mb={8} style={{ width: 'unset' }}>
              <Button
                view={item.id === stockId ? 'primary' : 'ghost'}
                label={item.name}
                className={item.id === stockId ? 'buttonBlack' : undefined}
                size='s'
                form='round'
                onClick={() => setStockId(item.id)}
              />
            </Margin>
          ))}
        </Row>
      </Margin> || null}
      {shipmentsTabs.length && <Margin mb={30}>
        <Tabs
          value={shipmentsTabs.find(item => item.value === shipmentId)}
          onChange={({ value }) => setShipmentId(value.value)}
          items={shipmentsTabs}
          size='m'
          fitMode='scroll'
        />
      </Margin> || null}
      <Margin mb={40}>
        <Row
          direction={smallScreen ? 'column-reverse' : 'row'}
          justify={smallScreen ? 'flex-start' : 'space-between'}
          align='flex-start'
        >
          {shipment && stats && <Row
            direction={isMobile ? 'column' : 'row'}
            justify='flex-start'
            align='flex-start'
            style={{ width: smallScreen ? '100%' : undefined }}
          >
            <Margin
              mr={isMobile ? 0 : 24}
              mb={isMobile ? 24 : 0}
              style={{width: isTablet ? 'calc(50% - 12px)' : 'unset'}}
            >
              <InfoField
                label='Количество посылок, которое вылетело'
                value={`${data?.packagesList.total || ''}`}
              />
            </Margin>
            <InfoField
              label='Количество принятых посылок'
              value={`${stats[StoreStatus.Checked] || ''}`}
            />
          </Row> || null}
          {shipment?.completeStatus === CompleteStatus.StockCompleted && (
            <Margin mb={smallScreen ? 30 : 0}>
              <Row
                direction={isMobile ? 'column' : 'row'}
                justify={smallScreen ? 'flex-start' : 'flex-end'}
                align={isMobile ? 'flex-start' : 'center'}
                style={{flexWrap: smallScreen ? 'wrap' : undefined}}
              >
                {smallScreen && <TextField
                  type='text'
                  placeholder='Трек-номер посылки'
                  form='round'
                  width='full'
                  style={{
                    width: isMobile ? '100%' : 'calc(50% - 12px)',
                    marginRight: isMobile ? '0' : '24px',
                    marginBottom: smallScreen ? '10px' : '0',
                  }}
                  size='s'
                  value={q}
                  leftSide={IconSearch}
                  onChange={({ value }) => setQ(value || undefined)}
                />}
                <Margin
                  mr={smallScreen ? 0 : 10}
                  mb={smallScreen ? 10 : 0}
                  style={{ width: smallScreen ? isMobile ? '100%' : 'calc(50% - 12px)' : 'unset' }}
                >
                  <Button
                    label='Перейти в режим сканирования'
                    size='s'
                    view='primary'
                    form='round'
                    width={smallScreen ? 'full' : undefined}
                    className='buttonBlack'
                    onClick={() => shipmentId && navigate(`/store-keeper-scanner/${shipmentId}`)}
                  />
                </Margin>
                <Button
                  label='Завершить обработку партии'
                  size='s'
                  view='secondary'
                  form='round'
                  className='buttonSuccess'
                  disabled={Boolean(stats[StoreStatus.Unchecked])}
                  style={{ width: smallScreen ? isMobile ? '100%' : 'calc(50% - 12px)' : 'unset' }}
                  onClick={onFinishShipment}
                />
              </Row>
            </Margin>
          )}
        </Row>
      </Margin>
      {packages?.length ? <>
        {isMobile ? <>
          {packages.map(item => <MobileStoreItem
            key={item.id}
            data={item}
            onClick={(id: string, e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
              // @ts-ignore
              if (shipment?.completeStatus === CompleteStatus.StockCompleted && !e.target.value) {
                const packageId = packages.find(el => el.id === id)?.track || '';
                navigate(`/store-keeper-scanner/${shipmentId}?track=${packageId}`);
              }
            }}
          />)}
        </> : <TableContainer>
          <Table
            rows={packages}
            // @ts-ignore
            columns={columns}
            defaultExpandAll={false}
            getAdditionalClassName={getAdditionalClassName}
            onRowClick={({ id, e }) => {
              // @ts-ignore
              if (shipment?.completeStatus === CompleteStatus.StockCompleted && !e.target.value) {
                const packageId = packages.find(item => item.id === id)?.track || '';
                navigate(`/store-keeper-scanner/${shipmentId}?track=${packageId}`);
              }
            }}
          />
        </TableContainer>}
      </> : <EmptyContainer>
        <Card shadow={false}>
          <Text size={smallScreen ? isMobile ? 'xl' : '2xl' : '3xl'} weight='medium' align='center'>
            Не найдено ни одной посылки
          </Text>
        </Card>
      </EmptyContainer>}
      <ConfirmModal
        isModalOpen={isConfirmFinishShipmentModalOpen}
        title={confirmModalData.title}
        body={confirmModalData.body}
        submitTitle={confirmModalData.submitTitle}
        onClose={onCloseConfirmModal}
        onSubmit={onSubmitConfirmModal}
        closeButtonTitle='Отмена'
        customMaxWidth={600}
      />
    </>
  );
};

export default StoreKeeperPackages;