import styled from 'styled-components';

export const PhoneFlagWrapper = styled.div`
  width: 20px;
  height: 15px;

  @media (max-width: 1100px) {
    width: 16px;
    height: 12px;
  }
`;

export const ProfileActionsWrapper = styled.div`
  align-items: flex-end;
  display: flex;
  .GridItem {
    width: 100%;
  }
  .Button {
    width: 100%;
    @media (max-width: 1100px) and (min-width: 640px) {
      margin-top: 24px;
    }
    @media (max-width: 640px) {
      margin-top: 16px;
    }
  }
`;

export const ProfileFormWrapper = styled.div`
  margin-left: -20px;
  margin-right: -20px;

  .TextField-Icon {
    color: var(--color-typo-warning);
  }

  .Text_view_secondary {
    color: var(--color-typo-primary);
  }

  .TextField-InputContainer_disabled .TextField-Input {
    color: var(--color-type-disabled);
  }

  .Card {
    background: var(--color-bg-default);
    border-radius: 16px;
    padding: 32px;
    height: 100%;
    overflow: hidden;
  }

  .GridItem:last-child {
    align-items: flex-end;
    display: flex;
    div {
      width: 100%;
    }
  }
`;
