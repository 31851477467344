import styled from 'styled-components';

export const DetailedInformationContainer = styled.div`
  background: var(--color-bg-default);
  min-height: calc(100vh - 40px);
  width: 100wv;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 40px;

  @media (max-width: 1024px) {
    padding-top: 40px;
    min-height: calc(100vh - 80px);

    .MixSpace_pL_5xl {
      padding-left: var(--space-l);
    }

    .MixSpace_pR_5xl {
      padding-right: var(--space-l);
    }
  }

  .Grid {
    max-width: 1200px;
  }

  .Card {
    background: var(--color-bg-default);
    border-radius: 40px;
    height: 100%;
    width: 80%;
    overflow: hidden;
    max-width: 1200px;
  }
`;

export const DetailedInformationFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .Button {
    color: var(--color-bg-secondary);

    &:hover {
      color: var(--color-bg-secondary) !important;
    }

    &.Button_disabled {
      color: var(--color-control-typo-placeholder) !important;
    }
  }
`;

export const ActionsWrapper = styled.div`
  .GridItem {
    width: 32%;
  }

  @media (max-width: 1100px) {
    .GridItem {
      width: 49%;
    }
  }
  @media (max-width: 640px) {
    .GridItem {
      width: 100%;
    }
  }
`;

export const FlagWrapper = styled.div`
  width: 16px;
  height: 12px;
`;

export const PhoneFlagWrapper = styled.div`
  width: 20px;
  height: 15px;

  @media (max-width: 1100px) {
    width: 16px;
    height: 12px;
  }
`;
