import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdminUpdatePackageMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  input: Types.AdminUpdatePackageInput;
}>;


export type AdminUpdatePackageMutation = { __typename?: 'Mutation', adminUpdatePackage: { __typename?: 'PackageType', id: number } };


export const AdminUpdatePackageDocument = gql`
    mutation adminUpdatePackage($id: Int!, $input: AdminUpdatePackageInput!) {
  adminUpdatePackage(id: $id, input: $input) {
    id
  }
}
    `;
export type AdminUpdatePackageMutationFn = Apollo.MutationFunction<AdminUpdatePackageMutation, AdminUpdatePackageMutationVariables>;

/**
 * __useAdminUpdatePackageMutation__
 *
 * To run a mutation, you first call `useAdminUpdatePackageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminUpdatePackageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminUpdatePackageMutation, { data, loading, error }] = useAdminUpdatePackageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminUpdatePackageMutation(baseOptions?: Apollo.MutationHookOptions<AdminUpdatePackageMutation, AdminUpdatePackageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminUpdatePackageMutation, AdminUpdatePackageMutationVariables>(AdminUpdatePackageDocument, options);
      }
export type AdminUpdatePackageMutationHookResult = ReturnType<typeof useAdminUpdatePackageMutation>;
export type AdminUpdatePackageMutationResult = Apollo.MutationResult<AdminUpdatePackageMutation>;
export type AdminUpdatePackageMutationOptions = Apollo.BaseMutationOptions<AdminUpdatePackageMutation, AdminUpdatePackageMutationVariables>;