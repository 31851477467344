import React, { FC } from 'react';
import { Card } from '@consta/uikit/Card';
import { Checkbox } from '@consta/uikit/Checkbox';
import { Grid, GridItem } from '@consta/uikit/Grid';
import InfoField from 'features/detailed-package/info-field';
import { PackageRow } from 'pages/stock-keeper/stock-keeper-packages/types';
import { MobileStoreWrapper } from 'features/mobile-store-item/styled';
import Margin from 'shared/ui/components/margin';

interface IProps {
  data: PackageRow;
  isChecked: boolean;
  isDisabled: boolean;
  onChangeCheckbox: (checked: boolean) => void;
  onClick: (id: string, e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

const MobileStoreItem: FC<IProps> = ({ data, isDisabled, isChecked, onChangeCheckbox, onClick }: IProps) => {
  return (
    <MobileStoreWrapper>
      <Card verticalSpace='m' horizontalSpace='s' onClick={(e) => onClick(data.id, e)}>
        <Checkbox
          checked={isChecked}
          disabled={isDisabled}
          onChange={({ checked }): void => onChangeCheckbox(checked)}
          size='l'
        />
        <Margin mb={15}>
          <InfoField label='Трек-номер' value={data.track} />
        </Margin>
        {/* <Margin mb={15}>
          <InfoField label='Получатель' value={data?.user || ''} />
        </Margin> */}
        <Margin mb={15}>
          <Grid cols={2}>
            <GridItem>
              <InfoField label='Номер коробки' value={data?.boxNumber?.toString() || ''} />
            </GridItem>
            <GridItem>
              <InfoField label='Вес' value={`${data?.weight} кг`} />
            </GridItem>
          </Grid>
        </Margin>
        <Margin mb={15}>
          <Grid cols={2}>
            <GridItem>
              <InfoField label='Длина' value={`${data?.length} м`} />
            </GridItem>
            <GridItem>
              <InfoField label='Ширина' value={`${data?.width} м`} />
            </GridItem>
          </Grid>
        </Margin>
        <Margin mb={15}>
          <Grid cols={2}>
            <GridItem>
              <InfoField label='Высота' value={`${data?.height} м`} />
            </GridItem>
            <GridItem>
              <InfoField label='Количество мест' value={data?.packageCount?.toString() || ''} />
            </GridItem>
          </Grid>
        </Margin>
        {/* <Margin mb={15}>
          <InfoField label='Комментарий' value={data?.comment || ''} />
        </Margin> */}
      </Card>
    </MobileStoreWrapper>
  );
};

export default MobileStoreItem;