import { UserCountry } from 'types';

export const countriesMap: Record<UserCountry, string> = {
  kz: 'Казахстан',
  kg: 'Кыргызстан',
  ru: 'Россия',
};

export const additionalCountries = {
  korea: 'korea',
};
