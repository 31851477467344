import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type NotificationsListQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type NotificationsListQuery = { __typename?: 'Query', notificationsList: Array<{ __typename?: 'NotificationType', id: number, title: string, text: string, read: boolean, createdAt: any }> };


export const NotificationsListDocument = gql`
    query NotificationsList {
  notificationsList {
    id
    title
    text
    read
    createdAt
  }
}
    `;

/**
 * __useNotificationsListQuery__
 *
 * To run a query within a React component, call `useNotificationsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsListQuery({
 *   variables: {
 *   },
 * });
 */
export function useNotificationsListQuery(baseOptions?: Apollo.QueryHookOptions<NotificationsListQuery, NotificationsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotificationsListQuery, NotificationsListQueryVariables>(NotificationsListDocument, options);
      }
export function useNotificationsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotificationsListQuery, NotificationsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotificationsListQuery, NotificationsListQueryVariables>(NotificationsListDocument, options);
        }
export type NotificationsListQueryHookResult = ReturnType<typeof useNotificationsListQuery>;
export type NotificationsListLazyQueryHookResult = ReturnType<typeof useNotificationsListLazyQuery>;
export type NotificationsListQueryResult = Apollo.QueryResult<NotificationsListQuery, NotificationsListQueryVariables>;