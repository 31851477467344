import styled from 'styled-components';

export const Wrapper = styled.div`
  max-width: 450px;
  width: 450px;
  @media(max-width: 640px) {
    width: 400px;
  }
  @media(max-width: 425px) {
    width: 400px;
  }
  @media(max-width: 320px) {
    width: 300px;
  }
  background: var(--color-bg-primary);
  border-radius: 4px !important;
  box-shadow: 0px 4px 12px 0px var(--color-bg-shadow);
  border: 1px var(--color-bg-border);
  .empty_message {
    color: var(--color-typo-primary);
    text-align: center;
    height: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
  }
`;

export const NotificationsHeader = styled.div`
  color: #fafafa;
  padding: 22px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--color-bg-border);
  h2 {
    font-size: 16px;
    line-height: 24px;
    margin: 0;
  }
  @media (max-width: 640px) {
    display: block;
    Button {
      padding-left: 0;
    }
  }
`;

export const CardWrapper = styled.div`
  padding: 20px 24px;
  &:not(:last-child) {
    border-bottom: 1px solid var(--color-bg-border);
  }
  p {
    margin: 0;
    color: var(--color-typo-secondary);
    font-size: 16px;
    font-weight: 700;
  }
  .main_info {
    display: flex;
    justify-content: space-between;
    align-items: start;
    gap: 16px;
  }
  .text {
    font-size: 14px;
    margin-top: 8px;
  }
  .date {
    font-size: 12px;
    margin-top: 16px;
  }
  .read,
  .read p {
    color: #fafafa;
  }

  @media (max-width: 1100px) {
    border-bottom: 1px solid var(--color-typo-ghost);
  }

  @media (max-width: 640px) {
    p {
      font-size: 14px;
    }
    .text {
      font-size: 12px;
      margin-top: 4px;
    }
    .date {
      font-size: 10px;
      margin-top: 16px;
    }
  }
`;

export const CardsWrapper = styled.div`
  overflow-y: scroll;
  max-height: 383px;
`;
