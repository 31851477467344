import React, { FC, useState, useEffect, useRef } from 'react';
import { Text } from '@consta/uikit/Text';
import { Button } from '@consta/uikit/Button';
import { TextField } from '@consta/uikit/TextField';
import { IconSettings } from '@consta/uikit/IconSettings';
import { Card } from '@consta/uikit/Card';
import { IconSearch } from '@consta/uikit/IconSearch';
import {
  SortByProps,
  Table,
  TableFilters,
  TableTextFilter,
} from '@consta/uikit/Table';
import { Modal } from '@consta/uikit/Modal';
import { SelectedFilters } from '@consta/uikit/__internal__/src/components/Table/filtering';
import dayjs from 'dayjs';
import {
  DeliveryStatus,
  Maybe,
  PackagesOrderInput,
  PackageType,
  StockCode,
} from 'types';
import { useStocksListQuery } from 'features/main/api/stocksList.generated';
import { EmptyContainer, TableContainer } from 'pages/packages/styled';
import FieldsModal from 'features/admin-repurchase/fields-modal';
import { useAdminPackagesListQuery } from 'features/packages/api/adminPackagesList.generated';
import { getPackageColumns } from 'features/admin-package-row/utils';
import PackageEditForm from 'widgets/package-edit';
import Margin from 'shared/ui/components/margin';
import Row from 'shared/ui/components/row';
import { useIsInViewport } from 'shared/hooks/useIsInViewport';
import { statusesLabels } from 'shared/constants/statuses.constants';
import { additionalCountries } from 'shared/constants/countries.constants';
import {
  ColumnHiddenType,
  PackageData,
  PackageRow,
} from '../../../entities/package-row/types';

const AdminPackages: FC = () => {
  const pageSize = 40;
  const [isEditOpen, setIsEditModalOpen] = useState(false);
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);
  const [packageForEdit, setPackageForEdit] = useState<PackageData | null>(
    null,
  );
  const [packages, setPackages] = useState<PackageRow[]>([]);
  const [stockId, setStockId] = useState<number | undefined>();
  const [q, setQ] = useState<string | undefined>();
  const [debouncedQ, setDebouncedQ] = useState<string | undefined>();

  const [statusFilter, setStatusFilter] = useState<
    DeliveryStatus[] | undefined
  >();
  const [isPaidFilter, setIsPaidFilter] = useState<boolean | undefined>();
  const [sortOrder, setSortOrder] = useState<Maybe<PackagesOrderInput>>(null);

  const footerRef = useRef(document.getElementById('footer'));
  const inViewport = useIsInViewport(footerRef);

  const { data: stocks } = useStocksListQuery();

  const [showModal, setShowModal] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);

  const [packageCountry, setPackageCountry] = useState('');

  const params = new URLSearchParams(window.location.search);
  const country = params.get('country');

  useEffect(() => {
    if (country === additionalCountries.korea) {
      setPackageCountry(country);
    }
  }, [country]);

  useEffect(() => {
    if (stocks?.stocksList?.length && country === additionalCountries.korea) {
      setStockId(stocks.stocksList.find((s) => s.code === StockCode.Kr)?.id);
    } else if (stocks?.stocksList?.length) {
      setStockId(stocks.stocksList[0].id);
    }
  }, [stocks, country]);

  const { data, refetch: refetchPackages } = useAdminPackagesListQuery({
    skip: !stockId,
    variables: {
      filter: {
        stockId,
        q: debouncedQ || undefined,
        statuses: statusFilter,
        isPaid: isPaidFilter,
      },
      pagination: {
        offset: offset * pageSize,
        limit: pageSize,
        order: sortOrder || undefined,
      },
    },
  });

  useEffect(() => {
    const items = data?.packagesList?.items;

    if (items && stockId) {
      setRowsFromPackages([...items] as PackageType[]);
      if (!offset) {
        setTotal(data?.packagesList?.total);
      }
    } else {
      setRowsFromPackages([]);
      setTotal(0);
    }
  }, [data]);

  useEffect(() => {
    if (inViewport && (offset + 1) * pageSize <= total) {
      setOffset(offset + 1);
    }
  }, [inViewport]);

  const damagedPackageTitle = 'Посылка повреждена';

  const setRowsFromPackages = (items: PackageType[]) => {
    const packagesWithRows = items.map<PackageRow>(
      ({
        id,
        track,
        shipment,
        user,
        status,
        description,
        weight,
        rateCost,
        insuranceCost,
        deliveryCost,
        isPaid,
        isDamaged,
        comment,
        deliveryAddress,
        pickupPoint,
        createdAt,
        packageCount,
      }) => ({
        id: id.toString(),
        track,
        shipmentName: shipment?.name,
        userName: user?.name,
        status,
        packageDescription: description,
        weight,
        rateCost,
        insuranceCost,
        deliveryCost,
        isPaid,
        packageCount,
        comment:
          comment || isDamaged
            ? `${comment} ${isDamaged ? damagedPackageTitle : ''}`
            : '',
        pickupPoint: pickupPoint?.name,
        deliveryAddress:
          deliveryAddress &&
          // eslint-disable-next-line max-len
          `${deliveryAddress?.address?.city}, ул. ${deliveryAddress?.address?.street} д. ${deliveryAddress?.address?.home} кв. ${deliveryAddress?.address?.flat}`,
        userId: user?.id,
        createdAt:
          (createdAt && dayjs(new Date(createdAt)).format('DD.MM.YYYY')) ||
          dayjs().format('DD.MM.YYYY'),
        deliveryAddressId: deliveryAddress?.id,
      }),
    );

    if (offset) {
      setPackages([...packages, ...packagesWithRows]);
    } else {
      setPackages(packagesWithRows);
    }
  };

  const [columns, setColumns] = useState(
    getPackageColumns(packageCountry === additionalCountries.korea),
  );
  const [columnsState, setColumnsState] = useState<ColumnHiddenType[]>(
    columns.map((item) => ({ ...item, hidden: false })),
  );

  useEffect(() => {
    setColumns(getPackageColumns(packageCountry === additionalCountries.korea));
  }, [packageCountry]);

  useEffect(() => {
    setColumnsState(columns.map((item) => ({ ...item, hidden: false })));
  }, [columns]);

  useEffect(() => {
    setOffset(0);
  }, [stockId]);

  const handleSortingUpdated = (value: Maybe<SortByProps<PackageRow>>) => {
    setOffset(0);
    if (value) {
      const sort = {
        [value.sortingBy]: value?.sortOrder.toUpperCase(),
      };
      setSortOrder(sort);
    } else {
      setSortOrder(null);
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedQ(q);
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [q]);

  const filterStatus: TableFilters<(typeof packages)[number]> = [
    {
      id: 'status',
      name: 'Статус',
      filterer: (
        cellValue,
        filterValues: Array<{ value: string; name: string }>,
      ) => {
        return filterValues.some(
          (filterValue) => filterValue && filterValue.value === cellValue,
        );
      },
      field: 'status',
      component: {
        name: TableTextFilter,
        props: {
          withSearch: true,
          items: [
            ...(Object.keys(statusesLabels) as Array<DeliveryStatus>).map(
              (status) => ({
                name: statusesLabels[status],
                value: status,
              }),
            ),
          ],
        },
      },
    },
  ];

  const filterIsPaid: TableFilters<(typeof packages)[number]> = [
    {
      id: 'isPaid',
      name: 'Оплачено',
      filterer: (
        cellValue,
        filterValues: Array<{ value: string; name: string }>,
      ) => {
        return filterValues.some(
          (filterValue) => filterValue && filterValue.value === cellValue,
        );
      },
      field: 'isPaid',
      component: {
        name: TableTextFilter,
        props: {
          withSearch: true,
          items: [
            { name: 'Оплачено', value: true },
            { name: 'Не оплачено', value: false },
          ],
        },
      },
    },
  ];

  const filters = [...filterStatus, ...filterIsPaid];

  const handleFilterUpdated = ({ status, isPaid }: SelectedFilters) => {
    if (status?.selected?.length || isPaid?.selected?.length) {
      setIsFiltered(true);
    } else {
      setIsFiltered(false);
    }

    if (status?.value?.length) {
      setStatusFilter(
        status?.value.map(
          (s: { name: string; value: DeliveryStatus }) => s.value,
        ) as DeliveryStatus[],
      );
    } else {
      setStatusFilter(undefined);
    }

    if (isPaid?.value?.length > 1) {
      setIsPaidFilter(undefined);
    } else if (isPaid?.value?.length) {
      setIsPaidFilter(
        isPaid?.value.some(
          (v: { name: string; value: boolean }) => v.value === true,
        ),
      );
    } else {
      setIsPaidFilter(undefined);
    }
  };

  const onRowClick = ({ id }: { id: string }) => {
    const packageData = packages?.find((p) => p.id === id);
    if (packageData) {
      setPackageForEdit({
        id: String(packageData?.id),
        track: packageData?.track || '',
        shipmentName: packageData?.shipmentName || '',
        userName: packageData?.userName || '',
        status: packageData?.status || DeliveryStatus.Created,
        createdAt: packageData?.createdAt,
        packageDescription: packageData?.packageDescription || undefined,
        weight: packageData?.weight,
        rateCost: packageData?.rateCost,
        insuranceCost: packageData?.insuranceCost,
        deliveryCost: packageData?.deliveryCost,
        isPaid: packageData?.isPaid || false,
        comment: packageData?.comment,
        deliveryAddress: packageData?.deliveryAddress,
        pickupPoint: packageData.pickupPoint,
        userId: packageData?.userId,
        packageCount: packageData?.packageCount,
        deliveryAddressId: packageData?.deliveryAddressId,
      });
    }
    setIsEditModalOpen(true);
  };

  const onCloseEditModal = () => {
    setIsEditModalOpen(false);
    refetchPackages({
      filter: {
        stockId,
        q: debouncedQ || undefined,
        statuses: statusFilter,
        isPaid: isPaidFilter,
      },
      pagination: {
        offset: offset * pageSize,
        limit: pageSize,
        order: sortOrder || undefined,
      },
    });
  };

  return (
    <>
      <Modal className='userEditModal' isOpen={isEditOpen}>
        <PackageEditForm
          packageData={packageForEdit}
          onCloseModal={onCloseEditModal}
          country={country || ''}
        />
      </Modal>
      <Margin mb={32}>
        <Row direction='row' justify='flex-start' align='center'>
          <Row justify='space-between' align='center' style={{ width: '100%' }}>
            <Text size='4xl' view='primary' weight='medium'>
              {country === additionalCountries.korea
                ? 'Грузы из Кореи'
                : 'Посылки США / Европа'}
            </Text>
          </Row>
          <Margin mt={0} style={{ width: 'unset' }}>
            <Row direction='row' justify='flex-end' align='center'>
              {(packages?.length && (
                <Margin ml={12} style={{ width: 'unset' }}>
                  <Button
                    onlyIcon
                    form='round'
                    iconLeft={IconSettings}
                    size='s'
                    view='ghost'
                    onClick={() => setShowModal(true)}
                  />
                </Margin>
              )) ||
                null}
              <Margin ml={24} style={{ width: '270px' }}>
                <TextField
                  type='text'
                  placeholder='Трек-номер посылки'
                  form='round'
                  width='full'
                  size='s'
                  value={q}
                  leftSide={IconSearch}
                  onChange={({ value }) => setQ(value || undefined)}
                />
              </Margin>
            </Row>
          </Margin>
        </Row>
      </Margin>
      {(stocks?.stocksList?.length && (
        <Margin mb={40}>
          <Row justify='flex-start' align='center' className='stocksList'>
            {stocks.stocksList
              .filter((s) => {
                if (country === additionalCountries.korea) {
                  return s.code === StockCode.Kr;
                }
                return s.code !== StockCode.Kr;
              })
              .map((item) => (
                <Margin key={item.id} mr={8} mb={8} style={{ width: 'unset' }}>
                  <Button
                    view={item.id === stockId ? 'primary' : 'ghost'}
                    label={item.name}
                    className={item.id === stockId ? 'buttonBlack' : undefined}
                    size='s'
                    form='round'
                    onClick={() => setStockId(item.id)}
                  />
                </Margin>
              ))}
          </Row>
        </Margin>
      )) ||
        null}
      {(packages?.length || isFiltered) && stockId ? (
        <Margin mb={60}>
          <TableContainer>
            <Table
              rows={packages}
              // @ts-ignore
              columns={columnsState}
              defaultExpandAll={false}
              onSortBy={handleSortingUpdated}
              filters={filters}
              onFiltersUpdated={handleFilterUpdated}
              onRowClick={onRowClick}
            />
          </TableContainer>
        </Margin>
      ) : (
        <EmptyContainer>
          <Card shadow={false}>
            <Text size='3xl' weight='medium' align='center'>
              Не найдено ни одной посылки
            </Text>
          </Card>
        </EmptyContainer>
      )}
      <FieldsModal
        isModalOpen={showModal}
        columns={columnsState}
        onClose={() => setShowModal(false)}
        onSubmit={(editedColumns) => setColumnsState(editedColumns)}
      />
    </>
  );
};

export default AdminPackages;
