import { ApolloClient, ApolloProvider, createHttpLink, ApolloLink } from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { FC } from 'react';
import { showErrorNotification } from 'features/notifications/ui/showErrorNotification';
import { getAuthToken, removeAuthToken } from 'shared/api/auth';

/**
 * Setup cache.
 */
const cache = new InMemoryCache();

/**
 * Provide opportunity to make GraphQL queries over HTTP.
 */
const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URL + '/graphql',
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getAuthToken();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token || '',
    },
  };
});

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    // @ts-ignore
    graphQLErrors.forEach(({ message, statusCode }) => {
      if (statusCode === 'UNAUTHORIZED' || statusCode === 'UNAUTHENTICATED') {
        removeAuthToken();
        window.location.reload();
      } else {
        showErrorNotification(message);
      }
    });
  }
});

const client = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, httpLink]),
  cache,
  connectToDevTools: process.env.NODE_ENV === 'development',
});

const withApollo = <P extends object>(Component: FC<P>): FC<P> => {
  return function WithApollo(props) {
    return (
      <ApolloProvider client={client}>
        <Component {...props} />
      </ApolloProvider>
    );
  };
};

export const clearCache = async () => await cache.reset();
export default withApollo;
