import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdatePackageMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  input: Types.UpdatePackageInput;
}>;


export type UpdatePackageMutation = { __typename?: 'Mutation', updatePackage: { __typename?: 'PackageType', id: number, track: string, status: Types.DeliveryStatus, cargoType: Types.CargoType, storeStatus: Types.StoreStatus, description?: string | null, weight?: number | null, createdAt: any, packageCost?: number | null, packageCount?: number | null, insuranceCost?: number | null, isPaid: boolean, isDamaged: boolean, rateCost: number, deliveryRate: { __typename?: 'DeliveryRateType', id: number, name: string, cost: number, time: string, description?: string | null, stock: { __typename?: 'StockType', id: number, name: string, code: Types.StockCode, address: { __typename?: 'StockAddressType', suite?: string | null, addressFirst: string, addressSecond?: string | null, city: string, state?: string | null, zipCode: string, country: string, phone?: string | null } } }, deliveryAddress?: { __typename?: 'AddressType', id: number, country: Types.UserCountry, createdAt: any, isFavorite: boolean, isUsed: boolean, address: { __typename?: 'AddressDetailsType', city: string, street: string, home: string, flat: string } } | null, pickupPoint?: { __typename?: 'PickupPointType', id: number, name: string, useAddress: boolean } | null } };


export const UpdatePackageDocument = gql`
    mutation updatePackage($id: Int!, $input: UpdatePackageInput!) {
  updatePackage(id: $id, input: $input) {
    id
    track
    status
    cargoType
    storeStatus
    description
    weight
    createdAt
    packageCost
    packageCount
    insuranceCost
    isPaid
    isDamaged
    rateCost
    deliveryRate {
      id
      name
      cost
      time
      description
      stock {
        id
        name
        code
        address {
          suite
          addressFirst
          addressSecond
          city
          state
          zipCode
          country
          phone
        }
      }
    }
    deliveryAddress {
      id
      country
      address {
        city
        street
        home
        flat
      }
      createdAt
      isFavorite
      isUsed
    }
    pickupPoint {
      id
      name
      useAddress
    }
  }
}
    `;
export type UpdatePackageMutationFn = Apollo.MutationFunction<UpdatePackageMutation, UpdatePackageMutationVariables>;

/**
 * __useUpdatePackageMutation__
 *
 * To run a mutation, you first call `useUpdatePackageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePackageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePackageMutation, { data, loading, error }] = useUpdatePackageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePackageMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePackageMutation, UpdatePackageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePackageMutation, UpdatePackageMutationVariables>(UpdatePackageDocument, options);
      }
export type UpdatePackageMutationHookResult = ReturnType<typeof useUpdatePackageMutation>;
export type UpdatePackageMutationResult = Apollo.MutationResult<UpdatePackageMutation>;
export type UpdatePackageMutationOptions = Apollo.BaseMutationOptions<UpdatePackageMutation, UpdatePackageMutationVariables>;