import styled from 'styled-components';

export const MobileStoreWrapper = styled.div`
  margin-bottom: 15px;
  .Card {
    border-radius: 16px;
    background: var(--color-bg-default);
    width: 100%;
    position: relative;

    .Checkbox {
      position: absolute;
      top: 15px;
      right: 15px;
      .Checkbox-Input {
        border-radius: 4px;
      }
    }
  }
`;