import React, { FC, useEffect, useState } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { Text } from '@consta/uikit/Text';
import { Button } from '@consta/uikit/Button';
import { TextField } from '@consta/uikit/TextField';
import { Card } from '@consta/uikit/Card';
import { IconSearch } from '@consta/uikit/IconSearch';
import { Grid, GridItem } from '@consta/uikit/Grid';
import { useShipmentStatsQuery } from 'features/shipment-stats/api/shipmentStats.generated';
import { useShipmentsListQuery } from 'features/shipments/api/shipmentsList.generated';
import { useAdminPackagesListQuery } from 'features/packages/api/adminPackagesList.generated';
import { useAdminCreatePackageMutation } from 'features/add-package/api/adminCreatePackage.generated';
import { useAdminUpdatePackageMutation } from 'features/packages/api/adminUpdatePackage.generated';
import { showErrorNotification } from 'features/notifications/ui/showErrorNotification';
import { ScannerCardWrapper } from 'pages/store-keeper/store-keeper-scanner/styled';
import { AdminPackageType } from 'pages/store-keeper/store-keeper-scanner/types';
import InfoField from 'features/shipment-stats/infoField';
import Scanner from 'features/scanner';
import Margin from 'shared/ui/components/margin';
import Row from 'shared/ui/components/row';

const StockKeeperScanner: FC = () => {
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    for (const entry of searchParams.entries()) {
      switch (entry[0]) {
        case 'track':
          setPackageTrack(entry[1]);
          break;
        default:
          break;
      }
    }
  }, []);

  const [packageTrack, setPackageTrack] = useState<string | undefined>();
  const [q, setQ] = useState<string | null>(null);
  const [packageData, setPackageData] = useState<AdminPackageType | undefined>();
  const [isCreateMode, setIsCreateMode] = useState(false);
  const [isOtherShipment, setIsOtherShipment] = useState(false);

  const [track, setTrack] = useState<string | null>(null);
  const [length, setLength] = useState<number | null>();
  const [width, setWidth] = useState<number | null>();
  const [height, setHeight] = useState<number | null>();
  const [weight, setWeight] = useState<number | null>();
  const [boxNumber, setBoxNumber] = useState<number | null>();

  const [weightError, setWeightError] = useState(false);
  const [lengthError, setLengthError] = useState(false);
  const [widthError, setWidthError] = useState(false);
  const [heightError, setHeightError] = useState(false);
  const [boxNumberError, setBoxNumberError] = useState(false);

  const { data: shipments } = useShipmentsListQuery({
    variables: {
      filter: {},
    },
  });

  const { data: stats, refetch } = useShipmentStatsQuery({
    fetchPolicy: 'no-cache',
    skip: !id,
    variables: {
      id: +id!,
    },
  });

  const { data } = useAdminPackagesListQuery({
    skip: !packageTrack,
    variables: {
      filter: {
        track: packageTrack,
        // stockId: admin!.stockId,
      },
      pagination: {
        limit: 1,
      },
    },
  });

  useEffect(() => {
    searchParams.delete('track');
    setSearchParams(searchParams);
    setCamera(false);
    if (data?.packagesList.items.length) {
      setIsCreateMode(false);
      if (data.packagesList.items[0].shipment?.id && data.packagesList.items[0].shipment?.id !== +id!) {
        setIsOtherShipment(true);
        clearData();
        showErrorNotification('Посылка в другой партии');
      } else {
        setPackageData(data.packagesList.items[0]);
        setTrack(data.packagesList.items[0].track || null);
        setLength(data.packagesList.items[0].size?.length || null);
        setWidth(data.packagesList.items[0].size?.width || null);
        setHeight(data.packagesList.items[0].size?.height || null);
        setWeight(data.packagesList.items[0].weight || null);
        setBoxNumber(data.packagesList.items[0].boxNumber || null);
        setIsOtherShipment(false);
      }
    } else if (packageTrack) {
      setIsCreateMode(true);
      setIsOtherShipment(false);
      clearData();
      setTrack(packageTrack);
    }
  }, [data]);

  const navigate = useNavigate();

  const [camera, setCamera] = useState(false);

  const onDetected = (result: string) => {
    setPackageTrack(result);
  };

  const [updatePackage] = useAdminUpdatePackageMutation();
  const [createPackage] = useAdminCreatePackageMutation();

  const onFinish = async () => {
    if (isCreateMode) {
      await createPackage({
        variables: {
          input: {
            track: track!,
            weight,
            size: {
              width,
              height,
              length,
            },
            boxNumber,
            shipmentId: +id!,
          },
        },
      }).then(() => {
        clearData();
        setPackageData(undefined);
        setPackageTrack(undefined);

        return refetch();
      })
    } else if (packageData && !isOtherShipment) {
      await updatePackage({
        variables: {
          id: packageData.id,
          input: {
            weight,
            size: {
              width,
              height,
              length,
            },
            boxNumber,
            shipmentId: +id!,
          },
        },
      }).then(() => {
        clearData();
        setPackageData(undefined);
        setPackageTrack(undefined);

        return refetch();
      });
    }
  };

  const clearData = () => {
    setPackageData(undefined);
    setLength(null);
    setWidth(null);
    setHeight(null);
    setWeight(null);
    setBoxNumber(null);
  };

  const isMobile = window.innerWidth < 640;
  const isTablet = window.innerWidth < 1100 && window.innerWidth >= 640;
  const smallScreen = isMobile || isTablet;

  return (
    <>
      <Margin mb={32}>
        <Row
          direction={isMobile ? 'column' : 'row'}
          justify={isMobile ? 'flex-start' : 'space-between'}
          align={isMobile ? 'flex-start' : 'center'}
        >
        <Text size={smallScreen ? isMobile ? '2xl' : '3xl' : '4xl'} view='primary' weight='medium'>
          {shipments?.shipmentsList?.find(item => item.id === +id!)?.name || ''}
        </Text>
        <Button
          label='Выйти из режима сканирования'
          size='s'
          view='primary'
          form='round'
          width={isMobile ? 'full' : undefined}
          style={{ marginTop: isMobile ? '15px' : '0' }}
          className='buttonBlack'
          onClick={() => navigate('/store-keeper-packages')}
        />
      </Row>
      </Margin>
      <Margin mb={40}>
        {stats && <InfoField
          label='Количество посылок в партии'
          value={`${stats.shipmentStats.reduce((acc, item) => acc + item.count, 0)}`}
        /> || null}
      </Margin>
      <Margin mb={24}>
        <Button
          label={camera ? 'Ввести вручную' : 'Сканировать посылку'}
          view='ghost'
          form='round'
          width='full'
          size='m'
          iconLeft={IconSearch}
          style={{width: '300px', marginLeft: 'calc(50% - 150px)'}}
          onClick={() => {
            clearData();
            setCamera(!camera);
          }}
        />
      </Margin>
      <ScannerCardWrapper>
        <Card
          verticalSpace={isMobile ? 'm' : 'l'}
          horizontalSpace={isMobile ? 's' : 'l'}
          shadow={false}
        >
          {(packageTrack && !isOtherShipment) ? <Grid cols={isMobile ? 1 : 2} colGap='l'>
            <GridItem>
              <Margin mb={32}>
                <TextField
                  label='Трек номер'
                  type='text'
                  placeholder='1232131222122'
                  form='round'
                  width='full'
                  disabled={Boolean(packageData)}
                  size='s'
                  value={track}
                  onChange={({ value }) => setTrack(value)}
                  status={!track?.length ? 'alert' : undefined}
                  caption={!track?.length ? 'Поле не заполнено' : undefined}
                />
              </Margin>
              <Margin mb={32}>
                <TextField
                  label='Длина (метры)'
                  type='number'
                  placeholder='12.3'
                  form='round'
                  width='full'
                  size='s'
                  required
                  incrementButtons={false}
                  value={length && isNaN(length) ? '' : `${length}`}
                  onChange={({ value }) => setLength(Number(value) || undefined)}
                  onBlur={() => setLengthError(!length)}
                  status={lengthError ? 'alert' : undefined}
                  caption={lengthError ? 'Поле не заполнено' : undefined}
                />
              </Margin>
              <Margin mb={32}>
                <TextField
                  label='Высота (метры)'
                  type='number'
                  placeholder='12.3'
                  form='round'
                  width='full'
                  size='s'
                  required
                  incrementButtons={false}
                  value={height && isNaN(height) ? '' : `${height}`}
                  onChange={({ value }) => setHeight(Number(value) || undefined)}
                  onBlur={() => setHeightError(!height)}
                  status={heightError ? 'alert' : undefined}
                  caption={heightError ? 'Поле не заполнено' : undefined}
                />
              </Margin>
              <Margin mb={32}>
                <TextField
                  label='Номер коробки'
                  type='number'
                  placeholder='1215'
                  form='round'
                  width='full'
                  size='s'
                  required
                  incrementButtons={false}
                  value={boxNumber && isNaN(boxNumber) ? '' : `${boxNumber}`}
                  onChange={({ value }) => setBoxNumber(Number(value) || undefined)}
                  onBlur={() => setBoxNumberError(!boxNumber)}
                  status={boxNumberError ? 'alert' : undefined}
                  caption={boxNumberError ? 'Поле не заполнено' : undefined}
                />
              </Margin>
            </GridItem>
            <GridItem>
              <Margin mb={32}>
                <TextField
                  label='Вес (кг)'
                  type='number'
                  placeholder='12.3'
                  form='round'
                  width='full'
                  size='s'
                  required
                  incrementButtons={false}
                  value={weight && isNaN(weight) ? '' : `${weight}`}
                  onChange={({ value }) => setWeight(Number(value) || undefined)}
                  onBlur={() => setWeightError(!weight)}
                  status={weightError ? 'alert' : undefined}
                  caption={weightError ? 'Поле не заполнено' : undefined}
                />
              </Margin>
              <Margin mb={32}>
                <TextField
                  label='Ширина (метры)'
                  type='number'
                  placeholder='12.3'
                  form='round'
                  width='full'
                  size='s'
                  required
                  incrementButtons={false}
                  value={width && isNaN(width) ? '' : `${width}`}
                  onChange={({ value }) => setWidth(Number(value) || undefined)}
                  onBlur={() => setWidthError(!width)}
                  status={widthError ? 'alert' : undefined}
                  caption={widthError ? 'Поле не заполнено' : undefined}
                />
              </Margin>
              <Button
                className='finishButton buttonBlack'
                disabled={
                  !track ||
                  !weight ||
                  !width ||
                  !height || 
                  !length || 
                  !boxNumber
                }
                view='primary'
                label='Добавить'
                form='round'
                onClick={onFinish}
              />
            </GridItem>
          </Grid> : <>
            {!camera && <div className='emptyLabel'>
              <Margin mb={15}>
                <Text size={smallScreen ? isMobile ? 'xl' : '2xl' : '3xl'} weight='medium' align='center'>
                  Отсканируйте посылку или введите трек-номер вручную
                </Text>
              </Margin>
              <Row justify='center' align='center'>
                <TextField
                  type='text'
                  placeholder='Трек-номер посылки'
                  form='round'
                  style={{width: isMobile ? 'calc(100% - 42px)' : '270px'}}
                  size='s'
                  value={q}
                  leftSide={IconSearch}
                  onChange={({ value }) => setQ(value)}
                />
                <Margin ml={10} style={{width: 'unset'}}>
                  <Button
                    onlyIcon
                    iconLeft={IconSearch}
                    className='buttonBlack'
                    size='s'
                    form='round'
                    onClick={() => setPackageTrack(q || undefined)}
                  />
                </Margin>
              </Row>
            </div>}
          </>}
          {camera && <div className='container'>
            <Scanner onDetected={onDetected} />
          </div>}
        </Card>
      </ScannerCardWrapper>
    </>
  );
};

export default StockKeeperScanner;