import styled from 'styled-components';

export const LoginContainer = styled.div`
  background: var(--color-bg-secondary);
  min-height: calc(100vh - 80px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;

  @media (max-width: 1024px) {
    padding: 20px;
    min-height: calc(100vh - 40px);

    .MixSpace_pL_5xl {
      padding-left: var(--space-l);
    }

    .MixSpace_pR_5xl {
      padding-right: var(--space-l);
    }
  }

  .Grid {
    max-width: 1200px;

    .GridItem {
      display: flex;
      justify-content: center;
    }
  }

  .Card {
    background: var(--color-bg-default);
    border-radius: 40px;
    height: 100%;
    width: 100%;
    overflow: hidden;
    max-width: 744px;
  }
`;

export const LoginFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .Button {
    color: var(--color-bg-secondary);

    &:hover {
      color: var(--color-bg-secondary) !important;
    }

    &.Button_disabled {
      color: var(--color-control-typo-placeholder) !important;
    }
  }
`;

export const LoginFormWallpaper = styled.div`
  img {
    width: 100%;
  }
`;

export const CaptchaWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
