import styled from 'styled-components';

export const DeliveryRateCard = styled.div`
  & > .Card {
    background: var(--color-bg-secondary);
    border-radius: 16px;
  }
`;

export const TooltipContent = styled.div`
  .Button, .Button:hover {
    background: var(--color-bg-default) !important;
    color: var(--color-control-typo-primary) !important;
    margin-top: 15px;
  }
`;