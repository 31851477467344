import React, { FC, useState, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import { useSearchParams } from 'react-router-dom';
import { Text } from '@consta/uikit/Text';
import { Button } from '@consta/uikit/Button';
import { Badge } from '@consta/uikit/Badge';
import { TextField } from '@consta/uikit/TextField';
import { IconBackward } from '@consta/uikit/IconBackward';
import { IconAdd } from '@consta/uikit/IconAdd';
import { Tabs } from '@consta/uikit/Tabs';
import { Card } from '@consta/uikit/Card';
import { IconSearch } from '@consta/uikit/IconSearch'
import { SortByProps, Table, TableFilters } from '@consta/uikit/Table';
import { SelectedFilters } from '@consta/uikit/__internal__/src/components/Table/filtering';
import { useNavigate } from 'react-router-dom';
import { US, EU, KR } from 'country-flag-icons/react/3x2';
import { DeliveryStatus, StockCode, Maybe, PackagesOrderInput, PackageType } from 'types';
import { usePackagesListQuery } from 'features/packages/api/packagesList.generated';
import MobilePackages from 'features/packages/mobile-packages';
import Margin from 'shared/ui/components/margin';
import Row from 'shared/ui/components/row';
import { useIsInViewport } from 'shared/hooks/useIsInViewport';
import { statusesBadges, statusesLabels } from 'shared/constants/statuses.constants';
import { EmptyContainer, TableContainer, TabsContainer, TabItem } from './styled';
import { PackageRow } from './types';

const tabs: Record<StockCode, string> = {
  [StockCode.Us]: 'США',
  [StockCode.Kr]: 'Южная Корея',
  [StockCode.Eu]: 'Италия',
};

const flagMap = {
  [tabs.eu]: <EU />,
  [tabs.kr]: <KR />,
  [tabs.us]: <US />,
};

const getStockCode = (value: string) => {
  const [code] = Object.entries(tabs).find(([, v]) => v === value) || [];
  if (!code) {
    return;
  }

  return code as StockCode;
};

const PackagesPage: FC = () => {
  const [isHistory, setIsHistory] = useState(false);

  const pageSize = 40;
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);

  const [packages, setPackages] = useState<PackageRow[]>([]);
  const [stockCode, setStockCode] = useState<StockCode>(StockCode.Us);
  const [q, setQ] = useState<string | undefined>();
  const [debouncedQ, setDebouncedQ] = useState<string | undefined>();

  const [isFiltered, setIsFiltered] = useState(false);

  useEffect(() => {
    if (isHistory) {
      setStatusFilter([DeliveryStatus.Done]);
    } else {
      setStatusFilter([DeliveryStatus.Created, DeliveryStatus.InTransit, DeliveryStatus.ReadyForDelivery]);
    }
    setIsFiltered(false);
  }, [isHistory]);

  const [searchParams, setSearchParams] = useSearchParams();
  
  useEffect(() => {
    for (const entry of searchParams.entries()) {
      switch (entry[0]) {
        case 'q':
          setQ(entry[1]);
          setDebouncedQ(entry[1]);
          break;
        case 'history':
          setIsHistory(JSON.parse(entry[1]));
          break;
        case 'stock':
          setStockCode(getStockCode(entry[1]) || stockCode);
          break;
        default:
          break;
      }
    }

    refetch();
  }, []);

  const [statusFilter, setStatusFilter] = useState<DeliveryStatus[]>([
    DeliveryStatus.Created,
    DeliveryStatus.InTransit,
    DeliveryStatus.ReadyForDelivery,
  ]);
  const [sortOrder, setSortOrder] = useState<Maybe<PackagesOrderInput>>(null);

  const footerRef = useRef(document.getElementById('footer'));
  const inViewport = useIsInViewport(footerRef);

  const { data, refetch } = usePackagesListQuery({
    variables: {
      filter: {
        stockCode,
        q: debouncedQ || undefined,
        statuses: statusFilter,
      },
      pagination: {
        offset: offset * pageSize,
        limit: pageSize,
        order: sortOrder || undefined,
      },
    },
  });

  useEffect(() => {
    const items = data?.packagesList?.items;

    if (items) {
      setRowsFromPackages([...items] as PackageType[]);
      if (!offset) {
        setTotal(data?.packagesList?.total);
      }
    }

  }, [data]);

  useEffect(() => {
    if (inViewport && ((offset + 1) * pageSize <= total)) {
      setOffset(offset + 1);
    }
  }, [inViewport]);

  const setRowsFromPackages = (items: PackageType[]) => {
    const packagesWithRows: PackageRow[] = items.map(({
      id, track, status, weight, description, deliveryCost, createdAt, packageCount,
    }) => ({
      id: id.toString(),
      track,
      status,
      weight: weight ?? undefined,
      description: description ?? undefined,
      deliveryCost: deliveryCost ?? undefined,
      packageCount: packageCount ?? undefined,
      createdAt: dayjs(createdAt).format('DD.MM.YYYY'),
    }));

    if (offset) {
      setPackages([...packages, ...packagesWithRows]);
    } else {
      setPackages(packagesWithRows);
    }
  };

  const initColumns = [
    {
      title: 'ТРЕК-НОМЕР',
      accessor: 'track',
      sortable: false,
    },
    {
      title: 'ОПИСАНИЕ',
      accessor: 'description',
      sortable: false,
      width: 250,
    },
    {
      title: 'СТАТУС',
      accessor: 'status',
      renderCell: ({ status }: PackageRow) => <Badge status={statusesBadges[status]} label={statusesLabels[status]} />,
    },
    {
      title: 'ВЕС',
      accessor: 'weight',
      sortable: true,
    },
    {
      title: 'СТОИМОСТЬ ДОСТАВКИ',
      accessor: 'deliveryCost',
      sortable: true,
      width: 220,
    },
    {
      title: 'КОЛИЧЕСТВО МЕСТ',
      accessor: 'packageCount',
      sortable: true,
      width: 220,
    },
    {
      title: 'ДАТА ДОБАВЛЕНИЯ',
      accessor: 'createdAt',
      sortable: true,
      width: 220,
    },
  ];

  const [columns, setColumns] = useState<unknown[]>([]);

  useEffect(() => {
    if (stockCode === StockCode.Kr) {
      setColumns(initColumns.filter(item => item.accessor !== 'deliveryCost'));
    } else {
      setColumns(initColumns.filter(item => item.accessor !== 'packageCount'));
    }

    setOffset(0);
  }, [stockCode])

  const filterStatus: TableFilters<typeof packages[number]> = Object.keys(statusesLabels).map((status) => ({
    id: Object.values(DeliveryStatus).find(item => item === status) || DeliveryStatus.Created,
    name: statusesLabels[status as DeliveryStatus],
    filterer: () => true,
    field: 'status',
  }));

  const handleFilterUpdated = ({ status }: SelectedFilters) => {
    setOffset(0);

    if (status?.selected?.length) {
      setStatusFilter([status.selected[0]] as DeliveryStatus[]);
      setIsFiltered(true);
    } else {
      setStatusFilter([DeliveryStatus.Created, DeliveryStatus.InTransit, DeliveryStatus.ReadyForDelivery]);
      setIsFiltered(false);
    }
  };

  const handleSortingUpdated = (value: Maybe<SortByProps<PackageRow>>) => {
    setOffset(0);
    if (value) {
      const sort = {
        [value.sortingBy]: value?.sortOrder.toUpperCase(),
      };
      setSortOrder(sort);
    } else {
      setSortOrder(null);
    }
  };

  const changeTab = (value: string) => {
    const code = getStockCode(value);
    if (code) {
      setStockCode(code);
      searchParams.set('stock', value);
      setSearchParams(searchParams);
    }
  };

  const setHistoryMode = (value: boolean) => {
    setIsHistory(value);
    searchParams.set('history', `${value}`);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedQ(q);
      if (q) {
        searchParams.set('q', q);
      } else {
        searchParams.delete('q');
      }
      setSearchParams(searchParams);
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [q]);

  const navigate = useNavigate();

  const isMobile = window.innerWidth < 640;
  const isTablet = window.innerWidth < 1100 && window.innerWidth >= 640;
  const smallScreen = isMobile || isTablet;

  return (
    <>
      {isHistory && <Button
        view='clear'
        size='s'
        label='Назад к посылкам'
        iconLeft={IconBackward}
        onClick={() => setHistoryMode(false)}
      />}
      <Margin mb={smallScreen ? 40 : 32}>
        <Row
          direction={smallScreen ? 'column' : 'row'}
          justify='flex-start'
          align={smallScreen ? 'flex-start' : 'center'}
        >
          <Row justify='space-between' align='center' style={{ width: '100%' }}>
            <Text size={smallScreen ? isMobile ? '2xl' : '3xl' : '4xl'} view='primary' weight='medium'>
              {isHistory ? 'История заказов' : 'Посылки'}
            </Text>
            {!isHistory && <Button
              view='clear'
              size='s'
              label='История заказов'
              onClick={() => setHistoryMode(true)}
            />}
          </Row>
          <Margin mt={smallScreen ? 20 : 0} style={{ width: smallScreen ? '100%' : 'unset' }}>
            <Row
              direction={isMobile ? 'column-reverse' : 'row'}
              justify={smallScreen ? isMobile ? 'flex-start' : 'space-between' : 'flex-end'}
              align={isMobile ? 'flex-start' : 'center'}
            >
              <Margin
                ml={smallScreen ? 0 : 12}
                mr={smallScreen ? 0 : 24}
                mt={isMobile ? 8 : 0}
                style={{width: isMobile ? '100%' : '270px'}}
              >
                <TextField
                  type='text'
                  placeholder='Трек-номер или описание посылки'
                  form='round'
                  width='full'
                  size='s'
                  value={q}
                  leftSide={IconSearch}
                  onChange={({ value }) => setQ(value || undefined)}
                />
              </Margin>
              <Button
                className='buttonBlack'
                size='s'
                form='round'
                label='Добавить посылку'
                iconRight={IconAdd}
                onClick={() => navigate('/add-package')}
              />
            </Row>
          </Margin>
        </Row>
      </Margin>
      <Margin mb={smallScreen ? 20 : 40}>
        <TabsContainer>
          <Tabs
            value={tabs[stockCode]}
            onChange={({ value }) => changeTab(value)}
            items={Object.values(tabs)}
            getItemLabel={(item) => item}
            size='m'
            renderItem={({item, label, onChange, checked}) => (
              <TabItem onClick={onChange}>
                {flagMap[item]}
                <Text
                  view={checked ? 'primary' : 'secondary'}
                  size={isMobile ? 'xs' : 'm'}
                  align='left'
                >
                  {label}
                </Text>
              </TabItem>
            )}
          />
        </TabsContainer>
      </Margin>
      {(packages?.length || isFiltered)
        ? <>{isMobile
            ? <MobilePackages
                packages={packages}
                stockCode={stockCode}
                onChangeFilter={({ status }: SelectedFilters) => handleFilterUpdated({ status })}
                onChangeSort={(value: Maybe<SortByProps<PackageRow>>) => handleSortingUpdated(value)}
              />
            : <TableContainer>
              <Table
                rows={packages}
                // @ts-ignore
                columns={columns}
                filters={isHistory ? undefined : filterStatus}
                defaultExpandAll={false}
                onFiltersUpdated={handleFilterUpdated}
                onSortBy={handleSortingUpdated}
                onRowClick={({ id }) => navigate(`/packages/${id}`)}
              />
            </TableContainer>
          }</>
        : <EmptyContainer>
          <Card shadow={false}>
            <Text size={smallScreen ? isMobile ? 'l' : '2xl' : '3xl'} weight='medium' align='center'>
              Вы еще не добавили ни одной посылки
            </Text>
            <Button
              className='buttonBlack'
              size='s'
              form='round'
              label='Добавить посылку'
              iconRight={IconAdd}
              onClick={() => navigate('/add-package')}
            />
          </Card>
        </EmptyContainer>}
    </>
  );
};

export default PackagesPage;