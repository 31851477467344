import React, { FC, useState, useEffect, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import FileSaver from 'file-saver';
import { Text } from '@consta/uikit/Text';
import { Button } from '@consta/uikit/Button';
import { TextField } from '@consta/uikit/TextField';
import { Tabs } from '@consta/uikit/Tabs';
import { Card } from '@consta/uikit/Card';
import { IconSearch } from '@consta/uikit/IconSearch'
import { Table } from '@consta/uikit/Table';
import { Checkbox } from '@consta/uikit/Checkbox';
import { Modal } from '@consta/uikit/Modal';
import { PackageType, CompleteStatus } from 'types';
import { useAdmin } from 'entities/admin';
import ConfirmModal from 'entities/ui/confirmModal';
import { useAdminPackagesListQuery } from 'features/packages/api/adminPackagesList.generated';
import { useShipmentsListQuery } from 'features/shipments/api/shipmentsList.generated';
import { useShipmentStatsQuery } from 'features/shipment-stats/api/shipmentStats.generated';
import { useUpdateShipmentMutation } from 'features/shipments/api/updateShipment.generated';
import { useCreateShipmentMutation } from 'features/shipments/api/createShipment.generated';
import { useDeleteShipmentMutation } from 'features/shipments/api/deleteShipment.generated';
import { useDeletePackageMutation } from 'features/detailed-package/api/deletePackage.generated';
import MobileStockItem from 'features/mobile-stock-item';
import InfoField from 'features/shipment-stats/infoField';
import { EmptyContainer, TableContainer } from 'pages/packages/styled';
import { RemoveButtonContainer } from 'pages/detailed-package/styled';
import {
  ConfirmModalWrapper,
  ConfirmModalHeaderWrapper,
  BodyWrapper,
  ActionsWrapper,
} from 'entities/ui/confirmModal/styled';
import Margin from 'shared/ui/components/margin';
import Row from 'shared/ui/components/row';
import { getAuthToken } from 'shared/api/auth';
import { useIsInViewport } from 'shared/hooks/useIsInViewport';
import { PackageRow, ShipmentsTab } from './types';
import { BottomCounterContainer } from './styled';

const StockKeeperPackages: FC = () => {
  const pageSize = 40;
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);

  const { admin } = useAdmin();

  const [packages, setPackages] = useState<PackageRow[]>([]);
  const [shipmentId, setShipmentId] = useState<number | undefined>();
  const [q, setQ] = useState<string | undefined>();
  const [debouncedQ, setDebouncedQ] = useState<string | undefined>();

  const [deletePackagesList, setDeletePackagesList] = useState<number[]>([]);

  const footerRef = useRef(document.getElementById('footer'));
  const inViewport = useIsInViewport(footerRef);

  const [shipmentsTabs, setShipmentsTabs] = useState<ShipmentsTab[]>([]);

  const { data: shipments, refetch: refetchShipments } = useShipmentsListQuery({
    fetchPolicy: 'no-cache',
    skip: !admin?.stockId,
    variables: {
      filter: {
        stockId: admin!.stockId,
      },
    },
  });

  const shipment = useMemo(() => shipments?.shipmentsList.find(s => s.id === shipmentId), [shipments, shipmentId]);

  useEffect(() => {
    if (shipments?.shipmentsList?.length) {
      setShipmentId(shipments.shipmentsList[0]?.id);
      setShipmentsTabs(shipments.shipmentsList.map(item => ({ label: item.name, value: item.id })));
    } else {
      setShipmentId(undefined);
      setShipmentsTabs([]);
    }
  }, [shipments]);

  const { data, refetch } = useAdminPackagesListQuery({
    fetchPolicy: 'no-cache',
    skip: !admin?.stockId,
    variables: {
      filter: {
        // stockId: admin!.stockId,
        q: debouncedQ || undefined,
        shipmentId,
      },
      pagination: {
        offset: offset * pageSize,
        limit: pageSize,
      },
    },
  });

  useEffect(() => {
    const items = data?.packagesList?.items;

    if (items && admin?.stockId && shipmentId) {
      setRowsFromPackages([...items] as PackageType[]);
      if (!offset) {
        setTotal(data?.packagesList?.total);
      }
    } else {
      setRowsFromPackages([]);
      setTotal(0);
    }
  }, [data]);

  const { data: stats } = useShipmentStatsQuery({
    fetchPolicy: 'no-cache',
    skip: !shipmentId,
    variables: {
      id: +shipmentId!,
    },
  });

  useEffect(() => {
    if (inViewport && ((offset + 1) * pageSize <= total)) {
      setOffset(offset + 1);
    }
  }, [inViewport]);

  const setRowsFromPackages = (items: PackageType[]) => {
    const packagesWithRows: PackageRow[] = items.map(({
      id, track, size, packageCount, weight, boxNumber,
    }) => ({
      id: id.toString(),
      track,
      boxNumber: boxNumber ?? undefined,
      weight: weight ?? undefined,
      length: size?.length ?? undefined,
      height: size?.height ?? undefined,
      width: size?.width ?? undefined,
      packageCount: packageCount ?? undefined,
    }));

    if (offset) {
      setPackages([...packages, ...packagesWithRows]);
    } else {
      setPackages(packagesWithRows);
    }
  };

  const columns = [
    {
      accessor: 'id',
      title: <Checkbox
        checked={deletePackagesList.length === packages.length}
        disabled={!(shipment && shipment.completeStatus === CompleteStatus.Incomplete)}
        onChange={({ checked }): void => {
          setDeletePackagesList(checked ? packages.map(item => +item.id!) : [])
        }}
        size='m'
      />,
      sortable: false,
      renderCell: ({ id }: PackageRow) => (
        <Checkbox
          checked={deletePackagesList.includes(+id!)}
          disabled={!(shipment && shipment.completeStatus === CompleteStatus.Incomplete)}
          onChange={({ checked }): void => {
            setDeletePackagesList(checked
              ? [...deletePackagesList, +id!]
              : deletePackagesList.filter(item => item !== +id!),
            )
          }}
          size='s'
        />
      ),
      width: 50,
    },
    {
      title: 'ТРЕК-НОМЕР',
      accessor: 'track',
      sortable: false,
      width: 350,
    },
    {
      title: 'НОМЕР КОРОБКИ',
      accessor: 'boxNumber',
      sortable: false,
    },
    {
      title: 'ВЕС (КГ)',
      accessor: 'weight',
      sortable: true,
    },
    {
      title: 'ДЛИНА (Д)',
      accessor: 'length',
      sortable: false,
    },
    {
      title: 'ШИРИНА (Д)',
      accessor: 'width',
      sortable: false,
    },
    {
      title: 'ВЫСОТА (Д)',
      accessor: 'height',
      sortable: false,
    },
    {
      title: 'КОЛИЧЕСТВО МЕСТ',
      accessor: 'packageCount',
      sortable: true,
    },
  ];

  useEffect(() => {
    setOffset(0);
    setDeletePackagesList([]);
  }, [admin, shipmentId]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedQ(q);
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [q]);

  const navigate = useNavigate();

  const [updateShipment] = useUpdateShipmentMutation();
  const [createShipment] = useCreateShipmentMutation();

  const exportExcel = async () => {
    const token = getAuthToken();
    if (shipmentId && token) {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/shipments/${shipmentId}/export`, {
        method: 'GET',
        headers: {
          Authorization: token,
        },
      });
      const blob = await response.blob();
      FileSaver.saveAs(blob, `${shipment!.name}.xlsx`);
    }
  };

  const [isConfirmRemoveShipmentModalOpen, setIsConfirmRemoveShipmentModalOpen] = useState(false);
  const [isConfirmFinishShipmentModalOpen, setIsConfirmFinishShipmentModalOpen] = useState(false);
  const [isConfirmRemovePackagesModalOpen, setIsConfirmRemovePackagesModalOpen] = useState(false);
  const [isCreateShipmentModalOpen, setIsCreateShipmentModalOpen] = useState(false);

  const [shipmentName, setShipmentName] = useState('');
  
  const [confirmModalData, setConfirmModalData] = useState({
    title: '',
    body: '',
    submitTitle: '',
  });

  const onCloseConfirmModal = () => {
    setIsConfirmRemoveShipmentModalOpen(false);
    setIsConfirmFinishShipmentModalOpen(false);
    setIsConfirmRemovePackagesModalOpen(false);
    setConfirmModalData({
      title: '',
      body: '',
      submitTitle: '',
    });
  };

  const [deletePackage] = useDeletePackageMutation();
  const [deleteShipment] = useDeleteShipmentMutation();

  const onSubmitConfirmModal = async () => {
    if (isConfirmRemovePackagesModalOpen) {
      const promises = deletePackagesList.map((id) =>
        deletePackage({
          variables: {
            id,
          },
        }),
      );
  
      await Promise.all(promises).then(() => {
        refetch();
        setDeletePackagesList([]);
        onCloseConfirmModal();
        setConfirmModalData({
          title: '',
          body: '',
          submitTitle: '',
        });
      });
    } else if (isConfirmRemoveShipmentModalOpen) {
      if (shipmentId) {
        deleteShipment({
          variables: {
            id: shipmentId,
          },
        }).then(() => {
          refetchShipments();
          onCloseConfirmModal();
          setConfirmModalData({
            title: '',
            body: '',
            submitTitle: '',
          });
        });
      }
    } else if (isConfirmFinishShipmentModalOpen) {
      if (shipmentId) {
        updateShipment({
          variables: {
            id: shipmentId,
            input: {
              isCompleted: true,
            },
          },
        }).then(() => {
          refetchShipments();
          onCloseConfirmModal();
          setConfirmModalData({
            title: '',
            body: '',
            submitTitle: '',
          });
        });
      }
    }
  };

  const onCreateShipment = () => {
    if (admin?.stockId) {
      createShipment({
        variables: {
          input: {
            stockId: admin?.stockId,
          },
        },
      }).then((res) => {
        if (res.data?.createShipment.id) {
          updateShipment({
            variables: {
              id: res.data.createShipment.id,
              input: {
                name: shipmentName,
              },
            },
          }).then(() => {
            refetchShipments();
            setShipmentName('');
            setIsCreateShipmentModalOpen(false);
          });
        }
      });
    }
  };

  const onRemovePackages = () => {
    setConfirmModalData({
      title: 'Вы действительно хотите удалить выбранные посылки?',
      body: '',
      submitTitle: 'Да',
    });
    setIsConfirmRemovePackagesModalOpen(true);
  };

  const onRemoveShipment = () => {
    setConfirmModalData({
      title: 'Вы действительно хотите удалить партию?',
      body: '',
      submitTitle: 'Да',
    });
    setIsConfirmRemoveShipmentModalOpen(true);
  };

  const onFinishShipment = () => {
    setConfirmModalData({
      title: 'Вы действительно хотите завершить обработку партии?',
      body: 'После этого посылки партии станут недоступны для редактирования',
      submitTitle: 'Да',
    });
    setIsConfirmFinishShipmentModalOpen(true);
  };

  const isMobile = window.innerWidth < 640;
  const isTablet = window.innerWidth < 1100 && window.innerWidth >= 640;
  const smallScreen = isMobile || isTablet;

  return (
    <>
      <Margin mb={32}>
        <Row
          direction='row'
          justify='space-between'
          align='center'
        >
          <Text size={smallScreen ? isMobile ? '2xl' : '3xl' : '4xl'} view='primary' weight='medium'>
            Посылки
          </Text>
          <Row justify='flex-end' align='center'>
            <Button
              size='s'
              view='primary'
              form='round'
              className='buttonBlack'
              label='Создать партию'
              onClick={() => setIsCreateShipmentModalOpen(true)}
            />
            {!smallScreen && <Margin ml={10} mr={10} style={{ width: 'unset' }}>
              <Button
                size='s'
                view='secondary'
                form='round'
                label='Экпортировать в Excel'
                onClick={exportExcel}
              />
            </Margin>}
            {!smallScreen && <TextField
              type='text'
              placeholder='Трек-номер посылки'
              form='round'
              width='full'
              style={{width: '270px'}}
              size='s'
              value={q}
              leftSide={IconSearch}
              onChange={({ value }) => setQ(value || undefined)}
            />}
          </Row>
        </Row>
      </Margin>
      {shipmentsTabs.length && <Margin mb={30}>
        <Tabs
          value={shipmentsTabs.find(item => item.value === shipmentId)}
          onChange={({ value }) => setShipmentId(value.value)}
          items={shipmentsTabs}
          size='m'
          fitMode='scroll'
        />
      </Margin> || null}
      <Margin mb={40}>
        <Row
          direction={smallScreen ? 'column-reverse' : 'row'}
          justify={smallScreen ? 'flex-start' : 'space-between'}
          align='flex-start'
        >
          {stats && <InfoField
            label='Количество посылок в партии'
            value={`${stats.shipmentStats.reduce((acc, item) => acc + item.count, 0)}`}
          /> || null}
          {shipment && shipment.completeStatus === CompleteStatus.Incomplete && (
            <Margin mb={smallScreen ? 30 : 0}>
              <Row
                direction={isMobile ? 'column-reverse' : 'row'}
                justify={smallScreen ? 'flex-start' : 'flex-end'}
                align={isMobile ? 'flex-start' : 'center'}
                style={{flexWrap: smallScreen ? 'wrap' : undefined}}
              >
                <Button
                  label='Перейти в режим сканирования'
                  size='s'
                  view='primary'
                  form='round'
                  className='buttonBlack'
                  style={{
                    width: smallScreen ? isMobile ? '100%' : 'calc(50% - 12px)' : 'unset',
                    marginBottom: isTablet ? '10px' : '0',
                  }}
                  onClick={() => shipmentId && navigate(`/stock-keeper-scanner/${shipmentId}`)}
                />
                <Margin
                  ml={smallScreen ? isMobile ? 0 : 24 : 10}
                  mr={smallScreen ? 0 : 10}
                  mb={smallScreen ? 10 : 0}
                  style={{ width: smallScreen ? isMobile ? '100%' : 'calc(50% - 12px)' : 'unset' }}
                >
                  <Button
                    label='Завершить обработку партии'
                    size='s'
                    view='secondary'
                    form='round'
                    width={smallScreen ? 'full' : undefined}
                    className='buttonSuccess'
                    onClick={onFinishShipment}
                  />
                </Margin>
                {smallScreen && <TextField
                  type='text'
                  placeholder='Трек-номер посылки'
                  form='round'
                  width='full'
                  style={{
                    width: isMobile ? '100%' : 'calc(50% - 12px)',
                    marginRight: isMobile ? '0' : '24px',
                    marginBottom: smallScreen ? '10px' : '0',
                  }}
                  size='s'
                  value={q}
                  leftSide={IconSearch}
                  onChange={({ value }) => setQ(value || undefined)}
                />}
                <RemoveButtonContainer style={{
                  width: smallScreen ? isMobile ? '100%' : 'calc(50% - 12px)' : 'unset',
                  justifyContent: 'flex-end',
                  marginBottom: isMobile ? '10px' : '0',
                }}>
                  <Button
                    label='Удалить партию'
                    size='s'
                    view='clear'
                    onClick={onRemoveShipment}
                  />
                </RemoveButtonContainer>
              </Row>
            </Margin>
          )}
        </Row>
      </Margin>
      {packages?.length ? <Margin mb={deletePackagesList.length ? 115 : 0}>
        {isMobile ? <>
          <Margin mb={15}>
              <Button
              view='primary'
              size='s'
              label={deletePackagesList.length === packages.length
                ? 'Снять выделение со всех посылок'
                : 'Выделить все посылки'
              }
              className='buttonBlack'
              disabled={!(shipment && shipment.completeStatus === CompleteStatus.Incomplete)}
              onClick={() => {
                setDeletePackagesList(deletePackagesList.length === packages.length ? [] : packages.map(item => +item.id!))
              }}
            />
          </Margin>
          {packages.map(item => <MobileStockItem
            key={item.id}
            data={item}
            onClick={(id: string, e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
              // @ts-ignore
              if (shipment?.completeStatus === CompleteStatus.Incomplete && !e.target.value) {
                const packageId = packages.find(el => el.id === id)?.track || '';
                navigate(`/stock-keeper-scanner/${shipmentId}?track=${packageId}`);
              }
            }}
            isChecked={deletePackagesList.includes(+item.id!)}
            isDisabled={!(shipment && shipment.completeStatus === CompleteStatus.Incomplete)}
            onChangeCheckbox={(checked: boolean) => {
              setDeletePackagesList(checked
                ? [...deletePackagesList, +item.id!]
                : deletePackagesList.filter(el => el !== +item.id!),
              )
            }}
          />)}
        </> : <TableContainer>
          <Table
            rows={packages}
            // @ts-ignore
            columns={columns}
            defaultExpandAll={false}
            onRowClick={({ id, e }) => {
              // @ts-ignore
              if (shipment?.completeStatus === CompleteStatus.Incomplete && !e.target.value) {
                const packageId = packages.find(item => item.id === id)?.track || '';
                navigate(`/stock-keeper-scanner/${shipmentId}?track=${packageId}`);
              }
            }}
          />
        </TableContainer>}
      </Margin> : <EmptyContainer>
        <Card shadow={false}>
          <Text size='3xl' weight='medium' align='center'>
            Не найдено ни одной посылки
          </Text>
        </Card>
      </EmptyContainer>}
      {(deletePackagesList.length && (
        <BottomCounterContainer>
          <Text size='s' align='center'>
            Количество выбранных посылок: {deletePackagesList.length}
          </Text>
          <Button
            view='primary'
            size='s'
            label='Удалить выбранные'
            onClick={onRemovePackages}
          />
        </BottomCounterContainer>
      )) || null}
      <ConfirmModal
        isModalOpen={
          isConfirmFinishShipmentModalOpen ||
          isConfirmRemovePackagesModalOpen ||
          isConfirmRemoveShipmentModalOpen
        }
        title={confirmModalData.title}
        body={confirmModalData.body}
        submitTitle={confirmModalData.submitTitle}
        onClose={onCloseConfirmModal}
        onSubmit={onSubmitConfirmModal}
        closeButtonTitle='Отмена'
        customMaxWidth={600}
      />
      <Modal
        isOpen={isCreateShipmentModalOpen}
        onClickOutside={() => setIsCreateShipmentModalOpen(false)}
        onEsc={() => setIsCreateShipmentModalOpen(false)}
        style={{ width: isMobile ? '90%' : undefined }}
      >
        <ConfirmModalWrapper>
          <ConfirmModalHeaderWrapper>
            <Text size={isMobile ? 'xl' : '3xl'} view='primary' weight='medium'>
              Создать новую партию
            </Text>
          </ConfirmModalHeaderWrapper>
          <BodyWrapper>
            <TextField
              type='text'
              placeholder='Партия №1'
              form='round'
              width='full'
              size='m'
              value={shipmentName}
              onChange={({ value }) => setShipmentName(value || '')}
            />
          </BodyWrapper>
          <ActionsWrapper>
            <Button
              size='l'
              view='primary'
              form='round'
              width='default'
              className='buttonBlack'
              label='Создать партию'
              onClick={onCreateShipment}
              style={{ width: 'fit-content' }}
            />
          </ActionsWrapper>
        </ConfirmModalWrapper>
      </Modal>
    </>
  );
};

export default StockKeeperPackages;