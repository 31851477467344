import styled from 'styled-components';

export const RemoveButtonContainer = styled.div`
  .Button {
    .Button-Label {
      color: var(--color-typo-alert) !important;
    }

    .Button-Icon .Icon-Svg {
      fill: var(--color-typo-alert) !important;
    }
  }
`;