import styled, { keyframes } from "styled-components";

const xTransform = keyframes`
  to {
    transform: translateX(-100%);
    left: 100%;
  }
`;

const yTransform = keyframes`
  33% {
     clip-path: inset(0 0 0 -100px);    
   }
   50% {
     clip-path: inset(0 0 0 0);    
   }
   83%{
     clip-path: inset(0 -100px 0 0); 
   }
`;

export const ScannerBox = styled.div<{ success: boolean }>`
  width: 100%;
  height: 100%;
  position: relative;

  &::after {
    content: "";
    visibility: ${props => props.success ? 'hidden' : 'inherit'};
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 10px;
    background: var(--color-typo-success);
    box-shadow: 0 0 70px 20px var(--color-typo-success);
    clip-path: inset(0);
    animation: 
      ${xTransform} 1s ease-in-out infinite alternate,
      ${yTransform} 2s   ease-in-out infinite;
  }
`;