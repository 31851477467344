import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SendConfirmationCodeMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type SendConfirmationCodeMutation = { __typename?: 'Mutation', sendConfirmationCode: boolean };


export const SendConfirmationCodeDocument = gql`
    mutation sendConfirmationCode {
  sendConfirmationCode
}
    `;
export type SendConfirmationCodeMutationFn = Apollo.MutationFunction<SendConfirmationCodeMutation, SendConfirmationCodeMutationVariables>;

/**
 * __useSendConfirmationCodeMutation__
 *
 * To run a mutation, you first call `useSendConfirmationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendConfirmationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendConfirmationCodeMutation, { data, loading, error }] = useSendConfirmationCodeMutation({
 *   variables: {
 *   },
 * });
 */
export function useSendConfirmationCodeMutation(baseOptions?: Apollo.MutationHookOptions<SendConfirmationCodeMutation, SendConfirmationCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendConfirmationCodeMutation, SendConfirmationCodeMutationVariables>(SendConfirmationCodeDocument, options);
      }
export type SendConfirmationCodeMutationHookResult = ReturnType<typeof useSendConfirmationCodeMutation>;
export type SendConfirmationCodeMutationResult = Apollo.MutationResult<SendConfirmationCodeMutation>;
export type SendConfirmationCodeMutationOptions = Apollo.BaseMutationOptions<SendConfirmationCodeMutation, SendConfirmationCodeMutationVariables>;