import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type StocksListQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type StocksListQuery = { __typename?: 'Query', stocksList: Array<{ __typename?: 'StockType', id: number, code: Types.StockCode, name: string, address: { __typename?: 'StockAddressType', suite?: string | null, addressFirst: string, addressSecond?: string | null, city: string, state?: string | null, zipCode: string, country: string, phone?: string | null } }> };


export const StocksListDocument = gql`
    query stocksList {
  stocksList {
    id
    code
    name
    address {
      suite
      addressFirst
      addressSecond
      city
      state
      zipCode
      country
      phone
    }
  }
}
    `;

/**
 * __useStocksListQuery__
 *
 * To run a query within a React component, call `useStocksListQuery` and pass it any options that fit your needs.
 * When your component renders, `useStocksListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStocksListQuery({
 *   variables: {
 *   },
 * });
 */
export function useStocksListQuery(baseOptions?: Apollo.QueryHookOptions<StocksListQuery, StocksListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StocksListQuery, StocksListQueryVariables>(StocksListDocument, options);
      }
export function useStocksListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StocksListQuery, StocksListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StocksListQuery, StocksListQueryVariables>(StocksListDocument, options);
        }
export type StocksListQueryHookResult = ReturnType<typeof useStocksListQuery>;
export type StocksListLazyQueryHookResult = ReturnType<typeof useStocksListLazyQuery>;
export type StocksListQueryResult = Apollo.QueryResult<StocksListQuery, StocksListQueryVariables>;