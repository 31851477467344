import styled from 'styled-components';

export const UsersHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  .TextField {
    width: 30%;
  }
`;

export const TableContainer = styled.div`
  .Table {
    &::-webkit-scrollbar {
      height: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--color-control-bg-border-default);
      border-radius: 20px;
      height: 6px;
    }

    .Table-CellsRow {
      .TableCell {
        cursor: pointer;
        background: var(--color-bg-secondary);

        .Badge_status_system {
          color: var(--color-typo-primary);
        }

        .Badge_status_normal {
          color: var(--color-bg-secondary);
        }
      }
    }

    .TableHeader-Row {
      .TableHeader-Cell {
        overflow: hidden;
        border-bottom: none;

        &:first-child {
          border-radius: 30px 0 0 30px;
        }

        &:last-child {
          border-radius: 0 30px 30px 0;
        }
      }

      &:hover {
        .TableHeader-Cell {
          &::after {
            display: none !important;
          }
        }
      }
    }

    .Table-RowWithoutCells {
      background: var(--color-bg-secondary);

      & > div {
        border-bottom: none;
      }
    }
  }
`;
