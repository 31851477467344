import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreatePackagesMutationVariables = Types.Exact<{
  input: Array<Types.CreatePackageInput> | Types.CreatePackageInput;
}>;


export type CreatePackagesMutation = { __typename?: 'Mutation', createPackages: number };


export const CreatePackagesDocument = gql`
    mutation createPackages($input: [CreatePackageInput!]!) {
  createPackages(input: $input)
}
    `;
export type CreatePackagesMutationFn = Apollo.MutationFunction<CreatePackagesMutation, CreatePackagesMutationVariables>;

/**
 * __useCreatePackagesMutation__
 *
 * To run a mutation, you first call `useCreatePackagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePackagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPackagesMutation, { data, loading, error }] = useCreatePackagesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePackagesMutation(baseOptions?: Apollo.MutationHookOptions<CreatePackagesMutation, CreatePackagesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePackagesMutation, CreatePackagesMutationVariables>(CreatePackagesDocument, options);
      }
export type CreatePackagesMutationHookResult = ReturnType<typeof useCreatePackagesMutation>;
export type CreatePackagesMutationResult = Apollo.MutationResult<CreatePackagesMutation>;
export type CreatePackagesMutationOptions = Apollo.BaseMutationOptions<CreatePackagesMutation, CreatePackagesMutationVariables>;