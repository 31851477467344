import { Badge } from '@consta/uikit/Badge';
import { Checkbox } from '@consta/uikit/Checkbox';
import { Text } from '@consta/uikit/Text';
import {
  statusesBadges,
  statusesLabels,
} from 'shared/constants/statuses.constants';
import { PackageData, PackageRow } from '../../entities/package-row/types';

export const getPackageColumns = (isKorea: boolean) => {
  return [
    {
      title: isKorea ? 'Артикул' : 'ТРЕК-НОМЕР',
      accessor: 'track',
      sortable: false,
      width: 300,
    },
    {
      title: 'НАЗВАНИЕ ПАРТИИ',
      accessor: 'shipmentName',
      sortable: false,
      width: 250,
    },
    {
      title: 'ФИО ПОЛУЧАТЕЛЯ',
      accessor: 'userName',
      sortable: false,
      width: 350,
    },
    {
      title: 'СТАТУС',
      accessor: 'status',
      sortable: false,
      renderCell: ({ status }: PackageRow) => (
        <Badge status={statusesBadges[status]} label={statusesLabels[status]} />
      ),
      width: 300,
    },
    {
      title: isKorea ? 'Описание груза' : 'ОПИСАНИЕ ПОСЫЛКИ',
      accessor: 'packageDescription',
      sortable: false,
      width: 350,
    },
    {
      title: 'ВЕС КГ',
      accessor: 'weight',
      sortable: true,
      width: 150,
    },
    {
      title: 'ТАРИФ $',
      accessor: 'rateCost',
      sortable: true,
      width: 200,
    },
    ...(isKorea
      ? [
          {
            title: 'КОЛИЧЕСТВО МЕСТ',
            accessor: 'packageCount',
            sortable: true,
            width: 200,
          },
        ]
      : []),
    ...(!isKorea
      ? [
          {
            title: 'СТРАХОВКА $',
            accessor: 'insuranceCost',
            sortable: true,
            width: 150,
          },
        ]
      : []),
    {
      title: 'СТОИМОСТЬ ДОСТАВКИ $',
      accessor: 'deliveryCost',
      sortable: true,
      width: 250,
    },
    {
      title: 'ОПЛАЧЕНО',
      accessor: 'isPaid',
      sortable: false,
      width: 150,
      renderCell: ({ isPaid }: PackageRow) => (
        <Checkbox
          checked={isPaid || false}
          align='center'
          view='primary'
          disabled
        />
      ),
    },
    {
      title: 'КОММЕНТАРИЙ СКЛАДА',
      accessor: 'comment',
      sortable: false,
      width: 350,
      renderCell: ({ isDamaged, comment }: PackageRow) => (
        <Text size='m' view={isDamaged ? 'alert' : 'primary'}>
          {comment}
        </Text>
      ),
    },
    {
      title: 'АДРЕС ПОЛУЧЕНИЯ',
      accessor: 'deliveryAddress',
      sortable: false,
      width: 350,
    },
    {
      title: 'ID ПОЛУЧАТЕЛЯ',
      accessor: 'userId',
      sortable: false,
      width: 250,
    },
    {
      title: 'ДАТА ДОБАВЛЕНИЯ',
      accessor: 'createdAt',
      sortable: false,
      width: 200,
    },
  ];
};

export const getInfoRows = (
  packageData: PackageData | null,
  isKorea: boolean,
) => {
  return [
    {
      title: 'НАЗВАНИЕ ПАРТИИ',
      value: packageData?.shipmentName || '',
    },
    {
      title: isKorea ? 'Артикул' : 'ТРЕК-НОМЕР',
      value: packageData?.track || '',
    },
    {
      title: 'ID ПОЛУЧАТЕЛЯ',
      value: packageData?.userId || '',
    },
    {
      title: 'ФИО ПОЛУЧАТЕЛЯ',
      value: packageData?.userName || '',
    },
    {
      title: 'АДРЕС ПОЛУЧАТЕЛЯ',
      value: packageData?.deliveryAddress || packageData?.pickupPoint || '',
    },
    {
      title: 'ОПИСАНИЕ ПОСЫЛКИ',
      value: packageData?.packageDescription || '',
    },
    {
      title: 'ДАТА ДОБАВЛЕНИЯ',
      value: packageData?.createdAt || '',
    },
  ];
};

export type ShipmentPackageDataType = {
  id: string;
  track?: string | null;
  weight?: number | null;
  length?: number | null;
  width?: number | null;
  height?: number | null;
  packageCount?: number | null;
};

export const getShipmentPackageInfoRows = ({
  packageData,
}: {
  packageData: ShipmentPackageDataType | null;
}) => {
  return [
    {
      title: 'ТРЕК-НОМЕР',
      value: packageData?.track || '',
    },
  ];
};
