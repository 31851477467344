import { DatePicker, DatePickerPropValue } from '@consta/uikit/DatePicker';
import { IconCalendar } from '@consta/uikit/IconCalendar';
import { DatePickerWrapper } from './styled';

export const DateRangePicker = ({
  range,
  setValue,
}: {
  range: [Date?, Date?] | null;
  setValue: (dates: DatePickerPropValue<'date-range'>) => void;
}) => {
  return (
    <DatePickerWrapper>
      <DatePicker
        type='date-range'
        size='xs'
        form='brick'
        rightSide={[IconCalendar, IconCalendar]}
        value={range}
        onChange={(dates) => setValue(dates.value)}
      />
    </DatePickerWrapper>
  );
};
