import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdminCreatePackageMutationVariables = Types.Exact<{
  input: Types.AdminCreatePackageInput;
}>;


export type AdminCreatePackageMutation = { __typename?: 'Mutation', adminCreatePackage: { __typename?: 'PackageType', id: number } };


export const AdminCreatePackageDocument = gql`
    mutation adminCreatePackage($input: AdminCreatePackageInput!) {
  adminCreatePackage(input: $input) {
    id
  }
}
    `;
export type AdminCreatePackageMutationFn = Apollo.MutationFunction<AdminCreatePackageMutation, AdminCreatePackageMutationVariables>;

/**
 * __useAdminCreatePackageMutation__
 *
 * To run a mutation, you first call `useAdminCreatePackageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminCreatePackageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminCreatePackageMutation, { data, loading, error }] = useAdminCreatePackageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminCreatePackageMutation(baseOptions?: Apollo.MutationHookOptions<AdminCreatePackageMutation, AdminCreatePackageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminCreatePackageMutation, AdminCreatePackageMutationVariables>(AdminCreatePackageDocument, options);
      }
export type AdminCreatePackageMutationHookResult = ReturnType<typeof useAdminCreatePackageMutation>;
export type AdminCreatePackageMutationResult = Apollo.MutationResult<AdminCreatePackageMutation>;
export type AdminCreatePackageMutationOptions = Apollo.BaseMutationOptions<AdminCreatePackageMutation, AdminCreatePackageMutationVariables>;