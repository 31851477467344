import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateBuyoutMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  input: Types.UpdateBuyoutInput;
}>;


export type UpdateBuyoutMutation = { __typename?: 'Mutation', updateBuyout: { __typename?: 'BuyoutType', id: number } };


export const UpdateBuyoutDocument = gql`
    mutation updateBuyout($id: Int!, $input: UpdateBuyoutInput!) {
  updateBuyout(id: $id, input: $input) {
    id
  }
}
    `;
export type UpdateBuyoutMutationFn = Apollo.MutationFunction<UpdateBuyoutMutation, UpdateBuyoutMutationVariables>;

/**
 * __useUpdateBuyoutMutation__
 *
 * To run a mutation, you first call `useUpdateBuyoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBuyoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBuyoutMutation, { data, loading, error }] = useUpdateBuyoutMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBuyoutMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBuyoutMutation, UpdateBuyoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBuyoutMutation, UpdateBuyoutMutationVariables>(UpdateBuyoutDocument, options);
      }
export type UpdateBuyoutMutationHookResult = ReturnType<typeof useUpdateBuyoutMutation>;
export type UpdateBuyoutMutationResult = Apollo.MutationResult<UpdateBuyoutMutation>;
export type UpdateBuyoutMutationOptions = Apollo.BaseMutationOptions<UpdateBuyoutMutation, UpdateBuyoutMutationVariables>;