import React, { useEffect, useState } from 'react';
import { Card } from '@consta/uikit/Card';
import { Grid, GridItem } from '@consta/uikit/Grid';
import { TextField } from '@consta/uikit/TextField';
import { IconWarning } from '@consta/uikit/IconWarning';
import { CitizenshipItem } from 'entities/countries/types';
import { countries, govIdFieldProperties } from 'entities/countries/utils';
import { UserCountry, UserType } from 'types';
import PhoneInput from 'features/phone-input';
import { getPhoneAndPrefix } from 'entities/phone/utils';
import ProfileActions from 'features/profile/profile-actions';
import { emailRegex } from 'shared/constants';
import { ProfileActionsWrapper, ProfileFormWrapper } from './styled';

interface ProfileFormProps {
  user: UserType;
  formDisabled: boolean;
  onReset: () => void;
  onSubmit: ({
    name,
    nameEn,
    phone,
    email,
    govId,
  }: {
    name: string;
    nameEn: string;
    phone: string;
    email: string;
    govId: string;
  }) => void;
}

const [defaultCountry] = countries;

const ProfileForm = ({
  user,
  formDisabled,
  onReset,
  onSubmit,
}: ProfileFormProps) => {
  const citizenship = countries.find((c) => c.value === user?.country);
  const isEmailConfirmed = user?.isEmailConfirmed;
  const phoneLabel = 'Номер телефона';

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [nameEn, setNameEn] = useState('');
  const [surnameEn, setSurnameEn] = useState('');
  const [phone, setPhone] = useState('');
  const [phonePrefixObj, setPhonePrefix] =
    useState<CitizenshipItem>(defaultCountry);
  const [govId, setGovId] = useState('');

  const [emailError, setEmailError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [surnameError, setSurnameError] = useState(false);
  const [nameEnError, setNameEnError] = useState(false);
  const [surnameEnError, setSurnameEnError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [govIdError, setGovIdError] = useState(false);

  const setInitialUserData = () => {
    const [nameData = '', surnameData = ''] = user?.name?.split(' ') || [];
    const [nameEnData = '', surnameEnData = ''] =
      user?.nameEn?.split(' ') || [];
    const { phone: phoneData, prefix: phonePrefixData } = getPhoneAndPrefix(
      user?.phone || '',
    );
    const prefixObj = countries.find((c) => c.phonePrefix === phonePrefixData);

    setEmail(user?.email || '');
    setName(nameData);
    setSurname(surnameData);
    setNameEn(nameEnData);
    setSurnameEn(surnameEnData);
    setPhone(phoneData);
    setPhonePrefix(prefixObj || defaultCountry);
    setGovId(user?.govId?.replace(/\s/g, '') || '');
  };

  const resetFormFields = () => {
    setNameError(false);
    setNameEnError(false);
    setSurnameError(false);
    setSurnameEnError(false);
    setEmailError(false);
    setPhoneError(false);
    setGovIdError(false);
  };

  useEffect(() => {
    if (user?.id) {
      setInitialUserData();
    }
  }, [user]);

  const isMobile = window.innerWidth < 640;
  const isTablet = window.innerWidth < 1100 && window.innerWidth >= 640;
  const smallScreen = isMobile || isTablet;

  const handleEmailChange = (value: string) => {
    setEmail(value);
    setEmailError(false);
  };

  const handleNameChange = (value: string) => {
    const cleanValue = value.replace(/[^а-яё]/i, '');
    setName(cleanValue);
    setNameError(false);
  };

  const handleNameEnChange = (value: string) => {
    const cleanValue = value.replace(/[^A-Za-z]/gi, '');
    setNameEn(cleanValue);
    setNameEnError(false);
  };

  const handleSurnameChange = (value: string) => {
    const cleanValue = value.replace(/[^а-яё]/i, '');
    setSurname(cleanValue);
    setSurnameError(false);
  };

  const handleSurnameEnChange = (value: string) => {
    const cleanValue = value.replace(/[^A-Za-z]/gi, '');
    setSurnameEn(cleanValue);
    setSurnameEnError(false);
  };

  const handlePhoneChange = (value: string) => {
    if (value && value.length > phoneMaxLength) return;

    setPhone(value);
    setPhoneError(false);
  };

  const handlePhonePrefixChange = (item: CitizenshipItem | null) => {
    if (!item?.phonePrefix) return;

    setPhonePrefix(item);
  };

  const handleGovIdChange = (value: string) => {
    if (value && value.length > govIdMaxLength) return;

    setGovId(value);
    setGovIdError(false);
  };

  const handleEmailBlur = () => {
    if (!emailRegex.test(email)) {
      setEmailError(true);
    }
  };

  const handleNameBlur = () => {
    if (!name) {
      setNameError(true);
    }
  };

  const handleNameEnBlur = () => {
    if (!nameEn) {
      setNameEnError(true);
    }
  };

  const handleSurnameBlur = () => {
    if (!surname) {
      setSurnameError(true);
    }
  };

  const handleSurnameEnBlur = () => {
    if (!surnameEn) {
      setSurnameEnError(true);
    }
  };

  const handlePhoneBlur = () => {
    if (!phone) {
      setPhoneError(true);
    }
  };

  const handleGovIdBlur = () => {
    if (!govId || govId?.length !== govIdMaxLength) {
      setGovIdError(true);
    }
  };

  const emailCaption = () => {
    if (emailError) {
      return 'Неверный email';
    }

    if (!isEmailConfirmed) {
      return 'Подтвердите email';
    }

    return '';
  };

  const emailStatus = () => {
    if (emailError) {
      return 'alert';
    }

    if (!isEmailConfirmed) {
      return 'warning';
    }

    return undefined;
  };

  const { govIdTitle, govIdPlaceholder, govIdMaxLength } = govIdFieldProperties(
    citizenship || defaultCountry,
  );

  const phoneMaxLength =
    phonePrefixObj?.countryCode === UserCountry.Kg ? 9 : 10; // Kyrgyzstan 12 цифр в номере телефона (3 из них в коде)

  const formInvalid =
    !name ||
    !surname ||
    !nameEn ||
    !surnameEn ||
    !phone ||
    !citizenship ||
    !govId ||
    emailError ||
    govIdError ||
    phoneError ||
    govId?.length !== govIdMaxLength;

  const onSubmitForm = async () => {
    if (!formInvalid) {
      onSubmit({
        name: `${name} ${surname}`,
        nameEn: `${nameEn} ${surnameEn}`,
        phone: `${phonePrefixObj.phonePrefix}${phone}`,
        email,
        govId,
      });
    }
  };

  const resetForm = () => {
    setInitialUserData();
    resetFormFields();
    onReset();
  };

  return (
    <ProfileFormWrapper>
      <Card>
        <Grid
          cols={1}
          rowGap='m'
          colGap='l'
          breakpoints={{
            m: {
              cols: 3,
              colGap: 'xl',
            },
            s: {
              cols: 2,
              colGap: 'xl',
            },
          }}
        >
          <GridItem order={smallScreen ? -1 : 0}>
            <TextField
              label='Имя на кириллице'
              type='text'
              placeholder='Иван'
              form='round'
              width='full'
              disabled={formDisabled}
              size={smallScreen ? 's' : 'm'}
              value={name}
              onChange={({ value }) => handleNameChange(value || '')}
              onBlur={handleNameBlur}
              status={nameError ? 'alert' : undefined}
              caption={nameError ? 'Поле не заполнено' : undefined}
            />
          </GridItem>
          <GridItem order={isTablet ? -1 : 0}>
            <TextField
              label='Имя латинскими буквами'
              type='text'
              placeholder='ivan'
              form='round'
              width='full'
              disabled={formDisabled}
              size={smallScreen ? 's' : 'm'}
              value={nameEn}
              onChange={({ value }) => handleNameEnChange(value || '')}
              onBlur={handleNameEnBlur}
              status={nameEnError ? 'alert' : undefined}
              caption={nameEnError ? 'Поле не заполнено' : undefined}
            />
          </GridItem>
          <GridItem order={isMobile ? 1 : 0}>
            <TextField
              label='Ваш email'
              type='email'
              placeholder='ivonov@gmail.com'
              form='round'
              width='full'
              disabled={formDisabled}
              size={smallScreen ? 's' : 'm'}
              value={email}
              onChange={({ value }) => handleEmailChange(value || '')}
              onBlur={handleEmailBlur}
              status={emailStatus()}
              caption={emailCaption()}
              rightSide={isEmailConfirmed ? undefined : IconWarning}
            />
          </GridItem>
          <GridItem order={smallScreen ? -1 : 0}>
            <TextField
              label='Фамилия на кириллице'
              type='text'
              placeholder='Иванов'
              form='round'
              width='full'
              disabled={formDisabled}
              size={smallScreen ? 's' : 'm'}
              value={surname}
              onChange={({ value }) => handleSurnameChange(value || '')}
              onBlur={handleSurnameBlur}
              status={surnameError ? 'alert' : undefined}
              caption={surnameError ? 'Поле не заполнено' : undefined}
            />
          </GridItem>
          <GridItem order={isTablet ? -1 : 0}>
            <TextField
              label='Фамилия латинскими буквами'
              type='text'
              placeholder='ivanov'
              form='round'
              width='full'
              disabled={formDisabled}
              size={smallScreen ? 's' : 'm'}
              value={surnameEn}
              onChange={({ value }) => handleSurnameEnChange(value || '')}
              onBlur={handleSurnameEnBlur}
              status={surnameEnError ? 'alert' : undefined}
              caption={surnameEnError ? 'Поле не заполнено' : undefined}
            />
          </GridItem>
          <GridItem order={isMobile ? 1 : 0}>
            <PhoneInput
              label={phoneLabel}
              smallScreen={smallScreen}
              countries={countries}
              phonePrefix={phonePrefixObj}
              phone={phone}
              phoneError={phoneError}
              disabled={formDisabled}
              withDisabledPlaceholder
              handlePhonePrefixChange={handlePhonePrefixChange}
              handlePhoneChange={handlePhoneChange}
              handlePhoneBlur={handlePhoneBlur}
            />
          </GridItem>
          <GridItem order={smallScreen ? 1 : 0}>
            <TextField
              label={govIdTitle}
              type='number'
              placeholder={govIdPlaceholder}
              form='round'
              width='full'
              disabled={formDisabled}
              size={smallScreen ? 's' : 'm'}
              value={govId}
              incrementButtons={false}
              onChange={({ value }) => handleGovIdChange(value || '')}
              onBlur={handleGovIdBlur}
              status={govIdError ? 'alert' : undefined}
              caption={govIdError ? 'Поле не заполнено' : undefined}
            />
          </GridItem>
          {!isMobile && <GridItem order={isTablet ? 1 : 0} />}
          <GridItem order={1} col={isTablet ? 3 : 1}>
            <ProfileActionsWrapper>
              {formDisabled ? null : (
                <ProfileActions onReset={resetForm} onsubmit={onSubmitForm} />
              )}
            </ProfileActionsWrapper>
          </GridItem>
        </Grid>
      </Card>
    </ProfileFormWrapper>
  );
};

export default ProfileForm;
