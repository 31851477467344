import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetPackageQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type GetPackageQuery = { __typename?: 'Query', getPackage: { __typename?: 'PackageType', id: number, track: string, status: Types.DeliveryStatus, cargoType: Types.CargoType, storeStatus: Types.StoreStatus, description?: string | null, weight?: number | null, createdAt: any, packageCost?: number | null, packageCount?: number | null, insuranceCost?: number | null, isPaid: boolean, isDamaged: boolean, rateCost: number, deliveryRate: { __typename?: 'DeliveryRateType', id: number, name: string, cost: number, time: string, description?: string | null, stock: { __typename?: 'StockType', id: number, name: string, code: Types.StockCode, address: { __typename?: 'StockAddressType', suite?: string | null, addressFirst: string, addressSecond?: string | null, city: string, state?: string | null, zipCode: string, country: string, phone?: string | null } } }, deliveryAddress?: { __typename?: 'AddressType', id: number, country: Types.UserCountry, createdAt: any, isFavorite: boolean, isUsed: boolean, address: { __typename?: 'AddressDetailsType', city: string, street: string, home: string, flat: string } } | null, pickupPoint?: { __typename?: 'PickupPointType', id: number, name: string, useAddress: boolean } | null } };


export const GetPackageDocument = gql`
    query getPackage($id: Int!) {
  getPackage(id: $id) {
    id
    track
    status
    cargoType
    storeStatus
    description
    weight
    createdAt
    packageCost
    packageCount
    insuranceCost
    isPaid
    isDamaged
    rateCost
    deliveryRate {
      id
      name
      cost
      time
      description
      stock {
        id
        name
        code
        address {
          suite
          addressFirst
          addressSecond
          city
          state
          zipCode
          country
          phone
        }
      }
    }
    deliveryAddress {
      id
      country
      address {
        city
        street
        home
        flat
      }
      createdAt
      isFavorite
      isUsed
    }
    pickupPoint {
      id
      name
      useAddress
    }
  }
}
    `;

/**
 * __useGetPackageQuery__
 *
 * To run a query within a React component, call `useGetPackageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPackageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPackageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPackageQuery(baseOptions: Apollo.QueryHookOptions<GetPackageQuery, GetPackageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPackageQuery, GetPackageQueryVariables>(GetPackageDocument, options);
      }
export function useGetPackageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPackageQuery, GetPackageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPackageQuery, GetPackageQueryVariables>(GetPackageDocument, options);
        }
export type GetPackageQueryHookResult = ReturnType<typeof useGetPackageQuery>;
export type GetPackageLazyQueryHookResult = ReturnType<typeof useGetPackageLazyQuery>;
export type GetPackageQueryResult = Apollo.QueryResult<GetPackageQuery, GetPackageQueryVariables>;