import styled from 'styled-components';

export const AddressesFormModalHeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: 32px;
`;

export const AddressModalWrapper = styled.div`
  max-width: 600px;
  height: 100%;
  padding: 40px;
  border-radius: 16px;
  background: var(--color-bg-secondary);

  .Button {
    width: 100%;
    margin-top: 32px;
    margin-bottom: 24px;
  }
`;
