import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateDeliveryRateMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  input: Types.UpdateDeliveryRateInput;
}>;


export type UpdateDeliveryRateMutation = { __typename?: 'Mutation', updateDeliveryRate: { __typename?: 'DeliveryRateType', id: number } };


export const UpdateDeliveryRateDocument = gql`
    mutation updateDeliveryRate($id: Int!, $input: UpdateDeliveryRateInput!) {
  updateDeliveryRate(id: $id, input: $input) {
    id
  }
}
    `;
export type UpdateDeliveryRateMutationFn = Apollo.MutationFunction<UpdateDeliveryRateMutation, UpdateDeliveryRateMutationVariables>;

/**
 * __useUpdateDeliveryRateMutation__
 *
 * To run a mutation, you first call `useUpdateDeliveryRateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDeliveryRateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDeliveryRateMutation, { data, loading, error }] = useUpdateDeliveryRateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDeliveryRateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDeliveryRateMutation, UpdateDeliveryRateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDeliveryRateMutation, UpdateDeliveryRateMutationVariables>(UpdateDeliveryRateDocument, options);
      }
export type UpdateDeliveryRateMutationHookResult = ReturnType<typeof useUpdateDeliveryRateMutation>;
export type UpdateDeliveryRateMutationResult = Apollo.MutationResult<UpdateDeliveryRateMutation>;
export type UpdateDeliveryRateMutationOptions = Apollo.BaseMutationOptions<UpdateDeliveryRateMutation, UpdateDeliveryRateMutationVariables>;