import styled from 'styled-components';

export const PageContainer = styled.div`
  min-height: 100vh;
  background: var(--color-bg-secondary);
  overflow-x: hidden;
`;

export const ContentContainer = styled.div`
  padding: 40px;
  padding-bottom: 0;
  min-height: calc(100vh - 140px);
  position: relative;

  @media (max-width: 1100px) {
    padding: 40px 20px 0;
  }
`;
