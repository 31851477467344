import { Modal } from '@consta/uikit/Modal';
import { Text } from '@consta/uikit/Text';
import { Button } from '@consta/uikit/Button';
import {
  ConfirmModalWrapper,
  ConfirmModalHeaderWrapper,
  BodyWrapper,
  ActionsWrapper,
} from './styled';

export interface ConfirmModalProps {
  isModalOpen: boolean;
  title: string;
  body: string;
  submitTitle: string;
  onSubmit: () => void;
  onClose: () => void;
  closeButtonTitle?: string;
  customMaxWidth?: number;
}

const ConfirmModal = ({
  isModalOpen,
  title,
  body,
  submitTitle,
  onSubmit,
  onClose,
  closeButtonTitle,
  customMaxWidth,
}: ConfirmModalProps) => {
  const isMobile = window.innerWidth < 500;
  return (
    <Modal
      isOpen={isModalOpen}
      onClickOutside={() => onClose()}
      onEsc={() => onClose()}
    >
      <ConfirmModalWrapper style={{ maxWidth: customMaxWidth }}>
        <ConfirmModalHeaderWrapper>
          <Text size={isMobile ? 'xl' : '3xl'} view='primary' weight='medium'>
            {title}
          </Text>
        </ConfirmModalHeaderWrapper>
        <BodyWrapper>
          <Text size={isMobile ? 's' : 'm'} view='secondary'>
            {body}
          </Text>
        </BodyWrapper>
        <ActionsWrapper>
          {closeButtonTitle && (
            <Button
              size={isMobile ? 's' : 'l'}
              view='ghost'
              form='round'
              width='default'
              label={closeButtonTitle || ''}
              onClick={onClose}
            />
          )}
          <Button
            size={isMobile ? 's' : 'l'}
            view='primary'
            form='round'
            width='default'
            className='buttonBlack'
            label={submitTitle}
            onClick={onSubmit}
          />
        </ActionsWrapper>
      </ConfirmModalWrapper>
    </Modal>
  );
};

export default ConfirmModal;

ConfirmModal.defaultProps = {
  closeButtonTitle: '',
  customMaxWidth: 400,
};
