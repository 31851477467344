import styled from 'styled-components';

export const BottomCounterContainer = styled.div`
  background: var(--color-bg-default);
  width: 374px;
  height: 105px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: calc(50% - 187px);
  bottom: 40px;
  border-radius: 8px;

  .Button {
    background: var(--color-typo-alert) !important;
    margin-top: 10px;
  }

  @media (max-width: 640px) {
    width: calc(100% - 40px);
    left: 20px;
    bottom: 20px;
    background: var(--color-bg-system);
  }
`;