import { Text } from '@consta/uikit/Text';
import { useEffect, useState } from 'react';
import { Button } from '@consta/uikit/Button';
import { IconEdit } from '@consta/uikit/IconEdit';
import { Card } from '@consta/uikit/Card';
import { Grid, GridItem } from '@consta/uikit/Grid';
import { useDeliveryRatesListQuery } from 'features/add-package/api/deliveryRatesList.generated';
import { DeliveryRateType } from 'types';
import { useUpdateDeliveryRateMutation } from 'entities/delivery-rate/api/updateDeliveryRate.generated';
import RateField, {
  RateRowType,
} from 'features/update-delivery-rate/rate-field';
import ConfirmModal from 'entities/ui/confirmModal';
import {
  DeliverRatesHeader,
  DeliveryRateRowContainer,
  RatesListHeader,
} from './styled';

const DeliverRatesPage = () => {
  const [isEdit, setIsEdit] = useState(false);
  const [rates, setRates] = useState<RateRowType[]>([]);
  const [deliveryRates, setDeliveryRates] = useState<DeliveryRateType[]>();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [confirmModalData, setConfirmModalData] = useState({
    title: '',
    body: '',
    submitTitle: '',
  });

  const { data } = useDeliveryRatesListQuery({
    variables: {
      input: {},
    },
  });

  const [updateDeliveryRate] = useUpdateDeliveryRateMutation();

  useEffect(() => {
    if (data?.deliveryRatesList) {
      const deliveryRatesData = [...data?.deliveryRatesList];
      setDeliveryRates(deliveryRatesData.sort((a, b) => a?.id - b?.id));
      setRates(
        data?.deliveryRatesList.map((item) => ({
          cost: item.cost,
          id: item.id,
          isChanged: false,
        })),
      );
    }
  }, [data]);

  const rateName = (item: DeliveryRateType) => {
    const itemName = `${item?.stock?.name}${
      item?.name ? `, ${item?.name}` : ''
    }`;

    return (
      <Text size='m' view='primary' key={`name_${item.id}`}>
        {itemName}
      </Text>
    );
  };

  const handleRateChange = (value: number | null, id: number) => {
    const newRates = rates.map((rate) => {
      if (rate.id === id) {
        return { cost: value, id, isChanged: true };
      }
      return rate;
    });

    setRates(newRates);
  };

  const onSave = () => {
    rates.forEach((rate) => {
      if (rate.isChanged) {
        updateDeliveryRate({
          variables: {
            id: rate.id,
            input: {
              cost: rate.cost,
            },
          },
        });
      }
    });
    setIsEdit(!isEdit);
  };

  const onClickSave = () => {
    setIsConfirmModalOpen(true);
    setConfirmModalData({
      title: 'Вы действительно хотите внести изменения?',
      body: '',
      submitTitle: 'Да',
    });
  };

  const onCloseConfirmModal = () => {
    setIsConfirmModalOpen(false);
  };

  const onSubmitConfirmModal = async () => {
    onSave();
    setIsConfirmModalOpen(false);
  };

  return (
    <>
      <DeliverRatesHeader>
        <Text size='4xl' view='primary' weight='medium'>
          Тарифы
        </Text>
        <>
          {isEdit ? (
            <div>
              <Button
                label='Отменить'
                view='ghost'
                size='s'
                onlyIcon
                onClick={() => setIsEdit(!isEdit)}
              />
              <Button
                label='Сохранить'
                view='primary'
                className='greenButton'
                size='s'
                onlyIcon
                disabled={!rates.every((rate) => rate.cost)}
                onClick={onClickSave}
              />
            </div>
          ) : (
            <Button
              className='buttonBlack'
              form='round'
              label='Редактировать'
              size='s'
              iconRight={IconEdit}
              onClick={() => setIsEdit(!isEdit)}
            />
          )}
        </>
      </DeliverRatesHeader>
      <RatesListHeader>
        <Card shadow={false}>
          <Text size='m' view='primary'>
            СКЛАД
          </Text>
          <Text size='m' view='primary'>
            ТАРИФ $
          </Text>
        </Card>
      </RatesListHeader>
      <DeliveryRateRowContainer>
        <Grid cols={12}>
          <GridItem col={10}>
            {deliveryRates?.map((item) => rateName(item))}
          </GridItem>
          <GridItem col={2}>
            {deliveryRates?.map((item) => (
              <RateField
                key={`Rate_field_${item.id}`}
                item={item}
                rates={rates}
                disabled={!isEdit}
                handleRateChange={handleRateChange}
              />
            ))}
          </GridItem>
        </Grid>
      </DeliveryRateRowContainer>
      <ConfirmModal
        isModalOpen={isConfirmModalOpen}
        title={confirmModalData.title}
        body={confirmModalData.body}
        submitTitle={confirmModalData.submitTitle}
        onClose={onCloseConfirmModal}
        onSubmit={onSubmitConfirmModal}
        closeButtonTitle='Отмена'
      />
    </>
  );
};

export default DeliverRatesPage;
