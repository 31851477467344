import { clearCache } from 'app/hocs/withApollo';
import { accessTokenName, refreshTokenName, expiresName } from '../constants';

export const setAuthToken = (token: string, refreshToken?: string, expired?: string): void => {
  localStorage.setItem(accessTokenName, token);

  if (refreshToken) {
    localStorage.setItem(refreshTokenName, refreshToken);
  }

  if (expired) {
    localStorage.setItem(expiresName, expired);
  }
};

export const getAuthToken = (): string | null => {
  return localStorage.getItem(accessTokenName) || null;
};

export const getRefreshToken = (): string | null => {
  return localStorage.getItem(refreshTokenName) || null;
};

export const getExpiredDate = (): string | null => {
  return localStorage.getItem(expiresName) || null;
};

export const removeAuthToken = (): void => {
  localStorage.removeItem(accessTokenName);
  localStorage.removeItem(refreshTokenName);
  localStorage.removeItem(expiresName);

  clearCache();
};