import styled from 'styled-components';

export const PageContainer = styled.div`
  min-height: 100vh;
  background: var(--color-bg-secondary);
  overflow-x: hidden;
  padding-bottom: 15px;
`;

export const ContentContainer = styled.div`
  padding: 40px;
  min-height: calc(100vh - 290px);
  position: relative;

  @media (max-width: 1100px) {
    padding: 28px 20px;
    min-height: calc(100vh - 311px);
  }

  @media (max-width: 640px) {
    padding: 20px;
    min-height: calc(100vh - 375px);
  }
`;
