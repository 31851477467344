import { Text } from '@consta/uikit/Text';

export const InfoWithLabel = ({
  title,
  value,
}: {
  title: string;
  value: string | number;
}) => {
  return (
    <div className='infoContainer'>
      <Text size='m' view='secondary' weight='regular'>
        {title}
      </Text>
      <Text size='m' view='primary' weight='regular'>
        {value}
      </Text>
    </div>
  );
};
