import React, { FC, useState, useEffect, useRef } from 'react';
import { Text } from '@consta/uikit/Text';
import { Button } from '@consta/uikit/Button';
import { TextField } from '@consta/uikit/TextField';
import { IconBackward } from '@consta/uikit/IconBackward';
import { IconSettings } from '@consta/uikit/IconSettings';
import { IconAdd } from '@consta/uikit/IconAdd';
import { Tabs } from '@consta/uikit/Tabs';
import { Card } from '@consta/uikit/Card';
import { IconSearch } from '@consta/uikit/IconSearch';
import {
  SortByProps,
  Table,
  TableNumberFilter,
  TableFilters,
} from '@consta/uikit/Table';
import { Modal } from '@consta/uikit/Modal';
import { SelectedFilters } from '@consta/uikit/__internal__/src/components/Table/filtering';
import {
  ImportPackageInput,
  Maybe,
  PackagesOrderInput,
  PackageType,
  ShipmentType,
} from 'types';
import { usePackagesListQuery } from 'features/packages/api/packagesList.generated';
import { useShipmentsListQuery } from 'features/shipments/api/shipmentsList.generated';
import { useCreateShipmentMutation } from 'features/shipments/api/createShipment.generated';
import { useImportShipmentMutation } from 'features/shipments/api/importShipment.generated';
import { useUpdateShipmentMutation } from 'features/shipments/api/updateShipment.generated';
import { useStocksListQuery } from 'features/main/api/stocksList.generated';
import ExcelImport from 'features/shipments/excel-import';
import { EmptyContainer, TableContainer } from 'pages/packages/styled';
import ShipmentsModal from 'features/shipments/shipments-modal';
import ShipmentPackageEditForm from 'widgets/shipment-package-edit';
import { ShipmentPackageDataType } from 'features/admin-package-row/utils';
import Margin from 'shared/ui/components/margin';
import Row from 'shared/ui/components/row';
import { useIsInViewport } from 'shared/hooks/useIsInViewport';
import { BottomFieldContainer } from './styled';
import { PackageRow, ShipmentsTab } from './types';

const PackagesPage: FC = () => {
  const [isArchive, setIsArchive] = useState(false);
  const [isEditOpen, setIsEditModalOpen] = useState(false);
  const [shipmentPackageForEdit, setShipmentPackageForEdit] =
    useState<ShipmentPackageDataType | null>(null);

  const pageSize = 40;
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);

  const [packages, setPackages] = useState<PackageRow[]>([]);
  const [stockId, setStockId] = useState<number | undefined>();
  const [shipmentId, setShipmentId] = useState<number | undefined>();
  const [q, setQ] = useState<string | undefined>();
  const [debouncedQ, setDebouncedQ] = useState<string | undefined>();

  const [weightFilter, setWeightFilter] = useState<
    { min?: number; max?: number } | undefined
  >();
  const [countFilter, setCountFilter] = useState<
    { min?: number; max?: number } | undefined
  >();

  const [sortOrder, setSortOrder] = useState<Maybe<PackagesOrderInput>>(null);

  const footerRef = useRef(document.getElementById('footer'));
  const inViewport = useIsInViewport(footerRef);

  const { data: stocks } = useStocksListQuery();

  const [showModal, setShowModal] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);

  useEffect(() => {
    if (stocks?.stocksList?.length) {
      setStockId(stocks.stocksList[0].id);
    }
  }, [stocks]);

  const [shipmentsTabs, setShipmentsTabs] = useState<ShipmentsTab[]>([]);

  const { data: shipments, refetch: refetchShipments } = useShipmentsListQuery({
    fetchPolicy: 'no-cache',
    variables: {
      filter: {
        stockId,
        isArchive,
      },
    },
  });

  useEffect(() => {
    if (shipments?.shipmentsList?.length) {
      setShipmentId(shipments.shipmentsList[0]?.id);
      setShipmentsTabs(
        shipments.shipmentsList.map((item) => ({
          label: item.name,
          value: item.id,
        })),
      );
    } else {
      setShipmentId(undefined);
      setShipmentsTabs([]);
    }
  }, [shipments]);

  const { data, refetch } = usePackagesListQuery({
    fetchPolicy: 'no-cache',
    variables: {
      filter: {
        stockId,
        q: debouncedQ || undefined,
        shipmentId,
        weight: weightFilter,
        packageCount: countFilter,
      },
      pagination: {
        offset: offset * pageSize,
        limit: pageSize,
        order: sortOrder || undefined,
      },
    },
  });

  useEffect(() => {
    const items = data?.packagesList?.items;

    if (items && stockId && shipmentId) {
      setRowsFromPackages([...items] as PackageType[]);
      if (!offset) {
        setTotal(data?.packagesList?.total);
      }
    } else {
      setRowsFromPackages([]);
      setTotal(0);
    }
  }, [data]);

  useEffect(() => {
    if (inViewport && (offset + 1) * pageSize <= total) {
      setOffset(offset + 1);
    }
  }, [inViewport]);

  const setRowsFromPackages = (items: PackageType[]) => {
    const packagesWithRows: PackageRow[] = items.map(
      ({ id, track, weight, size, packageCount }) => ({
        id: id.toString(),
        track,
        weight: weight ?? undefined,
        length: size?.length ?? undefined,
        height: size?.height ?? undefined,
        width: size?.width ?? undefined,
        packageCount: packageCount ?? undefined,
      }),
    );

    if (offset) {
      setPackages([...packages, ...packagesWithRows]);
    } else {
      setPackages(packagesWithRows);
    }
  };

  const onRowClick = ({ id }: { id: string }) => {
    const packageData = packages?.find((p) => p.id === id);
    setShipmentPackageForEdit({
      id: String(packageData?.id),
      track: packageData?.track,
      weight: packageData?.weight,
      length: packageData?.length,
      width: packageData?.width,
      height: packageData?.height,
      packageCount: packageData?.packageCount,
    });
    setIsEditModalOpen(true);
  };

  const columns = [
    {
      title: 'ТРЕК-НОМЕР',
      accessor: 'track',
      sortable: false,
    },
    {
      title: 'ВЕС (КГ)',
      accessor: 'weight',
      sortable: true,
    },
    {
      title: 'ДЛИНА (Д)',
      accessor: 'length',
      sortable: false,
    },
    {
      title: 'ШИРИНА (Д)',
      accessor: 'width',
      sortable: false,
    },
    {
      title: 'ВЫСОТА (Д)',
      accessor: 'height',
      sortable: false,
    },
    {
      title: 'КОЛИЧЕСТВО МЕСТ',
      accessor: 'packageCount',
      sortable: true,
    },
  ];

  useEffect(() => {
    setOffset(0);
  }, [stockId, shipmentId]);

  const handleSortingUpdated = (value: Maybe<SortByProps<PackageRow>>) => {
    setOffset(0);
    if (value) {
      const sort = {
        [value.sortingBy]: value?.sortOrder.toUpperCase(),
      };
      setSortOrder(sort);
    } else {
      setSortOrder(null);
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedQ(q);
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [q]);

  const [importShipment] = useImportShipmentMutation();
  const [createShipment] = useCreateShipmentMutation();

  const createNewShipment = () => {
    if (stockId) {
      createShipment({
        variables: {
          input: {
            stockId,
          },
        },
      }).then(() => refetchShipments());
    }
  };

  const importShipmentFunc = (importPackages: ImportPackageInput[]) => {
    if (shipmentId && importPackages.length) {
      importShipment({
        variables: {
          id: shipmentId,
          input: {
            packages: importPackages,
          },
        },
      }).then(() => {
        refetch();
      });
    }
  };

  const filterWeight: TableFilters<(typeof packages)[number]> = [
    {
      id: 'weight',
      name: 'Вес ',
      filterer: () => true,
      field: 'weight',
      component: {
        name: TableNumberFilter,
      },
    },
  ];

  const filterCount: TableFilters<(typeof packages)[number]> = [
    {
      id: 'packageCount',
      name: 'Количество посылок ',
      filterer: () => true,
      field: 'packageCount',
      component: {
        name: TableNumberFilter,
      },
    },
  ];

  const filters = [...filterWeight, ...filterCount];

  const handleFilterUpdated = ({ weight, packageCount }: SelectedFilters) => {
    if (weight?.selected?.length || packageCount?.selected?.length) {
      setIsFiltered(true);
    } else {
      setIsFiltered(false);
    }

    if (weight?.selected?.length) {
      setWeightFilter({
        min: parseFloat(weight?.value?.min) || undefined,
        max: parseFloat(weight?.value?.max) || undefined,
      });
    } else {
      setWeightFilter(undefined);
    }

    if (packageCount?.selected?.length) {
      setCountFilter({
        min: parseFloat(packageCount?.value?.min) || undefined,
        max: parseFloat(packageCount?.value?.max) || undefined,
      });
    } else {
      setCountFilter(undefined);
    }
  };

  const [updateShipment] = useUpdateShipmentMutation();

  const onSaveShipments = async (editedShipments: ShipmentType[]) => {
    const promises = editedShipments.map(
      ({ id, name, isArchive: isArchiveField }) =>
        updateShipment({
          variables: {
            id,
            input: {
              name,
              isArchive: isArchiveField,
            },
          },
        }),
    );

    await Promise.all(promises).then(() => {
      refetchShipments();
    });
  };

  const onArchive = () => {
    if (shipmentId) {
      updateShipment({
        variables: {
          id: shipmentId,
          input: {
            isArchive: true,
          },
        },
      }).then(() => refetchShipments());
    }
  };

  const onCloseEditModal = () => {
    refetch();
    setIsEditModalOpen(false);
  };

  return (
    <>
      <Modal className='userEditModal' isOpen={isEditOpen}>
        <ShipmentPackageEditForm
          package={shipmentPackageForEdit}
          onCloseModal={onCloseEditModal}
        />
      </Modal>
      {(isArchive && (
        <Button
          view='clear'
          size='s'
          label='Назад к партиям'
          iconLeft={IconBackward}
          onClick={() => setIsArchive(false)}
        />
      )) ||
        null}
      <Margin mb={32}>
        <Row direction='row' justify='flex-start' align='center'>
          <Row justify='space-between' align='center' style={{ width: '100%' }}>
            <Text size='4xl' view='primary' weight='medium'>
              {isArchive ? 'Архив' : 'Партии'}
            </Text>
            {(!isArchive && (
              <Button
                view='clear'
                size='s'
                label='Архив'
                onClick={() => setIsArchive(true)}
              />
            )) ||
              null}
          </Row>
          <Margin mt={0} style={{ width: 'unset' }}>
            <Row direction='row' justify='flex-end' align='center'>
              {(shipments?.shipmentsList?.length && (
                <Margin ml={12} style={{ width: 'unset' }}>
                  <Button
                    onlyIcon
                    form='round'
                    iconLeft={IconSettings}
                    size='s'
                    view='ghost'
                    onClick={() => setShowModal(true)}
                  />
                </Margin>
              )) ||
                null}
              <Margin ml={24} style={{ width: '270px' }}>
                <TextField
                  type='text'
                  placeholder='Трек-номер посылки'
                  form='round'
                  width='full'
                  size='s'
                  value={q}
                  leftSide={IconSearch}
                  onChange={({ value }) => setQ(value || undefined)}
                />
              </Margin>
              {(!isArchive && (
                <Margin ml={24} style={{ width: 'unset' }}>
                  <Button
                    view='secondary'
                    size='s'
                    form='round'
                    label='Добавить новую партию'
                    iconRight={IconAdd}
                    onClick={createNewShipment}
                  />
                </Margin>
              )) ||
                null}
            </Row>
          </Margin>
        </Row>
      </Margin>
      {(stocks?.stocksList?.length && (
        <Margin mb={40}>
          <Row justify='flex-start' align='center' className='stocksList'>
            {stocks.stocksList.map((item) => (
              <Margin key={item.id} mr={8} mb={8} style={{ width: 'unset' }}>
                <Button
                  view={item.id === stockId ? 'primary' : 'ghost'}
                  label={item.name}
                  className={item.id === stockId ? 'buttonBlack' : undefined}
                  size='s'
                  form='round'
                  onClick={() => setStockId(item.id)}
                />
              </Margin>
            ))}
          </Row>
        </Margin>
      )) ||
        null}
      {(shipmentsTabs.length && (
        <Margin mb={40}>
          <Tabs
            value={shipmentsTabs.find((item) => item.value === shipmentId)}
            onChange={({ value }) => setShipmentId(value.value)}
            items={shipmentsTabs}
            size='m'
            fitMode='scroll'
          />
        </Margin>
      )) ||
        null}
      {packages?.length || isFiltered ? (
        <Margin mb={60}>
          <TableContainer>
            <Table
              rows={packages}
              // @ts-ignore
              columns={columns}
              defaultExpandAll={false}
              onSortBy={handleSortingUpdated}
              filters={filters}
              onFiltersUpdated={handleFilterUpdated}
              onRowClick={onRowClick}
            />
          </TableContainer>
        </Margin>
      ) : (
        <EmptyContainer>
          <Card shadow={false}>
            <Text size='3xl' weight='medium' align='center'>
              Не найдено ни одной посылки
            </Text>
          </Card>
        </EmptyContainer>
      )}
      {(!isArchive && stockId && shipmentId && (
        <BottomFieldContainer>
          <Row justify='space-between' align='center'>
            {/* <Button
            view='primary'
            size='s'
            className='buttonBlack'
            iconRight={IconEdit}
            label='Редактировать посылки'
          /> */}
            <div />
            <Row justify='flex-end' align='center'>
              <Margin mr={12} style={{ width: 'unset' }}>
                <ExcelImport onImport={importShipmentFunc} />
              </Margin>
              <Button
                view='clear'
                size='s'
                label='Убрать партию в архив'
                onClick={onArchive}
              />
            </Row>
          </Row>
        </BottomFieldContainer>
      )) || null}
      {(shipments?.shipmentsList?.length && (
        <ShipmentsModal
          isModalOpen={showModal}
          shipments={shipments.shipmentsList}
          onClose={() => setShowModal(false)}
          onSubmit={(editedShipments) => onSaveShipments(editedShipments)}
        />
      )) ||
        null}
    </>
  );
};

export default PackagesPage;
