import styled from 'styled-components';

export const InfoCardWrapper = styled.div`
  height: 100%;

  .Card {
    border-radius: 16px;
    background: var(--color-bg-default);
    height: 100%;

    &.active {
      background: var(--color-bg-brand);

      & .Button {
        color: var(--color-bg-secondary);
        border-color: var(--color-bg-secondary);
      }

      .Text {
        color: var(--color-bg-secondary);
      }
    }

    .iconContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      min-width: 40px;
      height: 40px;
      border-radius: 50%;
      background: var(--color-bg-secondary);
    }
  }
`;