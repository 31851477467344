import styled from 'styled-components';

export const ProfileWrapper = styled.div`
  .Card {
    border-radius: 16px;
    height: 100%;
  }
`;

export const ProfileActionsContainer = styled.div`
  margin-left: auto;
  display: flex;
  @media (max-width: 768px) {
    align-items: center;
    flex-direction: row-reverse;
    justify-content: start;
    margin-top: 16px;
  }
  .Button {
    margin-right: 16px;
    @media (max-width: 500px) {
      margin-top: 16px;
    }
  }
`;

export const TitleContainer = styled.div`
  margin-right: 32px;
`;

export const IdContainer = styled.div``;

export const ProfileHeaderContainer = styled.div`
  display: flex;
  margin-bottom: 32px;
  .Text {
    margin-right: 4px;
  }
  @media (max-width: 768px) {
    display: block;
    .Text:nth-child(2) {
      margin-left: auto;
    }
  }
`;
