import { UserProfileStatType } from 'types';

export const usersColumns = [
  {
    title: 'ID КЛИЕНТА',
    accessor: 'id',
    sortable: false,
    width: '10%',
  },
  {
    title: 'ТЕЛЕФОН',
    accessor: 'phone',
    sortable: false,
    width: '13%',
  },
  {
    title: 'EMAIL',
    accessor: 'email',
    sortable: false,
    width: '21%',
  },
  {
    title: 'ФИО КИРИЛЛИЦА',
    accessor: 'name',
    sortable: false,
    width: '23%',
  },
  {
    title: 'ФИО ЛАТИНИЦА',
    accessor: 'nameEn',
    sortable: false,
    width: '23%',
  },
  {
    title: 'ДАТА РЕГИСТРАЦИИ',
    accessor: 'createdAt',
    sortable: true,
    width: '10%',
  },
];

export const getInfoRows = (
  id: string | undefined,
  userData: UserProfileStatType | undefined,
) => {
  return [
    {
      title: 'ID КЛИЕНТА',
      value: id || '0',
    },
    {
      title: 'ВСЕГО ПОСЫЛОК ДОСТАВЛЕНО',
      value: userData?.totalPackages || '0',
    },
    {
      title: 'ОБЩИЙ ВЕС ДОБАВЛЕН',
      value: `${userData?.totalWeight} кг` || '0 кг',
    },
    {
      title: 'ПОСЫЛОК В ПУТИ',
      value: userData?.inTransitPackages || '0',
    },
    {
      title: 'ОБЩИЙ ВЕС В ПУТИ',
      value: `${userData?.inTransitWeight} кг` || '0 кг',
    },
  ];
};
