import { useEffect, useState } from 'react';
import { UserType } from 'types';
import { getAuthToken } from 'shared/api/auth';
import { useMeQuery } from './api/me.generated';

export const useUser = () => {
  const token = getAuthToken();

  const { loading, data, refetch } = useMeQuery({
    skip: !token,
    fetchPolicy: 'cache-first', // Used for first execution
    nextFetchPolicy: 'cache-first',
    refetchWritePolicy: 'overwrite',
  });

  const [user, setUser] = useState<UserType | undefined>(() => data?.me);

  useEffect(() => {
    if (data?.me) {
      setUser(data.me);
    }
  }, [data]);

  const isAuth = !!user;

  return {
    user,
    isAuth,
    refetch,
    loading,
  };
};
