import styled from 'styled-components';

export const StepBarContainer = styled.div`
  .ProgressStepBar {
    .ProgressStepBarItem-Label {
      white-space: nowrap;
    }
    
    .ProgressStepBarItem_status_normal {
      .ProgressStepBarItem-PointText {
        color: var(--color-bg-secondary);
      }
    }
  }
`;

export const ContentCard = styled.div`
  & > .Card {
    width: 100%;
    background: var(--color-bg-default);
    padding: 28px 24px 40px;
    border-radius: 16px;

    @media (max-width: 1100px) {
      padding: 24px 20px 32px;
    }

    @media (max-width: 640px) {
      padding: 16px 16px 20px;
    }
  }
`;