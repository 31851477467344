import { Layout } from '@consta/header/Layout';

import styled from 'styled-components';

export const CustomFooter = styled(Layout)`
  & > div {
    width: 98%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 16px;
  }
  .che--Layout-Row {
    border-bottom: none;
  }
  .che--Layout-Row_center {
    height: 150px;
    margin-bottom: 8px;
    @media (max-width: 1280px) {
      height: 180px;
    }
    @media (max-width: 500px) {
      height: 310px;
    }
  }
  // Похоже на опечатку в классе консты, можемт отвалиться
  .che--Layout-Row_botton {
    height: 55px;
    margin-bottom: 8px;
    padding-top: 0px;
    .che--LayoutRow-Side_position_left {
      align-items: flex-start;
      padding-top: 4px;
    }
    @media (max-width: 500px) {
      height: 80px;
      .che--LayoutRow-Side_position_left {
        align-items: flex-start;
        padding-top: 12px;
      }
    }
  }
  .che--LayoutRow-Side_position_right {
    width: 40%;
  }

  .che--LayoutRow-Side_position_left {
    align-items: flex-start;
    padding-top: 24px;
  }

  img {
    height: 50px;
    width: 50px;
  }
`;

export const MobileNavigationFooter = styled.div`
  display: block;
`;

export const MobileMenuWrapper = styled.div`
  display: flex;
  margin-bottom: 16px;
  .Grid {
    grid-row-gap: 4px !important;
  }
  img {
    height: 100px;
    width: 100px;
  }
`;
