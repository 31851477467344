import { CargoType } from 'types';

export const cargoTypeItems: { label: string, value: CargoType }[] = [
  {
    label: 'Текстиль',
    value: CargoType.Wear,
  },
  {
    label: 'Косметика',
    value: CargoType.Cosmetic,
  },
  {
    label: 'Запчасти',
    value: CargoType.Spares,
  },
  {
    label: 'Иное',
    value: CargoType.Other,
  },
];