import styled from 'styled-components';

export const ConfirmModalHeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
  text-align: center;
`;

export const ConfirmModalWrapper = styled.div`
  max-width: 400px;
  height: 100%;
  padding: 40px;
  border-radius: 16px;
  background: var(--color-bg-secondary);

  .Button {
    width: 100%;
    margin-top: 32px;
    margin-bottom: 24px;
  }
`;

export const BodyWrapper = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  .Text {
    width: 90%;
  }
`;

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: center;
  .Button {
    width: 30%;
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }

    @media (max-width: 640px) {
      width: calc(50% - 5px);
    }
  }
`;
