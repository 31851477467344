import { FC, useEffect, useState } from 'react';
import { Html5Qrcode, Html5QrcodeScannerState } from 'html5-qrcode';
import { ScannerBox } from './styled';

interface IProps {
  onDetected: (result: string) => void;
}

const Scanner: FC<IProps> = ({ onDetected }: IProps) => {
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    const scanner = new Html5Qrcode('scanner');

    scanner.start(
      { facingMode: 'environment' },
      { fps: 100, disableFlip: true, aspectRatio: 1.5403726 },
      (result) => {
        onDetected(result);
        setSuccess(true);
      },
      () => {},
    );

    return () => {
      if ([Html5QrcodeScannerState.PAUSED, Html5QrcodeScannerState.SCANNING].includes(scanner.getState())) {
        scanner.stop().then(() => scanner.clear());
      } else {
        scanner.clear();
      }
    };
  }, []);

  return (
    <ScannerBox id='scanner' success={success} />
  );
};

export default Scanner;
