import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateBuyoutsMutationVariables = Types.Exact<{
  input: Array<Types.CreateBuyoutInput> | Types.CreateBuyoutInput;
}>;


export type CreateBuyoutsMutation = { __typename?: 'Mutation', createBuyouts: number };


export const CreateBuyoutsDocument = gql`
    mutation createBuyouts($input: [CreateBuyoutInput!]!) {
  createBuyouts(input: $input)
}
    `;
export type CreateBuyoutsMutationFn = Apollo.MutationFunction<CreateBuyoutsMutation, CreateBuyoutsMutationVariables>;

/**
 * __useCreateBuyoutsMutation__
 *
 * To run a mutation, you first call `useCreateBuyoutsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBuyoutsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBuyoutsMutation, { data, loading, error }] = useCreateBuyoutsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBuyoutsMutation(baseOptions?: Apollo.MutationHookOptions<CreateBuyoutsMutation, CreateBuyoutsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBuyoutsMutation, CreateBuyoutsMutationVariables>(CreateBuyoutsDocument, options);
      }
export type CreateBuyoutsMutationHookResult = ReturnType<typeof useCreateBuyoutsMutation>;
export type CreateBuyoutsMutationResult = Apollo.MutationResult<CreateBuyoutsMutation>;
export type CreateBuyoutsMutationOptions = Apollo.BaseMutationOptions<CreateBuyoutsMutation, CreateBuyoutsMutationVariables>;