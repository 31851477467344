import { Navigate, RouteObject } from 'react-router-dom';
import Layout from 'widgets/admin-layout';
import UsersPage from 'pages/admin/users';
import Analytics from 'pages/admin/analytics';
import LoginPage from 'pages/admin/login';
import ShipmentsPage from 'pages/admin/shipments';
import RepurchasePage from 'pages/admin/repurchase';
import AdminPackages from 'pages/admin/packages';
import StoreKeeperPackages from 'pages/store-keeper/store-keeper-packages';
import StoreKeeperScanner from 'pages/store-keeper/store-keeper-scanner';
import DeliverRatesPage from 'pages/admin/delivery-rates';
import StockKeeperPackages from 'pages/stock-keeper/stock-keeper-packages';
import StockKeeperScanner from 'pages/stock-keeper/stock-keeper-scanner';
import { RequireSuperAdmin, RequireStoreKeeper, RequireStockKeeper, RequireNotAuth } from 'shared/auth';

export const routes: RouteObject[] = [
  {
    path: '/login',
    element: (
      <RequireNotAuth>
        <LoginPage />
      </RequireNotAuth>
    ),
  },
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Navigate to={'users'} replace />,
      },
      {
        path: 'users',
        element: (
          <RequireSuperAdmin>
            <UsersPage />
          </RequireSuperAdmin>
        ),
      },
      {
        path: 'analytics',
        element: (
          <RequireSuperAdmin>
            <Analytics />
          </RequireSuperAdmin>
        ),
      },
      {
        path: 'shipments',
        element: (
          <RequireSuperAdmin>
            <ShipmentsPage />
          </RequireSuperAdmin>
        ),
      },
      {
        path: 'repurchase',
        element: (
          <RequireSuperAdmin>
            <RepurchasePage />
          </RequireSuperAdmin>
        ),
      },
      {
        path: 'packages',
        element: (
          <RequireSuperAdmin>
            <AdminPackages />
          </RequireSuperAdmin>
        ),
      },
      {
        path: '/delivery-rates',
        element: (
          <RequireSuperAdmin>
            <DeliverRatesPage />
          </RequireSuperAdmin>
        ),
      },
      {
        path: '/store-keeper-packages',
        element: (
          <RequireStoreKeeper>
            <StoreKeeperPackages />
          </RequireStoreKeeper>
        ),
      },
      {
        path: '/store-keeper-scanner/:id',
        element: (
          <RequireStoreKeeper>
            <StoreKeeperScanner />
          </RequireStoreKeeper>
        ),
      },
      {
        path: '/stock-keeper-packages',
        element: (
          <RequireStockKeeper>
            <StockKeeperPackages />
          </RequireStockKeeper>
        ),
      },
      {
        path: '/stock-keeper-scanner/:id',
        element: (
          <RequireStockKeeper>
            <StockKeeperScanner />
          </RequireStockKeeper>
        ),
      },
    ],
  },
];
