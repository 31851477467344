import './_color/Theme_color_defaultLTLTheme.css';
import './_control/Theme_control_defaultLTLTheme.css';
import './_font/Theme_font_defaultLTLTheme.css';
import './_size/Theme_size_defaultLTLTheme.css';
import './_space/Theme_space_defaultLTLTheme.css';
import './_shadow/Theme_shadow_defaultLTLTheme.css';
import { ThemePreset } from '@consta/uikit/Theme';


export const LTLPreset: ThemePreset  = {
    color: {
      primary: 'defaultLTLTheme',
      accent: 'defaultLTLTheme',
      invert: 'defaultLTLTheme',
    },
    control: 'defaultLTLTheme',
    font: 'defaultLTLTheme',
    size: 'defaultLTLTheme',
    space: 'defaultLTLTheme',
    shadow: 'defaultLTLTheme',
  };