import styled from 'styled-components';

export const DetailedPackageContainer = styled.div`
  .Card {
    border-radius: 16px;
    background: var(--color-bg-default);

    .Grid {
      padding-bottom: 40px;
      border-bottom: 1px solid var(--color-bg-border);

      .Badge_status_system {
        color: var(--color-typo-primary);
      }

      .Badge_status_normal {
        color: var(--color-bg-secondary);
      }
    }

    & > div {
        &:last-child {
          .Grid {
            padding-bottom: 0;
            border-bottom: none;
          }
        }
      }
    }
    
    @media (max-width: 1100px) {
      .Grid {
        padding-bottom: 36px;
      }
    }

    @media (max-width: 640px) {
      .Grid {
        padding-bottom: 32px;
      }
    }
  }
`;

export const EditButtonContainer = styled.div`
  .Button {
    color: var(--color-typo-link) !important;
  }
`;

export const RemoveButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  
  .Button {
    color: var(--color-typo-alert) !important;
  }

  @media (max-width: 1100px) {
    justify-content: flex-start;
  }
`;
