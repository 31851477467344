import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UsersListQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.UsersFilterInput>;
  pagination: Types.UsersPaginationInput;
}>;


export type UsersListQuery = { __typename?: 'Query', usersList: { __typename?: 'PaginatedUsersType', total: number, items: Array<{ __typename?: 'UserProfileType', id: number, name?: string | null, nameEn?: string | null, phone?: string | null, email: string, country?: Types.UserCountry | null, govId?: string | null, createdAt: any }> } };


export const UsersListDocument = gql`
    query usersList($filter: UsersFilterInput, $pagination: UsersPaginationInput!) {
  usersList(filter: $filter, pagination: $pagination) {
    items {
      id
      name
      nameEn
      phone
      email
      country
      govId
      createdAt
    }
    total
  }
}
    `;

/**
 * __useUsersListQuery__
 *
 * To run a query within a React component, call `useUsersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useUsersListQuery(baseOptions: Apollo.QueryHookOptions<UsersListQuery, UsersListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersListQuery, UsersListQueryVariables>(UsersListDocument, options);
      }
export function useUsersListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersListQuery, UsersListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersListQuery, UsersListQueryVariables>(UsersListDocument, options);
        }
export type UsersListQueryHookResult = ReturnType<typeof useUsersListQuery>;
export type UsersListLazyQueryHookResult = ReturnType<typeof useUsersListLazyQuery>;
export type UsersListQueryResult = Apollo.QueryResult<UsersListQuery, UsersListQueryVariables>;