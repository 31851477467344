import React, { FC, useMemo, useState } from 'react';
import { Select } from '@consta/uikit/Select';
import { Button } from '@consta/uikit/Button';
import { useStocksListQuery } from 'features/main/api/stocksList.generated';
import CountryLabel from 'features/country-label';
import { StockCode, StockType } from 'types';
import Margin from 'shared/ui/components/margin';

interface IProps {
  caption: string;
  onFinish: (stock: StockType) => void;
  additionalAction?: JSX.Element | null;
}

interface IStockItem {
  id: number;
  label: string;
  value: StockCode;
}

const Stock: FC<IProps> = ({ caption, onFinish, additionalAction }: IProps) => {
  const [currentStock, setCurrentStock] = useState<IStockItem | null>(null);
  const [stockError, setStockError] = useState(false);

  const { data } = useStocksListQuery();

  const stockItems: IStockItem[] | null = useMemo(() => {
    if (data?.stocksList) {
      return data.stocksList.map((item) => ({
        id: item.id,
        label: item.name,
        value: item.code,
      }));
    }
    return null;
  }, [data]);

  const handleChange = (value: IStockItem | null) => {
    if (!value?.id) return;

    setCurrentStock(value);
    setStockError(false);
  };

  const handleBlur = () => {
    if (!currentStock?.id) {
      setStockError(true);
    }
  };

  const onNext = () => {
    if (currentStock?.id) {
      const stock = data?.stocksList.find(
        (item) => item.id === currentStock.id,
      );
      stock && onFinish(stock);
    }
  };

  const isMobile = window.innerWidth < 640;

  return (
    <>
      {stockItems && (
        <Margin mb={isMobile ? 28 : 32}>
          <Select
            label='Склад'
            placeholder='Выберите склад'
            items={stockItems}
            form='round'
            required
            size={isMobile ? 's' : 'm'}
            value={currentStock}
            getItemLabel={(item) => item.label}
            getItemKey={(item) => item.id}
            onChange={({ value }) => handleChange(value)}
            status={stockError ? 'alert' : undefined}
            caption={stockError ? 'Поле не заполнено' : caption}
            onBlur={handleBlur}
            style={{ maxWidth: isMobile ? '100%' : '264px' }}
            renderItem={({ item, active, hovered, onClick, onMouseEnter }) => (
              <CountryLabel
                item={item}
                active={active}
                hovered={hovered}
                onClick={onClick}
                onMouseEnter={onMouseEnter}
              />
            )}
          />
        </Margin>
      )}
      {additionalAction && additionalAction}
      <Button
        label='Далее'
        view='primary'
        form='round'
        className='buttonBlack'
        size={isMobile ? 's' : 'm'}
        disabled={!currentStock || stockError}
        onClick={onNext}
      />
    </>
  );
};

export default Stock;

Stock.defaultProps = {
  additionalAction: null,
};
