import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserProfileStatQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type UserProfileStatQuery = { __typename?: 'Query', userProfileStat: { __typename?: 'UserProfileStatType', totalPackages: number, inTransitPackages: number, totalWeight: number, inTransitWeight: number } };


export const UserProfileStatDocument = gql`
    query userProfileStat($id: Int!) {
  userProfileStat(id: $id) {
    totalPackages
    inTransitPackages
    totalWeight
    inTransitWeight
  }
}
    `;

/**
 * __useUserProfileStatQuery__
 *
 * To run a query within a React component, call `useUserProfileStatQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserProfileStatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserProfileStatQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserProfileStatQuery(baseOptions: Apollo.QueryHookOptions<UserProfileStatQuery, UserProfileStatQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserProfileStatQuery, UserProfileStatQueryVariables>(UserProfileStatDocument, options);
      }
export function useUserProfileStatLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserProfileStatQuery, UserProfileStatQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserProfileStatQuery, UserProfileStatQueryVariables>(UserProfileStatDocument, options);
        }
export type UserProfileStatQueryHookResult = ReturnType<typeof useUserProfileStatQuery>;
export type UserProfileStatLazyQueryHookResult = ReturnType<typeof useUserProfileStatLazyQuery>;
export type UserProfileStatQueryResult = Apollo.QueryResult<UserProfileStatQuery, UserProfileStatQueryVariables>;