import { Navigate, RouteObject } from 'react-router-dom';
import Layout from 'widgets/client-layout';
import LoginPage from 'pages/login';
import RegisterPage from 'pages/register';
import ForgetPasswordPage from 'pages/forget-password';
import ResetPage from 'pages/reset';
import DetailedInformationPage from 'pages/detailed-information';
import ConfirmPhonePage from 'pages/phone-confirmation';
import MainPage from 'pages/main';
import InstructionsPage from 'pages/instructions';
import AddressesPage from 'pages/addresses';
import PackagesPage from 'pages/packages';
import ProfilePage from 'pages/profile';
import AddPackagePage from 'pages/add-package';
import RepurchasePage from 'pages/repurchase';
import DetailedPackage from 'pages/detailed-package';
import { RequireAuth, RequireNotAuth, RequireToken } from 'shared/auth';

export const routes: RouteObject[] = [
  {
    path: '/login',
    element: (
      <RequireNotAuth>
        <LoginPage />
      </RequireNotAuth>
    ),
  },
  {
    path: '/register',
    element: (
      <RequireNotAuth>
        <RegisterPage />
      </RequireNotAuth>
    ),
  },
  {
    path: '/forget-password',
    element: (
      <RequireNotAuth>
        <ForgetPasswordPage />
      </RequireNotAuth>
    ),
  },
  {
    path: '/reset/:id',
    element: (
      <RequireNotAuth>
        <ResetPage />
      </RequireNotAuth>
    ),
  },
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Navigate to={'dashboard'} replace />,
      },
      {
        path: 'profile',
        element: (
          <RequireAuth>
            <ProfilePage />
          </RequireAuth>
        ),
      },
      {
        path: 'dashboard',
        element: (
          <RequireAuth>
            <MainPage />
          </RequireAuth>
        ),
      },
      {
        path: '/instructions',
        element: (
          <RequireAuth>
            <InstructionsPage />
          </RequireAuth>
        ),
      },
      {
        path: '/addresses',
        element: (
          <RequireAuth>
            <AddressesPage />
          </RequireAuth>
        ),
      },
      {
        path: '/packages',
        element: (
          <RequireAuth>
            <PackagesPage />
          </RequireAuth>
        ),
      },
      {
        path: '/packages/:id',
        element: (
          <RequireAuth>
            <DetailedPackage />
          </RequireAuth>
        ),
      },
      {
        path: '/add-package',
        element: (
          <RequireAuth>
            <AddPackagePage />
          </RequireAuth>
        ),
      },
      {
        path: '/repurchase',
        element: (
          <RequireAuth>
            <RepurchasePage />
          </RequireAuth>
        ),
      },
    ],
  },
  {
    path: '/detailed-information',
    element: (
      <RequireToken>
        <DetailedInformationPage />
      </RequireToken>
    ),
  },
  {
    path: '/confirm-phone',
    element: (
      <RequireToken>
        <ConfirmPhonePage />
      </RequireToken>
    ),
  },
];
