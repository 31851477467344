import styled from 'styled-components';

export const StatsWrapper = styled.div`
  margin-bottom: 24px;
  .GridItem:first-child {
    .Card {
      background: var(--color-bg-brand);
      .Text {
        color: var(--color-bg-secondary);
      }
    }
  }
  .Card {
    padding-top: 12px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 12px;
    background: var(--color-bg-default);
    min-height: 180px;
    display: flex;
    flex-direction: column;
    .text: last-child {
      margin-top: auto;
    }
  }
  .Text:last-child {
    margin-top: auto;
  }
`;

export const CountryStatWrapper = styled.div`
  margin-bottom: 12px;
  .GridItem {
    align-items: center;
    display: flex;
  }
  .GridItem: last-child {
    margin-left: 24px;
  }
`;
