import React, { useEffect, useState } from 'react';
import { Text } from '@consta/uikit/Text';
import { Button } from '@consta/uikit/Button';
import { Grid, GridItem } from '@consta/uikit/Grid';
import { Card } from '@consta/uikit/Card';
import { DatePickerPropValue } from '@consta/uikit/DatePicker';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';
import { useStocksListQuery } from 'features/main/api/stocksList.generated';
import { usePackagesStatQuery } from 'features/analytics/api/packagesStat.generated';
import { usePackagesStatByDatesQuery } from 'features/analytics/api/packagesStatByDates.generated';
import { DeliveryStatus, PackagesSummaryType } from 'types';
import AnalyticsStatCard from 'features/analytics-stat-card';
import { DateRangePicker } from 'entities/stats/date-range-picker';
import Row from 'shared/ui/components/row';
import Margin from 'shared/ui/components/margin';
import { statusesLabels } from 'shared/constants/statuses.constants';
import ProgressChart from 'shared/ui/components/progressChart';
import {
  AnalyticsStatsWrapper,
  GraphWrapper,
  StatusStatWrapper,
} from './styled';

const Analytics = () => {
  const currentDate = new Date();
  const monthAgo = currentDate.setMonth(currentDate.getMonth() - 1);
  const [dateRange, setDateRange] = useState<[Date?, Date?] | null>([
    new Date(monthAgo),
    new Date(),
  ]);
  const [totalWeightInTransit, setTotalWeightInTransit] = useState<
    string | number
  >(0);
  const [totalPackagesInAnyStatus, setTotalPackagesInAnyStatus] = useState<
    string | number
  >(0);
  const [totalWeightInPeriod, setTotalWeightInPeriod] = useState<
    string | number
  >(0);
  const [activeBuyouts, setActiveBuyouts] = useState<string | number>(0);
  const [statusSummary, setStatusSummary] = useState<PackagesSummaryType[]>([]);
  const [weightByDates, setWeightByDates] = useState<
    { amt: string; kg: number; dateValue: string }[]
  >([]);

  const [activeTab, setActiveTab] = useState<number | undefined | null>(null);
  const { data: stockListData } = useStocksListQuery();

  const [startDate, endDate] = dateRange || [];

  const { data: packagesStatData } = usePackagesStatQuery({
    skip: !(typeof activeTab === 'number' || activeTab === undefined),
    variables: {
      filter: {
        from: startDate,
        to: endDate,
        stockId: activeTab,
      },
    },
  });

  const { data: packagesStatByDateData, refetch: refetchPackagesStatByDate } =
    usePackagesStatByDatesQuery({
      skip: !(typeof activeTab === 'number' || activeTab === undefined),
      variables: {
        filter: {
          from: startDate,
          to: endDate,
          stockId: activeTab,
        },
      },
    });

  const stocksList = [
    ...(stockListData?.stocksList || []),
    {
      id: undefined,
      code: null,
      name: 'Все направления',
    },
  ];

  useEffect(() => {
    if (stockListData) {
      if (typeof activeTab !== 'number' && activeTab !== undefined) {
        setActiveTab(stockListData.stocksList?.[0]?.id);
      }
    }
  }, [stockListData, activeTab]);

  useEffect(() => {
    if (packagesStatData?.packagesStat) {
      setActiveBuyouts(packagesStatData.packagesStat?.activeBuyouts);
      setTotalWeightInTransit(
        packagesStatData.packagesStat?.totalWeightInTransit,
      );
      setStatusSummary(packagesStatData.packagesStat?.summary || []);
    }
  }, [packagesStatData]);

  useEffect(() => {
    if (packagesStatByDateData?.packagesStat) {
      const totalWeight =
        packagesStatByDateData.packagesStat?.weightByDates?.reduce(
          (acc, item) => acc + item.weight,
          0,
        );
      setTotalWeightInPeriod(Math.round(totalWeight * 100) / 100);
      const parsedData =
        packagesStatByDateData.packagesStat?.weightByDates?.map((item) => ({
          amt: item.date,
          dateValue: item.date,
          kg: item.weight,
        }));

      setWeightByDates(parsedData);
    }
  }, [packagesStatByDateData]);

  useEffect(() => {
    const total = statusSummary?.reduce((acc, item) => acc + item.count, 0);
    setTotalPackagesInAnyStatus(total);
  }, [statusSummary]);

  const onUpdateSignUpDateRange = async (
    dates: DatePickerPropValue<'date-range'>,
  ) => {
    if (!dates?.length) return;

    setDateRange(dates);

    refetchPackagesStatByDate({
      filter: {
        from: dateRange?.[0]?.toISOString(),
        to: dateRange?.[1]?.toISOString(),
        stockId: activeTab,
      },
    });
  };

  const onChangeActiveTab = (id: number | undefined) => {
    setDateRange([new Date(monthAgo), new Date()]);
    setActiveTab(id);
  };

  const renderStatusStats = () => {
    return Object.keys(statusesLabels).map((status) => {
      return (
        <StatusStatWrapper key={status}>
          <Grid cols={3} rowGap='xl'>
            <GridItem col={1}>
              {statusesLabels[status as DeliveryStatus]}
            </GridItem>
            <GridItem col={1}>
              {
                <ProgressChart
                  total={Number(totalPackagesInAnyStatus)}
                  value={
                    statusSummary?.find((s) => s.status === status)?.count || 0
                  }
                />
              }
            </GridItem>
            <GridItem col={1}>
              {statusSummary?.find((s) => s.status === status)?.count || 0}
            </GridItem>
          </Grid>
        </StatusStatWrapper>
      );
    });
  };
  return (
    <>
      <Text size='4xl' view='primary' weight='medium'>
        Аналитика
      </Text>
      <Margin mt={16} mb={32}>
        {stocksList && (
          <Row justify='flex-start' align='center' className='stocksList'>
            {stocksList.map((item, index) => (
              <Margin
                key={`${item.id}_${index}`}
                mr={8}
                mb={8}
                style={{ width: 'unset' }}
              >
                <Button
                  view={item.id === activeTab ? 'primary' : 'ghost'}
                  label={item.name}
                  className={item.id === activeTab ? 'buttonBlack' : undefined}
                  size='s'
                  form='round'
                  onClick={() => onChangeActiveTab(item.id)}
                />
              </Margin>
            ))}
          </Row>
        )}
      </Margin>
      <Grid cols={12} colGap={'xl'}>
        <GridItem col={3}>
          <AnalyticsStatsWrapper>
            <Card>
              <Text size='xl' view='primary' weight='semibold'>
                Посылки на разных этапах
              </Text>
              <Margin mt={12} mb={12}>
                <Text size='s' view='primary'>
                  Всего в обработке: {totalPackagesInAnyStatus}
                </Text>
              </Margin>
              <Text size='s' view='primary'>
                {renderStatusStats()}
              </Text>
            </Card>
            <Margin mt={24} mb={24} />
            <Card>
              <AnalyticsStatCard
                title={'Всего килограмм в пути'}
                statValue={totalWeightInTransit}
              />
            </Card>
            <Margin mt={24} mb={24} />
            <Card>
              <AnalyticsStatCard
                title={'Активных заявок на выкуп товара'}
                statValue={activeBuyouts}
              />
            </Card>
          </AnalyticsStatsWrapper>
        </GridItem>
        <GridItem col={9}>
          <GraphWrapper>
            <Card>
              <Row justify='space-between' align='center'>
                <Text size='xl' view='primary' weight='semibold'>
                  Данные по посылкам
                </Text>
                {DateRangePicker({
                  range: dateRange,
                  setValue: onUpdateSignUpDateRange,
                })}
              </Row>
              <Text size='4xl' view='primary' weight='semibold'>
                {totalWeightInPeriod} кг
              </Text>
              <Text size='xs' view='primary'>
                Всего доставлено за период
              </Text>
              <Margin mt={24} mb={24} />
              {weightByDates?.length && (
                <ResponsiveContainer width='100%' height='100%'>
                  <LineChart
                    width={500}
                    height={300}
                    data={weightByDates}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid />
                    <XAxis dataKey='dateValue' />
                    <YAxis unit={' кг'} />
                    <Tooltip
                      itemStyle={{ color: 'var(--color-typo-default)' }}
                    />
                    <Line
                      type='monotone'
                      dataKey='kg'
                      stroke='var(--color-typo-brand)'
                    />
                  </LineChart>
                </ResponsiveContainer>
              )}
            </Card>
          </GraphWrapper>
        </GridItem>
      </Grid>
    </>
  );
};

export default Analytics;
