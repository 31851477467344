import parsePhoneNumber from 'libphonenumber-js';

export const formatPhoneNumber = (phone: string) => {
  const parsed = parsePhoneNumber(`+${phone}`);

  return parsed ? parsed.formatInternational() : phone;
};

export const getPhoneAndPrefix = (phone: string) => {
  const parsed = parsePhoneNumber(`+${phone}`);

  return parsed
    ? {
        phone: parsed.nationalNumber,
        prefix: parsed.countryCallingCode,
      }
    : {
        phone,
        prefix: '',
      };
};
