import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SendResetCodeMutationVariables = Types.Exact<{
  input: Types.RequestResetCodeInput;
}>;


export type SendResetCodeMutation = { __typename?: 'Mutation', sendResetCode: boolean };


export const SendResetCodeDocument = gql`
    mutation sendResetCode($input: RequestResetCodeInput!) {
  sendResetCode(input: $input)
}
    `;
export type SendResetCodeMutationFn = Apollo.MutationFunction<SendResetCodeMutation, SendResetCodeMutationVariables>;

/**
 * __useSendResetCodeMutation__
 *
 * To run a mutation, you first call `useSendResetCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendResetCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendResetCodeMutation, { data, loading, error }] = useSendResetCodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendResetCodeMutation(baseOptions?: Apollo.MutationHookOptions<SendResetCodeMutation, SendResetCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendResetCodeMutation, SendResetCodeMutationVariables>(SendResetCodeDocument, options);
      }
export type SendResetCodeMutationHookResult = ReturnType<typeof useSendResetCodeMutation>;
export type SendResetCodeMutationResult = Apollo.MutationResult<SendResetCodeMutation>;
export type SendResetCodeMutationOptions = Apollo.BaseMutationOptions<SendResetCodeMutation, SendResetCodeMutationVariables>;