import styled from 'styled-components';

export const AddressesWrapper = styled.div`
  .Card {
    border-radius: 16px;
    height: 100%;
  }
`;

export const AddressHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  justify-content: space-between;
`;

export const EmptyAddressesContainer = styled.div`
  .Card {
    width: 100%;
    background: var(--color-bg-default);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 60px;

    .Text {
      max-width: 600px;
      margin-bottom: 32px;
    }

    @media (max-width: 800px) {
      padding-left: 10px !important;
      padding-right: 10px !important;
      .Text {
        max-width: 600px;
        margin-bottom: 32px;
        text-align: center;
      }
    }
  }
`;

export const AddressContainer = styled.div`
  .Card {
    width: 100%;
    background: var(--color-bg-default);
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    .Button {
      margin-left: 12px;
    }
    @media (max-width: 500px) {
      display: block;
      .Button {
        margin-top: 8px;
      }
    }
  }
`;
