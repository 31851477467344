import styled from 'styled-components';

const Margin = styled.div<{mt?: number, mb?: number, mr?: number, ml?: number}>`
  margin-top: ${({ mt }) => `${mt || 0}px`};
  margin-right: ${({ mr }) => `${mr || 0}px`};
  margin-bottom: ${({ mb }) => `${mb || 0}px`};
  margin-left: ${({ ml }) => `${ml || 0}px`};
  width: 100%;
`;

export default Margin;