import { Grid, GridItem } from '@consta/uikit/Grid';
import { Text } from '@consta/uikit/Text';
import { AnalyticsStatCardWrapper } from './styled';

const AnalyticsStatCard = ({
  statValue,
  title,
}: {
  statValue: number | string;
  title: string;
}) => {
  return (
    <AnalyticsStatCardWrapper>
      <Grid cols={5}>
        <GridItem col={2}>
          <Text size='xl' view='primary' weight='semibold'>
            {title}
          </Text>
        </GridItem>
        <GridItem col={2}>
          <Text size='3xl' view='primary' weight='bold'>
            {statValue}
          </Text>
        </GridItem>
      </Grid>
    </AnalyticsStatCardWrapper>
  );
};

export default AnalyticsStatCard;
