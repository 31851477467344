import React, { FC, useEffect, useState } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { Text } from '@consta/uikit/Text';
import { Button } from '@consta/uikit/Button';
import { TextField } from '@consta/uikit/TextField';
import { Card } from '@consta/uikit/Card';
import { IconSearch } from '@consta/uikit/IconSearch';
import { Checkbox } from '@consta/uikit/Checkbox';
import { Grid, GridItem } from '@consta/uikit/Grid';
import { StoreStatus, UserCountry } from 'types';
import { useShipmentStatsQuery } from 'features/shipment-stats/api/shipmentStats.generated';
import { useShipmentsListQuery } from 'features/shipments/api/shipmentsList.generated';
import { useAdminPackagesListQuery } from 'features/packages/api/adminPackagesList.generated';
import { useAdminUpdatePackageMutation } from 'features/packages/api/adminUpdatePackage.generated';
import { showErrorNotification } from 'features/notifications/ui/showErrorNotification';
import InfoField from 'features/shipment-stats/infoField';
import Scanner from 'features/scanner';
import { FlagWrapper } from 'features/country-label/styled';
import Flag from 'entities/countries';
import Margin from 'shared/ui/components/margin';
import Row from 'shared/ui/components/row';
import { ScannerCardWrapper } from './styled';
import { AdminPackageType } from './types';

const StoreKeeperScanner: FC = () => {
  const { id } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    for (const entry of searchParams.entries()) {
      switch (entry[0]) {
        case 'track':
          setPackageTrack(entry[1]);
          break;
        default:
          break;
      }
    }
  }, []);

  const [packageTrack, setPackageTrack] = useState<string | undefined>();
  const [q, setQ] = useState<string | null>(null);
  const [packageData, setPackageData] = useState<AdminPackageType | undefined>();

  const [comment, setComment] = useState<string | null>(null);
  const [isDamaged, setIsDamaged] = useState(false);

  const { data: shipments } = useShipmentsListQuery({
    variables: {
      filter: {},
    },
  });

  const { data: stats, refetch } = useShipmentStatsQuery({
    fetchPolicy: 'no-cache',
    skip: !id,
    variables: {
      id: +id!,
    },
  });

  const { data } = useAdminPackagesListQuery({
    skip: !packageTrack,
    variables: {
      filter: {
        track: packageTrack,
        shipmentId: +id!,
      },
      pagination: {
        limit: 1,
      },
    },
  });

  useEffect(() => {
    searchParams.delete('track');
    setSearchParams(searchParams);
    if (data?.packagesList.items.length) {
      setCamera(false);
      setPackageData(data?.packagesList.items[0]);
      setComment(data?.packagesList.items[0]?.comment || null);
      setIsDamaged(data?.packagesList.items[0]?.isDamaged || false);
    } else {
      if (packageTrack) {
        setPackageData(undefined);
        setComment(null);
        setIsDamaged(false);
        showErrorNotification('Не найдено');
      }
    }
  }, [data])

  const navigate = useNavigate();

  const [camera, setCamera] = useState(false);

  const onDetected = (result: string) => {
    setPackageTrack(result);
  };

  const [updatePackage] = useAdminUpdatePackageMutation();

  const onFinish = () => {
    if (packageData) {
      updatePackage({
        variables: {
          id: packageData.id,
          input: {
            comment,
            isDamaged,
            storeStatus: StoreStatus.Checked,
          },
        },
      }).then(() => {
        setPackageData(undefined);
        refetch();
      })
    }
  };

  const isMobile = window.innerWidth < 640;
  const isTablet = window.innerWidth < 1100 && window.innerWidth >= 640;
  const smallScreen = isMobile || isTablet;

  return (
    <>
      <Margin mb={32}>
        <Row
          direction={isMobile ? 'column' : 'row'}
          justify={isMobile ? 'flex-start' : 'space-between'}
          align={isMobile ? 'flex-start' : 'center'}
        >
          <Text size={smallScreen ? isMobile ? '2xl' : '3xl' : '4xl'} view='primary' weight='medium'>
            {shipments?.shipmentsList?.find(item => item.id === +id!)?.name || ''}
          </Text>
          <Button
            label='Выйти из режима сканирования'
            size='s'
            view='primary'
            form='round'
            width={isMobile ? 'full' : undefined}
            style={{ marginTop: isMobile ? '15px' : '0' }}
            className='buttonBlack'
            onClick={() => navigate('/store-keeper-packages')}
          />
        </Row>
      </Margin>
      <Margin mb={40}>
        {stats && <Row
          direction={isMobile ? 'column' : 'row'}
          justify='flex-start'
          align='flex-start'
          style={{ width: smallScreen ? '100%' : undefined }}
        >
          <Margin
            mr={isMobile ? 0 : 24}
            mb={isMobile ? 24 : 0}
            style={{width: isTablet ? 'calc(50% - 12px)' : 'unset'}}
          >
            <InfoField
              label='Количество посылок, которое вылетело'
              value={`${stats.shipmentStats.reduce((acc, item) => acc + item.count, 0)}`}
            />
          </Margin>
          <InfoField
            label='Количество принятых посылок'
            value={`${stats.shipmentStats.find(item => item.status === StoreStatus.Checked)?.count}`}
          />
        </Row> || null}
      </Margin>
      <Margin mb={24}>
        <Button
          label={camera ? 'Выйти из режима сканирования' : 'Сканировать посылку'}
          view='ghost'
          form='round'
          width='full'
          size='m'
          iconLeft={IconSearch}
          style={{width: '300px', marginLeft: 'calc(50% - 150px)'}}
          onClick={() => {
            setPackageData(undefined);
            setCamera(!camera);
          }}
        />
      </Margin>
      <ScannerCardWrapper>
        <Card
          verticalSpace={isMobile ? 'm' : 'l'}
          horizontalSpace={isMobile ? 's' : 'l'}
          shadow={false}
        >
          {packageData ? <Grid cols={isMobile ? 1 : 2} colGap='l'>
            <GridItem>
              <Margin mb={32}>
                <InfoField label='Трек-номер' value={packageData?.track || ''} />
              </Margin>
              <Margin mb={32}>
                <InfoField label='Получатель' value={packageData?.user?.name || ''} />
              </Margin>
              <Margin mb={32}>
                <Grid cols={2}>
                  <GridItem>
                    <InfoField label='Вес' value={`${packageData?.weight} кг`} />
                  </GridItem>
                  <GridItem>
                    <InfoField label='Длина' value={`${packageData?.size?.length} м`} />
                  </GridItem>
                </Grid>
              </Margin>
              <Margin mb={32}>
                <Grid cols={2}>
                  <GridItem>
                    <InfoField label='Ширина' value={`${packageData?.size?.width} м`} />
                  </GridItem>
                  <GridItem>
                    <InfoField label='Высота' value={`${packageData?.size?.height} м`} />
                  </GridItem>
                </Grid>
              </Margin>
              <Margin mb={32}>
                <Grid cols={2}>
                  <GridItem>
                    <InfoField label='Количество мест' value={packageData?.packageCount?.toString() || ''} />
                  </GridItem>
                  <GridItem>
                    <InfoField label='Номер коробки' value={packageData?.boxNumber?.toString() || ''} />
                  </GridItem>
                </Grid>
              </Margin>
            </GridItem>
            <GridItem>
              <Margin mb={8}>
                <Text
                  size='xs'
                  view='secondary'
                  style={{whiteSpace: 'nowrap'}}
                >
                  Комментарий
                </Text>
              </Margin>
              <Margin mb={32}>
                <TextField
                  type='text'
                  placeholder='Посылка повреждена'
                  form='round'
                  width='full'
                  size='s'
                  value={comment}
                  onChange={({ value }) => setComment(value)}
                />
              </Margin>
              <Margin mb={32}>
                <Checkbox
                  checked={isDamaged}
                  onChange={({ checked }) => setIsDamaged(checked)}
                  size='m'
                  label='С посылкой что-то не так'
                />
              </Margin>
              <FlagWrapper className='flagWrapper'>
                <Flag
                  variant={
                    packageData?.deliveryAddress?.country ||
                    packageData?.pickupPoint?.country ||
                    UserCountry.Kz
                  }
                />
              </FlagWrapper>
              <Button
                className='finishButton buttonBlack'
                view='primary'
                label='Отсортировано'
                form='round'
                onClick={onFinish}
              />
            </GridItem>
          </Grid> : <>
            {!camera && <div className='emptyLabel'>
              <Margin mb={15}>
                <Text size={smallScreen ? isMobile ? 'xl' : '2xl' : '3xl'} weight='medium' align='center'>
                  Отсканируйте посылку или введите трек-номер вручную
                </Text>
              </Margin>
              <Row justify='center' align='center'>
                <TextField
                  type='text'
                  placeholder='Трек-номер посылки'
                  form='round'
                  style={{width: isMobile ? 'calc(100% - 42px)' : '270px'}}
                  size='s'
                  value={q}
                  leftSide={IconSearch}
                  onChange={({ value }) => setQ(value)}
                />
                <Margin ml={10} style={{width: 'unset'}}>
                  <Button
                    onlyIcon
                    iconLeft={IconSearch}
                    className='buttonBlack'
                    size='s'
                    form='round'
                    onClick={() => setPackageTrack(q || undefined)}
                  />
                </Margin>
              </Row>
            </div>}
          </>}
          {camera && <div className='container'>
            <Scanner onDetected={onDetected} />
          </div>}
        </Card>
      </ScannerCardWrapper>
    </>
  );
};

export default StoreKeeperScanner;