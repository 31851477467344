import React, { FC } from 'react';
import { Text } from '@consta/uikit/Text';
import Margin from 'shared/ui/components/margin';

interface IProps {
  label: string,
  value: string,
};

const InfoField: FC<IProps> = ({ label, value }: IProps) => {
  return (
    <div>
      <Margin mb={8}>
        <Text
          size='xs'
          view='secondary'
          style={{whiteSpace: 'nowrap'}}
        >
          {label}
        </Text>
      </Margin>
      <Text size='s' view='primary' align='left'>
        {value}
      </Text>
    </div>
  );
};

export default InfoField;