import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PackagesSummaryQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type PackagesSummaryQuery = { __typename?: 'Query', packagesSummary: Array<{ __typename?: 'PackagesSummaryType', status: Types.DeliveryStatus, count: number }> };


export const PackagesSummaryDocument = gql`
    query packagesSummary {
  packagesSummary {
    status
    count
  }
}
    `;

/**
 * __usePackagesSummaryQuery__
 *
 * To run a query within a React component, call `usePackagesSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `usePackagesSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePackagesSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function usePackagesSummaryQuery(baseOptions?: Apollo.QueryHookOptions<PackagesSummaryQuery, PackagesSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PackagesSummaryQuery, PackagesSummaryQueryVariables>(PackagesSummaryDocument, options);
      }
export function usePackagesSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PackagesSummaryQuery, PackagesSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PackagesSummaryQuery, PackagesSummaryQueryVariables>(PackagesSummaryDocument, options);
        }
export type PackagesSummaryQueryHookResult = ReturnType<typeof usePackagesSummaryQuery>;
export type PackagesSummaryLazyQueryHookResult = ReturnType<typeof usePackagesSummaryLazyQuery>;
export type PackagesSummaryQueryResult = Apollo.QueryResult<PackagesSummaryQuery, PackagesSummaryQueryVariables>;