import React, { FC, useState, useEffect, useRef } from 'react';
import { Text } from '@consta/uikit/Text';
import { Button } from '@consta/uikit/Button';
import { TextField } from '@consta/uikit/TextField';
import { IconSettings } from '@consta/uikit/IconSettings';
import { Tabs } from '@consta/uikit/Tabs';
import { Card } from '@consta/uikit/Card';
import { Table } from '@consta/uikit/Table';
import { Select } from '@consta/uikit/Select';
import { IconSearch } from '@consta/uikit/IconSearch'
import { BuyoutStatus, BuyoutStrategy, BuyoutType } from 'types';
import { EmptyContainer, TableContainer } from 'pages/packages/styled';
import { useBuyoutsListQuery } from 'features/admin-repurchase/api/buyoutsList.generated';
import { useUpdateBuyoutMutation } from 'features/admin-repurchase/api/updateBuyout.generated';
import FieldsModal from 'features/admin-repurchase/fields-modal';
import Margin from 'shared/ui/components/margin';
import Row from 'shared/ui/components/row';
import { useIsInViewport } from 'shared/hooks/useIsInViewport';
import { BuyoutRow, BuyoutStatusesTab, ColumnType, ColumnHiddenType } from './types';

const statusesTabs: BuyoutStatusesTab[] = [
  {
    label: 'Входящие',
    value: BuyoutStatus.Created,
  },
  {
    label: 'В работе',
    value: BuyoutStatus.InProgress,
  },
  {
    label: 'Архив',
    value: BuyoutStatus.Done,
  },
];

const strategyTexts: Record<BuyoutStrategy, string> = {
  [BuyoutStrategy.StatedPrice]: 'Выкупить товар только по указанной цене',
  [BuyoutStrategy.UpTo_10]: 'Выкупить товар при увеличении цены до 10%',
  [BuyoutStrategy.UpTo_20]: 'Выкупить товар при увеличении цены до 20%',
  [BuyoutStrategy.UpTo_30]: 'Выкупить товар при увеличении цены до 30%',
  [BuyoutStrategy.AnyPrice]: 'Выкупить товар в любом случае',
};

const PackagesPage: FC = () => {
  const pageSize = 40;
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);

  const [buyouts, setBuyouts] = useState<BuyoutRow[]>([]);
  const [buyoutStatus, setBuyoutStatus] = useState<BuyoutStatus>(BuyoutStatus.Created);
  const [q, setQ] = useState<string | undefined>();
  const [debouncedQ, setDebouncedQ] = useState<string | undefined>();

  const [showModal, setShowModal] = useState(false);

  const footerRef = useRef(document.getElementById('footer'));
  const inViewport = useIsInViewport(footerRef);

  const { data, refetch } = useBuyoutsListQuery({
    fetchPolicy: 'no-cache',
    variables: {
      filter: {
        status: buyoutStatus,
        q: debouncedQ || undefined,
      },
      pagination: {
        offset: offset * pageSize,
        limit: pageSize,
      },
    },
  });

  useEffect(() => {
    const items = data?.buyoutsList?.items;

    if (items) {
      setRowsFromBuyouts([...items] as BuyoutType[]);
      if (!offset) {
        setTotal(data?.buyoutsList?.total);
      }
    } else {
      setRowsFromBuyouts([]);
      setTotal(0);
    }

  }, [data]);

  useEffect(() => {
    if (inViewport && ((offset + 1) * pageSize <= total)) {
      setOffset(offset + 1);
    }
  }, [inViewport]);

  const setRowsFromBuyouts = (items: BuyoutType[]) => {
    const buyoutsWithRows: BuyoutRow[] = items.map(({
      id, status, stock, strategy, summary, user,
    }) => ({
      id: id.toString(),
      status,
      stock: stock.name,
      clientId: user.id,
      clientPhone: user.phone || '',
      link: summary.link,
      name: summary.name,
      description: summary.description,
      count: summary.count,
      cost: summary.cost,
      strategy: strategyTexts[strategy],
    }));

    if (offset) {
      setBuyouts([...buyouts, ...buyoutsWithRows]);
    } else {
      setBuyouts(buyoutsWithRows);
    }
  };

  useEffect(() => {
    setOffset(0);
  }, [buyoutStatus]);

  const [updateBuyout] = useUpdateBuyoutMutation();

  const onChangeStatus = (id: string, value: BuyoutStatusesTab) => {
    updateBuyout({
      variables: {
        id: parseInt(id),
        input: {
          status: value.value,
        },
      },
    }).then(() => refetch());
  }

  const columns: ColumnType[] = [
    {
      title: 'Статус',
      accessor: 'status',
      width: 250,
      renderCell: ({ id, status }: BuyoutRow) => (
        <Select
          form='brick'
          items={statusesTabs}
          size='s'
          value={{label: statusesTabs.find(item => item.value === status)?.label || '', value: status}}
          getItemLabel={(item: BuyoutStatusesTab) => item.label}
          getItemKey={(item) => item.value}
          onChange={({value}) => value && onChangeStatus(id, value)}
        />
      ),
    },
    {
      title: 'Склад',
      accessor: 'stock',
      width: 150,
    },
    {
      title: 'ID клиента',
      accessor: 'clientId',
      width: 150,
    },
    {
      title: 'Телефон клиента',
      accessor: 'clientPhone',
      width: 200,
    },
    {
      title: 'Ссылка на товар',
      accessor: 'link',
      width: 500,
    },
    {
      title: 'Название товара',
      accessor: 'name',
      width: 200,
    },
    {
      title: 'Цвет/Размер',
      accessor: 'description',
      width: 200,
    },
    {
      title: 'Количество шт',
      accessor: 'count',
      width: 200,
    },
    {
      title: 'Стоимость за шт',
      accessor: 'cost',
      width: 200,
    },
    {
      title: 'Если цена изменится',
      accessor: 'strategy',
      width: 250,
    },
  ];

  const [columnsState, setColumnsState] = useState<ColumnHiddenType[]>(columns.map(item => ({ ...item, hidden: false })));

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedQ(q);
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [q]);

  return (
    <>
      <Margin mb={32}>
        <Row
          direction='row'
          justify='space-between'
          align='center'
        >
          <Text size='4xl' view='primary' weight='medium'>
            Заявки на выкуп
          </Text>
          <Row
            direction='row'
            justify='flex-end'
            align='center'
          >
            {buyouts.length && <Button
              onlyIcon
              form='round'
              iconLeft={IconSettings}
              size='s'
              view='ghost'
              onClick={() => setShowModal(true)}
            /> || null}
            <Margin
              ml={24}
              style={{width: '270px'}}
            >
              <TextField
                type='text'
                placeholder='Поиск заявки'
                form='round'
                width='full'
                size='s'
                value={q}
                leftSide={IconSearch}
                onChange={({ value }) => setQ(value || undefined)}
              />
            </Margin>
          </Row>
        </Row>
      </Margin>
      {statusesTabs.length && <Margin mb={40}>
        <Tabs
          value={statusesTabs.find(item => item.value === buyoutStatus)}
          onChange={({ value }) => setBuyoutStatus(value.value)}
          items={statusesTabs}
          size='m'
          fitMode='scroll'
        />
      </Margin> || null}
      {buyouts?.length ? <TableContainer>
        <Table
          rows={buyouts}
          // @ts-ignore
          columns={columnsState}
          defaultExpandAll={false}
        />
      </TableContainer> : <EmptyContainer>
        <Card shadow={false}>
          <Text size='3xl' weight='medium' align='center'>
            Здесь еще нет заявок на выкуп
          </Text>
        </Card>
      </EmptyContainer>}
      <FieldsModal
        isModalOpen={showModal}
        columns={columnsState}
        onClose={() => setShowModal(false)}
        onSubmit={(editedColumns) => setColumnsState(editedColumns)}
      />
    </>
  );
};

export default PackagesPage;