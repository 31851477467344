import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PackagesStatQueryVariables = Types.Exact<{
  filter: Types.PackagesStatInput;
}>;


export type PackagesStatQuery = { __typename?: 'Query', packagesStat: { __typename?: 'PackagesStatType', totalWeightInTransit: number, activeBuyouts: number, summary: Array<{ __typename?: 'PackagesSummaryType', status: Types.DeliveryStatus, count: number }> } };


export const PackagesStatDocument = gql`
    query packagesStat($filter: PackagesStatInput!) {
  packagesStat(filter: $filter) {
    totalWeightInTransit
    activeBuyouts
    summary {
      status
      count
    }
  }
}
    `;

/**
 * __usePackagesStatQuery__
 *
 * To run a query within a React component, call `usePackagesStatQuery` and pass it any options that fit your needs.
 * When your component renders, `usePackagesStatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePackagesStatQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function usePackagesStatQuery(baseOptions: Apollo.QueryHookOptions<PackagesStatQuery, PackagesStatQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PackagesStatQuery, PackagesStatQueryVariables>(PackagesStatDocument, options);
      }
export function usePackagesStatLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PackagesStatQuery, PackagesStatQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PackagesStatQuery, PackagesStatQueryVariables>(PackagesStatDocument, options);
        }
export type PackagesStatQueryHookResult = ReturnType<typeof usePackagesStatQuery>;
export type PackagesStatLazyQueryHookResult = ReturnType<typeof usePackagesStatLazyQuery>;
export type PackagesStatQueryResult = Apollo.QueryResult<PackagesStatQuery, PackagesStatQueryVariables>;