import { Text } from '@consta/uikit/Text';
import { Button } from '@consta/uikit/Button';
import { Grid, GridItem } from '@consta/uikit/Grid';
import { TextField } from '@consta/uikit/TextField';
import { useEffect, useState } from 'react';
import { Select } from '@consta/uikit/Select';
import { Checkbox } from '@consta/uikit/Checkbox';
import { AddressType, DeliveryStatus } from 'types';
import ConfirmModal from 'entities/ui/confirmModal';
import { PackageData } from 'entities/package-row/types';
import { useAdminUpdatePackageMutation } from 'features/packages/api/adminUpdatePackage.generated';
import { getInfoRows } from 'features/admin-package-row/utils';
import Margin from 'shared/ui/components/margin';
import Row from 'shared/ui/components/row';
import { InfoWithLabel } from 'shared/ui/components/infoWithLabel';
import { statusesLabels } from 'shared/constants/statuses.constants';
import { additionalCountries } from 'shared/constants/countries.constants';
import { EditClientBodyWrapper, ModalHeader } from './styled';

const PackageEditForm = ({
  packageData,
  country,
  onCloseModal,
}: {
  packageData: PackageData | null;
  country: string;
  onCloseModal: () => void;
}) => {
  const defaultStatus = {
    value: DeliveryStatus.Created,
    label: 'Ожидается',
  };
  const [formEdited, setFormEdited] = useState(false);
  const [status, setStatus] = useState(defaultStatus);
  const [weight, setWeight] = useState<number | null>();
  const [rateCost, setRateCost] = useState<number | null>();
  const [packageCount, setPackageCount] = useState<number | null>();
  const [insuranceCost, setInsuranceCost] = useState<number | null>();
  const [deliveryCost, setDeliveryCost] = useState<number | null>();
  const [comment, setComment] = useState('');
  const [deliveryAddress, setDeliveryAddress] = useState<AddressType | null>(
    null,
  );
  const [isPaid, setIsPaid] = useState(false);
  const [isStatusError, setIsStatusError] = useState(false);
  const [weightError, setWeightError] = useState(false);
  const [rateCostError, setRateCostError] = useState(false);
  const [isPackageCountError, setIsPackageCountError] = useState(false);
  const [insuranceCostError, setInsuranceCostError] = useState(false);
  const [deliveryCostError, setDeliveryCostError] = useState(false);
  const [isCommentError, setIsCommentError] = useState(false);

  const [isConfirmCloseModalOpen, setIsConfirmCloseModalOpen] = useState(false);
  const [isConfirmSaveModalOpen, setIsConfirmSaveModalOpen] = useState(false);
  const [confirmModalData, setConfirmModalData] = useState({
    title: '',
    body: '',
    submitTitle: '',
  });

  const handleStatusChange = (value: {
    value: DeliveryStatus;
    label: string;
  }) => {
    setStatus(value);
    setIsStatusError(false);
    setFormEdited(true);
  };

  const handleWeightChange = (value?: number) => {
    setWeight(value);
    setWeightError(false);
    setFormEdited(true);
  };

  const handleRateCostChange = (value?: number) => {
    setRateCost(value);
    setRateCostError(false);
    setFormEdited(true);
  };

  const handlePackageCountChange = (value?: number) => {
    setPackageCount(value);
    setIsPackageCountError(false);
    setFormEdited(true);
  };

  const handleInsuranceCostChange = (value?: number) => {
    setInsuranceCost(value);
    setInsuranceCostError(false);
    setFormEdited(true);
  };

  const handleDeliveryCostChange = (value?: number) => {
    setDeliveryCost(value);
    setDeliveryCostError(false);
    setFormEdited(true);
  };

  const handleCommentChange = (value: string) => {
    setComment(value);
    setIsCommentError(false);
    setFormEdited(true);
  };

  const handleIsPaidChange = (value: boolean) => {
    setIsPaid(value);
    setFormEdited(true);
  };

  const handleStatusBlur = () => {
    if (!status) {
      setIsStatusError(true);
    }
  };

  const handleWeightBlur = () => {
    if (!weight) {
      setWeightError(true);
    }
  };

  const handleRateCostBlur = () => {
    if (!rateCost) {
      setRateCostError(true);
    }
  };

  const handlePackageCountBlur = () => {
    if (!packageCount) {
      setIsPackageCountError(true);
    }
  };

  const handleInsuranceCostBlur = () => {
    if (!insuranceCost) {
      setInsuranceCostError(true);
    }
  };

  const handleDeliveryCostBlur = () => {
    if (!deliveryCost) {
      setDeliveryCostError(true);
    }
  };

  const handleCommentBlur = () => {
    if (!comment) {
      setIsCommentError(true);
    }
  };

  const setInitialPackageData = () => {
    setStatus({
      value: packageData?.status || DeliveryStatus.Created,
      label: statusesLabels[packageData?.status || DeliveryStatus.Created],
    });
    setWeight(packageData?.weight);
    setRateCost(packageData?.rateCost);
    setPackageCount(packageData?.packageCount);
    setInsuranceCost(packageData?.insuranceCost);
    setDeliveryCost(packageData?.deliveryCost);
    setComment(packageData?.comment || '');
    setIsPaid(packageData?.isPaid || false);
  };

  const clearData = () => {
    setStatus(defaultStatus);
    setWeight(0);
    setRateCost(0);
    setPackageCount(0);
    setInsuranceCost(0);
    setDeliveryCost(0);
    setComment('');
    setDeliveryAddress(null);
    setIsPaid(false);
  };

  const closeModal = () => {
    if (formEdited) {
      setConfirmModalData({
        title: 'Последние изменения не сохранены. Закрыть панель?',
        body: '',
        submitTitle: 'Да',
      });
      setIsConfirmCloseModalOpen(true);
    } else {
      clearData();
      onCloseModal();
    }
  };

  const onCloseConfirmModal = () => {
    setIsConfirmCloseModalOpen(false);
    setIsConfirmSaveModalOpen(false);
  };

  const onSubmitConfirmModal = () => {
    if (isConfirmSaveModalOpen) {
      onSubmit();
    }
    setIsConfirmCloseModalOpen(false);
    setIsConfirmSaveModalOpen(false);

    if (isConfirmCloseModalOpen) {
      clearData();
      onCloseModal();
    }
  };

  const showConfirmModal = () => {
    setConfirmModalData({
      title: 'Вы действительно хотите внести изменения?',
      body: '',
      submitTitle: 'Да',
    });
    setIsConfirmSaveModalOpen(true);
  };

  useEffect(() => {
    setInitialPackageData();
  }, [packageData]);

  const [updatePackage] = useAdminUpdatePackageMutation();

  const onSubmit = () => {
    updatePackage({
      variables: {
        id: Number(packageData?.id),
        input: {
          status: status.value,
          weight,
          rateCost,
          ...(country !== additionalCountries.korea ? { insuranceCost } : {}),
          ...(country === additionalCountries.korea ? { packageCount } : {}),
          deliveryCost,
          comment,
          deliveryAddressId: deliveryAddress?.id,
          isPaid,
        },
      },
    });
    setFormEdited(false);
  };

  const statuses = [
    { label: 'Ожидается', value: DeliveryStatus.Created },
    { label: 'Готово к выдаче', value: DeliveryStatus.ReadyForDelivery },
    { label: 'В пути', value: DeliveryStatus.InTransit },
    { label: 'Доставлено', value: DeliveryStatus.Done },
  ];

  return (
    <>
      <ModalHeader>
        <Text size='xl' view='primary' weight='medium'>
          Информация о посылке
        </Text>
        <Row justify='center' align='center'>
          <Button
            label='Сохранить'
            view='primary'
            className='buttonBlack'
            size='s'
            onlyIcon
            onClick={showConfirmModal}
          />
          <Margin ml={8}>
            <Button
              label='Закрыть'
              view='ghost'
              size='s'
              onlyIcon
              onClick={() => closeModal()}
            />
          </Margin>
        </Row>
      </ModalHeader>
      <EditClientBodyWrapper>
        <Grid cols={2}>
          <GridItem col={1}>
            <Select
              label='Статус'
              placeholder='Выберите варинат'
              items={statuses}
              form='clearClear'
              size='m'
              value={status}
              getItemLabel={(item: { value: DeliveryStatus; label: string }) =>
                item.label || ''
              }
              getItemKey={(item: { value: DeliveryStatus; label: string }) =>
                item.value
              }
              status={isStatusError ? 'alert' : undefined}
              caption={isStatusError ? 'Поле не заполнено' : undefined}
              onBlur={handleStatusBlur}
              onChange={({ value }) => value && handleStatusChange(value)}
            />
            <TextField
              label='ВЕС КГ'
              type='number'
              placeholder='Ввведите вес'
              form='clearClear'
              width='full'
              size='m'
              incrementButtons={false}
              value={weight && isNaN(weight) ? '' : `${weight}`}
              onChange={({ value }) => handleWeightChange(Number(value) || undefined)}
              onBlur={handleWeightBlur}
              status={weightError ? 'alert' : undefined}
              caption={weightError ? 'Поле не заполнено' : undefined}
            />
            <TextField
              label='ТАРИФ $'
              type='number'
              placeholder='Ввведите тариф'
              form='clearClear'
              width='full'
              size='m'
              incrementButtons={false}
              value={rateCost && isNaN(rateCost) ? '' : `${rateCost}`}
              onChange={({ value }) => handleRateCostChange(Number(value) || undefined)}
              onBlur={handleRateCostBlur}
              status={rateCostError ? 'alert' : undefined}
              caption={rateCostError ? 'Поле не заполнено' : undefined}
            />
            {country !== additionalCountries.korea ? (
              <TextField
                label='СТРАХОВКА $'
                type='number'
                placeholder='Ввведите значение'
                form='clearClear'
                width='full'
                size='m'
                incrementButtons={false}
                value={
                  insuranceCost && isNaN(insuranceCost)
                    ? ''
                    : `${insuranceCost}`
                }
                onChange={({ value }) =>
                  handleInsuranceCostChange(Number(value) || undefined)
                }
                onBlur={handleInsuranceCostBlur}
                status={insuranceCostError ? 'alert' : undefined}
                caption={insuranceCostError ? 'Поле не заполнено' : undefined}
              />
            ) : (
              <TextField
                label='КОЛИЧЕСТВО МЕСТ'
                type='number'
                placeholder='Ввведите значение'
                form='clearClear'
                width='full'
                size='m'
                incrementButtons={false}
                value={
                  packageCount && isNaN(packageCount) ? '' : `${packageCount}`
                }
                onChange={({ value }) =>
                  handlePackageCountChange(Number(value) || undefined)
                }
                onBlur={handlePackageCountBlur}
                status={isPackageCountError ? 'alert' : undefined}
                caption={isPackageCountError ? 'Поле не заполнено' : undefined}
              />
            )}
            <TextField
              label='СТОИМОСТЬ ДОСТАВКИ $'
              type='number'
              placeholder='Ввведите значение'
              form='clearClear'
              width='full'
              size='m'
              incrementButtons={false}
              value={
                deliveryCost && isNaN(deliveryCost) ? '' : `${deliveryCost}`
              }
              onChange={({ value }) =>
                handleDeliveryCostChange(Number(value) || undefined)
              }
              onBlur={handleDeliveryCostBlur}
              status={deliveryCostError ? 'alert' : undefined}
              caption={deliveryCostError ? 'Поле не заполнено' : undefined}
            />
            <TextField
              label='КОММЕНТАРИЙ СКЛАДА'
              type='text'
              placeholder='Ввведите значение'
              form='clearClear'
              width='full'
              size='m'
              incrementButtons={false}
              value={comment}
              onChange={({ value }) => handleCommentChange(value || '')}
              onBlur={handleCommentBlur}
              status={isCommentError ? 'alert' : undefined}
              caption={isCommentError ? 'Поле не заполнено' : undefined}
            />
            {/* <Select
              label='Адрес доставки'
              placeholder='Выберите из сохраненных адресов'
              items={addresses}
              form='clearClear'
              size='m'
              value={deliveryAddress}
              getItemLabel={(item) =>
                `${countriesMap[item.country]}, г. ${item.address.city}, ул.${
                  item.address.street
                } ${item.address.home}, кв. ${item.address.flat}`
              }
              getItemKey={(item) => item.id}
              onChange={({ value }) => handleDeliveryAddressChange(value)}
              status={deliveryAddressError ? 'alert' : undefined}
              caption={deliveryAddressError ? 'Поле не заполнено' : undefined}
              onBlur={handleDeliveryAddressBlur}
              renderItem={({
                item,
                active,
                hovered,
                onClick,
                onMouseEnter,
              }) => (
                <CountryLabel
                  item={{
                    label: `${countriesMap[item.country]}, г. ${
                      item.address.city
                    }, ул.${item.address.street} ${item.address.home}, кв. ${
                      item.address.flat
                    }`,
                    value: item.country,
                  }}
                  active={active}
                  hovered={hovered}
                  onClick={onClick}
                  onMouseEnter={onMouseEnter}
                />
              )}
            /> */}
            <Checkbox
              label='ОПЛАЧЕНО'
              checked={isPaid || false}
              align='center'
              view='primary'
              onChange={({ checked }) => handleIsPaidChange(checked)}
            />
          </GridItem>
          <GridItem col={1}>
            {getInfoRows(
              packageData,
              country === additionalCountries.korea,
            ).map((row, index) => {
              return (
                <InfoWithLabel
                  key={index}
                  title={row.title}
                  value={row.value}
                />
              );
            })}
          </GridItem>
        </Grid>
      </EditClientBodyWrapper>
      <ConfirmModal
        isModalOpen={isConfirmCloseModalOpen || isConfirmSaveModalOpen}
        title={confirmModalData.title}
        body={confirmModalData.body}
        submitTitle={confirmModalData.submitTitle}
        onClose={onCloseConfirmModal}
        onSubmit={onSubmitConfirmModal}
        closeButtonTitle='Отмена'
        customMaxWidth={600}
      />
    </>
  );
};

export default PackageEditForm;
