import styled from 'styled-components';

export const BottomFieldContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 40px;
  width: calc(100% - 80px);
  height: 100px;
  background: var(--color-bg-secondary);

  & > div {
    background: var(--color-bg-default);
    height: 60px;
    border-radius: 30px;
    padding: 0 10px;
  }
`;