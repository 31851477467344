import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BuyoutsListQueryVariables = Types.Exact<{
  filter: Types.BuyoutsFilterInput;
  pagination: Types.BuyoutsPaginationInput;
}>;


export type BuyoutsListQuery = { __typename?: 'Query', buyoutsList: { __typename?: 'PaginatedBuyoutsType', total: number, items: Array<{ __typename?: 'BuyoutType', id: number, status: Types.BuyoutStatus, strategy: Types.BuyoutStrategy, stock: { __typename?: 'StockType', id: number, name: string, code: Types.StockCode, address: { __typename?: 'StockAddressType', addressFirst: string, city: string, country: string, zipCode: string } }, summary: { __typename?: 'BuyoutSummaryType', cost: number, count: number, description: string, link: string, name: string }, user: { __typename?: 'UserType', createdAt: any, email: string, id: number, phone?: string | null } }> } };


export const BuyoutsListDocument = gql`
    query buyoutsList($filter: BuyoutsFilterInput!, $pagination: BuyoutsPaginationInput!) {
  buyoutsList(filter: $filter, pagination: $pagination) {
    items {
      id
      stock {
        id
        name
        code
        address {
          addressFirst
          city
          country
          zipCode
        }
      }
      status
      strategy
      summary {
        cost
        count
        description
        link
        name
      }
      user {
        createdAt
        email
        id
        phone
      }
    }
    total
  }
}
    `;

/**
 * __useBuyoutsListQuery__
 *
 * To run a query within a React component, call `useBuyoutsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useBuyoutsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBuyoutsListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useBuyoutsListQuery(baseOptions: Apollo.QueryHookOptions<BuyoutsListQuery, BuyoutsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BuyoutsListQuery, BuyoutsListQueryVariables>(BuyoutsListDocument, options);
      }
export function useBuyoutsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BuyoutsListQuery, BuyoutsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BuyoutsListQuery, BuyoutsListQueryVariables>(BuyoutsListDocument, options);
        }
export type BuyoutsListQueryHookResult = ReturnType<typeof useBuyoutsListQuery>;
export type BuyoutsListLazyQueryHookResult = ReturnType<typeof useBuyoutsListLazyQuery>;
export type BuyoutsListQueryResult = Apollo.QueryResult<BuyoutsListQuery, BuyoutsListQueryVariables>;