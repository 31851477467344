import React from 'react';

const ProgressChart = ({ total, value }: { total: number; value: number }) => {
  const ratio = value / total;
  const color = 'var(--color-bg-border)';
  const width = `${ratio * 100}%`;

  return (
    <div
      style={{
        backgroundColor: color,
        width: '100%',
        height: '10px',
        borderRadius: '9px',
      }}
    >
      <div
        style={{
          backgroundColor: 'var(--color-bg-brand)',
          width,
          height: '100%',
          borderRadius: '9px',
        }}
      ></div>
    </div>
  );
};

export default ProgressChart;
