import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PickupPointsListQueryVariables = Types.Exact<{
  input: Types.PickupPointsFilterInput;
}>;


export type PickupPointsListQuery = { __typename?: 'Query', pickupPointsList: Array<{ __typename?: 'PickupPointType', id: number, name: string, useAddress: boolean }> };


export const PickupPointsListDocument = gql`
    query pickupPointsList($input: PickupPointsFilterInput!) {
  pickupPointsList(input: $input) {
    id
    name
    useAddress
  }
}
    `;

/**
 * __usePickupPointsListQuery__
 *
 * To run a query within a React component, call `usePickupPointsListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePickupPointsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePickupPointsListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePickupPointsListQuery(baseOptions: Apollo.QueryHookOptions<PickupPointsListQuery, PickupPointsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PickupPointsListQuery, PickupPointsListQueryVariables>(PickupPointsListDocument, options);
      }
export function usePickupPointsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PickupPointsListQuery, PickupPointsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PickupPointsListQuery, PickupPointsListQueryVariables>(PickupPointsListDocument, options);
        }
export type PickupPointsListQueryHookResult = ReturnType<typeof usePickupPointsListQuery>;
export type PickupPointsListLazyQueryHookResult = ReturnType<typeof usePickupPointsListLazyQuery>;
export type PickupPointsListQueryResult = Apollo.QueryResult<PickupPointsListQuery, PickupPointsListQueryVariables>;