import styled from 'styled-components';

export const ShipmentsModalWrapper = styled.div`
  max-width: 744px;
  height: 100%;
  padding: 40px;
  border-radius: 16px;
  background: var(--color-bg-secondary);

  .Grid {
    padding-bottom: 10px;
    border-bottom: 1px solid var(--color-control-bg-border-default);
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .TextField {
    width: 100%;

    .TextField-Input {
      color: var(--color-control-typo-default) !important;
    }
  }

  .archiveBtn {
    width: 100%;

    &.red {
      color: var(--color-typo-alert) !important;
    }
  }
`;