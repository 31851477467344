import styled from 'styled-components';

export const FieldsModalWrapper = styled.div`
  width: 472px;
  max-width: 472px;
  height: 100%;
  padding: 40px;
  border-radius: 16px;
  background: var(--color-bg-secondary);

  .field {
    padding-bottom: 5px;
    border-bottom: 1px solid var(--color-control-bg-border-default);
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .TextField {
    width: 100%;

    .TextField-Input {
      color: var(--color-control-typo-default) !important;
    }
  }

  .active {
    .Button-Icon {
      .Icon-Svg {
        fill: var(--color-typo-normal) !important;
      }
    }
  }
`;