import { Menu } from '@consta/header/Menu';
import { Card } from '@consta/uikit/Card';
import Margin from 'shared/ui/components/margin';
import { MenuWrapper } from './styled';

export type NavigationMenuItem = {
  label: string;
  href: string;
  active: boolean;
};

export const NavigationMenu = ({
  items,
  isAdmin = false,
}: {
  items: NavigationMenuItem[];
  isAdmin?: boolean;
}) => {
  return (
    <Margin ml={48}>
      <MenuWrapper>
        <Card style={{ maxWidth: isAdmin ? '100%' : undefined }}>
          <Menu
            items={items}
            getItemActive={(item) =>
              item.href === location?.pathname ||
              item.href === `${location?.pathname}${location?.search}`
            }
          />
        </Card>
      </MenuWrapper>
    </Margin>
  );
};

export default NavigationMenu;
