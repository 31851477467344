import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ConfirmPhoneMutationVariables = Types.Exact<{
  code: Types.Scalars['String']['input'];
}>;


export type ConfirmPhoneMutation = { __typename?: 'Mutation', confirmPhone: { __typename?: 'AuthPayloadType', accessToken: string } };


export const ConfirmPhoneDocument = gql`
    mutation confirmPhone($code: String!) {
  confirmPhone(code: $code) {
    accessToken
  }
}
    `;
export type ConfirmPhoneMutationFn = Apollo.MutationFunction<ConfirmPhoneMutation, ConfirmPhoneMutationVariables>;

/**
 * __useConfirmPhoneMutation__
 *
 * To run a mutation, you first call `useConfirmPhoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmPhoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmPhoneMutation, { data, loading, error }] = useConfirmPhoneMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useConfirmPhoneMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmPhoneMutation, ConfirmPhoneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmPhoneMutation, ConfirmPhoneMutationVariables>(ConfirmPhoneDocument, options);
      }
export type ConfirmPhoneMutationHookResult = ReturnType<typeof useConfirmPhoneMutation>;
export type ConfirmPhoneMutationResult = Apollo.MutationResult<ConfirmPhoneMutation>;
export type ConfirmPhoneMutationOptions = Apollo.BaseMutationOptions<ConfirmPhoneMutation, ConfirmPhoneMutationVariables>;