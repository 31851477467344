import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PackagesListQueryVariables = Types.Exact<{
  filter: Types.PackagesFilterInput;
  pagination: Types.PackagesPaginationInput;
}>;


export type PackagesListQuery = { __typename?: 'Query', packagesList: { __typename?: 'PaginatedPackagesType', total: number, items: Array<{ __typename?: 'PackageType', id: number, track: string, status: Types.DeliveryStatus, description?: string | null, weight?: number | null, createdAt: any, packageCost?: number | null, packageCount?: number | null, deliveryCost?: number | null, deliveryRate: { __typename?: 'DeliveryRateType', id: number, name: string, cost: number }, size?: { __typename?: 'PackageSizeType', width?: number | null, height?: number | null, length?: number | null } | null }> } };


export const PackagesListDocument = gql`
    query packagesList($filter: PackagesFilterInput!, $pagination: PackagesPaginationInput!) {
  packagesList(filter: $filter, pagination: $pagination) {
    items {
      id
      track
      status
      description
      weight
      createdAt
      packageCost
      packageCount
      deliveryCost
      deliveryRate {
        id
        name
        cost
      }
      size {
        width
        height
        length
      }
    }
    total
  }
}
    `;

/**
 * __usePackagesListQuery__
 *
 * To run a query within a React component, call `usePackagesListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePackagesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePackagesListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function usePackagesListQuery(baseOptions: Apollo.QueryHookOptions<PackagesListQuery, PackagesListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PackagesListQuery, PackagesListQueryVariables>(PackagesListDocument, options);
      }
export function usePackagesListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PackagesListQuery, PackagesListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PackagesListQuery, PackagesListQueryVariables>(PackagesListDocument, options);
        }
export type PackagesListQueryHookResult = ReturnType<typeof usePackagesListQuery>;
export type PackagesListLazyQueryHookResult = ReturnType<typeof usePackagesListLazyQuery>;
export type PackagesListQueryResult = Apollo.QueryResult<PackagesListQuery, PackagesListQueryVariables>;