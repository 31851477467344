import styled from 'styled-components';

export const StepBarContainer = styled.div`
  width: 35%;
  @media (max-width: 500px) {
    width: 100%;
  }
  .ProgressStepBarLine {
    width: 100%;
    .ProgressStepBarLine-Svg {
      rect {
        width: 100% !important;
      }
    }
  }
  .ProgressStepBarLine:after {
    position: relative; // Делает линию в неактивном состянии желтой, но зато на всю ширину. 
  }
  .ProgressStepBar {
    .ProgressStepBarItem_status_normal {
      .ProgressStepBarItem-PointText {
        color: var(--color-bg-secondary);
      }
    }
  }
`;

export const ContentCard = styled.div`
  & > .Card {
    width: 100%;
    background: var(--color-bg-default);
    padding: 28px 24px 40px;
    border-radius: 16px;
  }
`;

export const SuccessWrapper = styled.div`
  display: flex;
  justify-content: center;
  div {
    text-align: center;
  }
  .Button {
    @media (max-width: 500px) {
      width: 100%;
    }
  }
`;

export const MobileRadioGroupWrapper = styled.div`
  .Button {
    @media (max-width: 500px) {
      margin-left: auto;
      display: block;
    }
  }
`;
