import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddressesListQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type AddressesListQuery = { __typename?: 'Query', listAddresses: Array<{ __typename?: 'AddressType', id: number, country: Types.UserCountry, isFavorite: boolean, createdAt: any, isUsed: boolean, address: { __typename?: 'AddressDetailsType', city: string, street: string, home: string, flat: string } }> };


export const AddressesListDocument = gql`
    query addressesList {
  listAddresses {
    id
    country
    address {
      city
      street
      home
      flat
    }
    isFavorite
    createdAt
    isUsed
  }
}
    `;

/**
 * __useAddressesListQuery__
 *
 * To run a query within a React component, call `useAddressesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddressesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddressesListQuery({
 *   variables: {
 *   },
 * });
 */
export function useAddressesListQuery(baseOptions?: Apollo.QueryHookOptions<AddressesListQuery, AddressesListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddressesListQuery, AddressesListQueryVariables>(AddressesListDocument, options);
      }
export function useAddressesListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddressesListQuery, AddressesListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddressesListQuery, AddressesListQueryVariables>(AddressesListDocument, options);
        }
export type AddressesListQueryHookResult = ReturnType<typeof useAddressesListQuery>;
export type AddressesListLazyQueryHookResult = ReturnType<typeof useAddressesListLazyQuery>;
export type AddressesListQueryResult = Apollo.QueryResult<AddressesListQuery, AddressesListQueryVariables>;