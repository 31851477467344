import { Layout } from '@consta/header/Layout';

import styled from 'styled-components';

export const CustomHeader = styled(Layout)`
  img {
    width: 26px;
    height: 30px;
  }
  & > div {
    background-color: var(--color-bg-secondary);
    padding: 10px 40px;
    border-bottom: none;

    @media (max-width: 1100px) {
      padding: 10px 20px;
    }
  }
`;

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  .User {
    cursor: pointer;
    min-width: 200px;
  }
`;
