import { UserCountry } from 'types';
import { countriesMap } from 'shared/constants/countries.constants';
import { CitizenshipItem } from '../types';

const governmentIdLengthMap = {
  [UserCountry.Kz]: 12,
  [UserCountry.Ru]: 10,
  [UserCountry.Kg]: 14,
};

const governmentIdPlaceholderMap = {
  [UserCountry.Kz]: '123456789012',
  [UserCountry.Ru]: '1234567890',
  [UserCountry.Kg]: '12345678901234',
};

export const governmentIdLengthMapper = (country: UserCountry) => {
  return governmentIdLengthMap[country];
};

export const governmentIdPlaceholderMapper = (country: UserCountry) => {
  return governmentIdPlaceholderMap[country];
};

export const countries: CitizenshipItem[] = [
  {
    label: countriesMap.kz,
    value: UserCountry.Kz,
    phonePrefix: '7',
    countryCode: UserCountry.Kz,
  },
  {
    label: countriesMap.kg,
    value: UserCountry.Kg,
    phonePrefix: '996',
    countryCode: UserCountry.Kg,
  },
  {
    label: countriesMap.ru,
    value: UserCountry.Ru,
    phonePrefix: '7',
    countryCode: UserCountry.Ru,
  },
];

export const govIdFieldProperties = (
  citizenship: CitizenshipItem,
): {
  govIdTitle: string;
  govIdPlaceholder: string;
  govIdMaxLength: number;
} => {
  const govIdTitle = citizenship?.value === UserCountry.Ru ? 'ИНН' : 'ИИН';

  const govIdPlaceholder = governmentIdPlaceholderMapper(
    citizenship?.value || UserCountry.Kz,
  );
  const govIdMaxLength = governmentIdLengthMapper(
    citizenship?.value || UserCountry.Kz,
  );

  return {
    govIdTitle,
    govIdPlaceholder,
    govIdMaxLength,
  };
};
