import React, { FC, useState, useEffect } from 'react';
import { Modal } from '@consta/uikit/Modal';
import { Text } from '@consta/uikit/Text';
import { Button } from '@consta/uikit/Button';
import { Grid, GridItem } from '@consta/uikit/Grid';
import { TextField } from '@consta/uikit/TextField';
import { IconCheck } from '@consta/uikit/IconCheck';
import { IconEdit } from '@consta/uikit/IconEdit';
import { ShipmentType } from 'types';
import Margin from 'shared/ui/components/margin';
import Row from 'shared/ui/components/row';
import { ShipmentsModalWrapper } from './styled';

interface IProps {
  shipments: ShipmentType[];
  isModalOpen: boolean;
  onSubmit: (editedShipments: ShipmentType[]) => void;
  onClose: () => void;
}

const ShipmentsModal: FC<IProps> = ({ shipments, isModalOpen, onSubmit, onClose }: IProps) => {
  const [shipmentsCopy, setShipmentsCopy] = useState<ShipmentType[]>([]);
  const [activeField, setActiveField] = useState<number | undefined>();

  const onChangeName = (id: number, value: string) => {
    setShipmentsCopy(shipmentsCopy.map(item => {
      if (item.id === id) {
        return { ...item, name: value };
      }
      return item;
    }));
  };

  const onChangeArchive = (id: number) => {
    setShipmentsCopy(shipmentsCopy.map(item => {
      if (item.id === id) {
        return { ...item, isArchive: !item.isArchive };
      }
      return item;
    }));
  };

  const onSave = () => {
    onSubmit(shipmentsCopy);
    onClose();
  };

  useEffect(() => {
    setShipmentsCopy([...shipments]);
  }, [shipments, isModalOpen]);

  return (
    <Modal
      isOpen={isModalOpen}
      onClickOutside={() => onClose()}
      onEsc={() => onClose()}
    >
      <ShipmentsModalWrapper>
        <Margin mb={32}>
          <Text size='3xl' view='primary' weight='medium'>Настройка партий</Text>
        </Margin>
        {shipmentsCopy.map(item => (
          <Grid cols={12} colGap='m' key={item.id}>
            <GridItem col={8}>
              <TextField
                value={item.name}
                view='clear'
                onChange={({ value }) => onChangeName(item.id, value || '')}
                disabled={activeField !== item.id}
              />
            </GridItem>
            <GridItem col={1}>
              <Button
                onlyIcon
                form='round'
                iconLeft={activeField === item.id ? IconCheck : IconEdit}
                size='s'
                view={activeField === item.id ? 'primary' : 'ghost'}
                className={activeField === item.id ? 'buttonBlack' : undefined}
                onClick={() => setActiveField(activeField === item.id ? undefined : item.id)}
              />
            </GridItem>
            <GridItem col={3}>
              <Button
                size='s'
                view='clear'
                onClick={() => onChangeArchive(item.id)}
                label={item.isArchive ? 'Восстановить' : 'Убрать в архив'}
                className={item.isArchive ? 'archiveBtn' : 'archiveBtn red'}
              />
            </GridItem>
          </Grid>
        ))}
        <Margin mt={24}>
          <Row justify='flex-start' align='center'>
          <Margin mr={24} style={{ width: 'unset' }}>
            <Button
              size='s'
              label='Отменить'
              view='secondary'
              form='round'
              onClick={onClose}
            />
          </Margin>
          <Button
            label='Сохранить'
            size='s'
            view='primary'
            form='round'
            className='buttonBlack'
            onClick={onSave}
          />
        </Row>
        </Margin>
      </ShipmentsModalWrapper>
    </Modal>
  );
};

export default ShipmentsModal;
