import React, { FC } from 'react';
import readXlsxFile from 'read-excel-file';
import { FileField } from '@consta/uikit/FileField';
import { Button } from '@consta/uikit/Button';
import { IconAdd } from '@consta/uikit/IconAdd';
import { ImportPackageInput } from 'types';
import { showWarningNotification } from 'features/notifications/ui/showWarningNotifications';

interface IProps {
  onImport: (importPackages: ImportPackageInput[]) => void;
}

const ExcelImport: FC<IProps> = ({ onImport }: IProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const parseExcel = async (e: any) => {
    const rows = await readXlsxFile(e?.target?.files[0]);

    const packages: ImportPackageInput[] = [];

    for (const item of rows) {
      const track = `${item[0]}`.trim();

      if (track?.length < 3 || track?.length > 32) {
        showWarningNotification(`Неверный трек-номер ${track}`);
        continue;
      }

      const weight = parseFloat(item[1]?.toString().replace(',', '.'));

      if (isNaN(weight)) {
        showWarningNotification(`Неверный вес у посылки ${track}`);
        continue;
      }

      const length = item[2]?.toString()?.length ? parseFloat(item[2].toString().replace(',', '.')) : undefined;

      if (length && isNaN(length)) {
        showWarningNotification(`Неверная длина у посылки ${track}`);
        continue;
      }

      const width = item[3]?.toString()?.length ? parseFloat(item[3].toString().replace(',', '.')) : undefined;

      if (width && isNaN(width)) {
        showWarningNotification(`Неверная ширина у посылки ${track}`);
        continue;
      }

      const height = item[4]?.toString()?.length ? parseFloat(item[4].toString().replace(',', '.')) : undefined;

      if (height && isNaN(height)) {
        showWarningNotification(`Неверная высота у посылки ${track}`);
        continue;
      }

      const packageCount = item[5]?.toString()?.length ? parseInt(item[5].toString().replace(',', '.')) : undefined;

      if (packageCount && isNaN(packageCount)) {
        showWarningNotification(`Неверное количество посылок ${track}`);
        continue;
      }

      packages.push({
        track,
        weight,
        size: {
          length,
          width,
          height,
        },
        packageCount,
      });
    }

    onImport(packages);
  };
  return (
    <FileField
      id='FileFieldButton'
      onChange={e => parseExcel(e)}
    >
      {(props) => <Button
        {...props}
        view='secondary'
        size='s'
        form='round'
        label='Добавить посылки в партию'
        iconRight={IconAdd}
      />}
    </FileField>
  );
};

export default ExcelImport;