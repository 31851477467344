import styled from 'styled-components';

export const TextButtonWrapper = styled.div`
  .Button {
    color: var(--color-typo-link);
  }
 }
`;

export const ConfirmationInputsWrapper = styled.div`
  input[type=number] {
    height: 60px;
    width: 60px !important;
    margin-right: 12px;
    margin-bottom: 20px;
    background: transparent;
    border-radius: 8px;
    color: #FFFFFF;
    outline: none;
    -moz-appearance: textfield;
    
    &:last-child {
      margin-right: 0
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    @media (max-width: 330px) {
      width: 55px !important;
    }
  }
 }
`;