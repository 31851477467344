import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdminPackagesListQueryVariables = Types.Exact<{
  filter: Types.PackagesFilterInput;
  pagination: Types.PackagesPaginationInput;
}>;


export type AdminPackagesListQuery = { __typename?: 'Query', packagesList: { __typename?: 'PaginatedPackagesType', total: number, items: Array<{ __typename?: 'PackageType', id: number, track: string, boxNumber?: number | null, comment?: string | null, packageCount?: number | null, deliveryCost?: number | null, storeStatus: Types.StoreStatus, status: Types.DeliveryStatus, description?: string | null, weight?: number | null, rateCost: number, insuranceCost?: number | null, isPaid: boolean, isDamaged: boolean, createdAt: any, packageCost?: number | null, shipment?: { __typename?: 'ShipmentType', id: number, name: string } | null, user?: { __typename?: 'UserType', id: number, name?: string | null } | null, deliveryRate: { __typename?: 'DeliveryRateType', id: number, name: string, cost: number }, size?: { __typename?: 'PackageSizeType', width?: number | null, height?: number | null, length?: number | null } | null, deliveryAddress?: { __typename?: 'AddressType', id: number, country: Types.UserCountry, address: { __typename?: 'AddressDetailsType', city: string, street: string, home: string, flat: string } } | null, pickupPoint?: { __typename?: 'PickupPointType', id: number, name: string, country?: Types.UserCountry | null } | null }> } };


export const AdminPackagesListDocument = gql`
    query adminPackagesList($filter: PackagesFilterInput!, $pagination: PackagesPaginationInput!) {
  packagesList(filter: $filter, pagination: $pagination) {
    items {
      id
      track
      boxNumber
      comment
      packageCount
      deliveryCost
      storeStatus
      shipment {
        id
        name
      }
      user {
        id
        name
      }
      status
      description
      weight
      rateCost
      insuranceCost
      deliveryRate {
        id
        name
        cost
      }
      isPaid
      size {
        width
        height
        length
      }
      deliveryAddress {
        id
        country
        address {
          city
          street
          home
          flat
        }
      }
      pickupPoint {
        id
        name
        country
      }
      isDamaged
      createdAt
      packageCost
      packageCount
    }
    total
  }
}
    `;

/**
 * __useAdminPackagesListQuery__
 *
 * To run a query within a React component, call `useAdminPackagesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminPackagesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminPackagesListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useAdminPackagesListQuery(baseOptions: Apollo.QueryHookOptions<AdminPackagesListQuery, AdminPackagesListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminPackagesListQuery, AdminPackagesListQueryVariables>(AdminPackagesListDocument, options);
      }
export function useAdminPackagesListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminPackagesListQuery, AdminPackagesListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminPackagesListQuery, AdminPackagesListQueryVariables>(AdminPackagesListDocument, options);
        }
export type AdminPackagesListQueryHookResult = ReturnType<typeof useAdminPackagesListQuery>;
export type AdminPackagesListLazyQueryHookResult = ReturnType<typeof useAdminPackagesListLazyQuery>;
export type AdminPackagesListQueryResult = Apollo.QueryResult<AdminPackagesListQuery, AdminPackagesListQueryVariables>;