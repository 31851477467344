import styled from 'styled-components';

export const MobilePackagesContainer = styled.div`
  .Card {
    background: var(--color-bg-default);
    border-radius: 0;

    .Badge_status_system {
      color: var(--color-typo-primary);
    }

    .Badge_status_normal {
      color: var(--color-bg-secondary);
    }
  }
`;

export const MobileSortWindow = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: var(--color-bg-secondary);
  padding: 24px 0;
  z-index: 100;

  .sortOption {
    width: 100%;
    padding: 0 20px;
    border-radius: 0;
    border-left: 2px solid transparent;

    .Button-Label {
      text-align: left;
    }

    &.active {
      border-color: var(--color-typo-link);
    }
  }
`;