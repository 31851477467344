import styled from 'styled-components';

export const AnalyticsStatsWrapper = styled.div`
  .Card:first-child {
    background: var(--color-bg-default);
    .Text {
      color: var(--color-typo-primary);
    }
  }
  .Card {
    padding: 12px;
    background: var(--color-bg-brand);
    .Text {
      color: var(--color-bg-secondary);
    }
  }
`;

export const StatusStatWrapper = styled.div`
  margin-bottom: 12px;
  .GridItem {
    align-items: center;
    display: flex;
  }
  .GridItem:last-child {
    margin-left: 24px;
  }
`;

export const GraphWrapper = styled.div`
  height: 100%;
  .Card {
    height: 100%;
    padding: 12px;
    background: var(--color-bg-default);
    .Text {
      color: var(--color-typo-primary);
    }
    .recharts-responsive-container {
      height: 320px !important;
    }
  }
`;
