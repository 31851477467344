
import { FC } from 'react';
import { Theme } from '@consta/uikit/Theme';
import { LTLPreset } from 'shared/Theme/defaultLTLPreset';

const withConsta = <P extends object>(Component: FC<P>): FC<P> => {
  return function WithConsta(props) {
    return (
      <Theme preset={LTLPreset}>
        <Component {...props} />
      </Theme>
    );
  };
};
export default withConsta;
