import { GridItem } from '@consta/uikit/Grid';
import { TextField } from '@consta/uikit/TextField';
import { DeliveryRateType } from 'types';

export type RateRowType = {
  cost: number | null;
  id: number;
  isChanged: boolean;
};

const RateField = ({
  item,
  rates,
  disabled,
  handleRateChange,
}: {
  item: DeliveryRateType;
  rates: RateRowType[];
  disabled: boolean;
  handleRateChange: (value: number | null, id: number) => void;
}) => {
  const currentRate = rates?.find((rate) => rate.id === item.id);
  const currentRateCost = currentRate?.cost;

  const handleChange = (value: string | null) => {
    handleRateChange(Number(value) || null, item.id);
  };
  return (
    <GridItem key={`wrapper_${item.id}`}>
      <TextField
        type='number'
        key={`field_${item.id}`}
        incrementButtons
        placeholder='значение'
        required
        form='brick'
        width='default'
        size='s'
        disabled={disabled}
        value={
          currentRateCost && isNaN(currentRateCost) ? '' : `${currentRateCost}`
        }
        onChange={({ value }) => handleChange(value)}
      />
    </GridItem>
  );
};

export default RateField;
