import React, { useEffect, useRef, useState } from 'react';
import { Text } from '@consta/uikit/Text';
import { TextField } from '@consta/uikit/TextField';
import { SortByProps, Table, TableRow } from '@consta/uikit/Table';
import dayjs from 'dayjs';
import { IconSearch } from '@consta/uikit/IconSearch';
import { useSearchParams } from 'react-router-dom';
import { Modal } from '@consta/uikit/Modal';
import { useUsersListQuery } from 'features/users/api/usersList.generated';
import { Maybe, UsersOrderInput } from 'types';
import { formatPhoneNumber } from 'entities/phone/utils';
import UserStatCards from 'widgets/user-stats';
import ClientEditForm, { UserDataType } from 'widgets/client-edit';
import { usersColumns } from 'entities/user/utils';
import { useIsInViewport } from 'shared/hooks/useIsInViewport';
import { TableContainer, UsersHeader } from './styled';

export type UserRow = TableRow & UserDataType;

const UsersPage: React.FC = () => {
  const [debouncedQ, setDebouncedQ] = useState<string | undefined>();
  const pageSize = 20;
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);
  const [sortOrder, setSortOrder] = useState<Maybe<UsersOrderInput>>(null);
  const [q, setQ] = useState<string | undefined>();
  const [users, setUsers] = useState<UserRow[]>([]);
  const footerRef = useRef(document.getElementById('footer'));
  const inViewport = useIsInViewport(footerRef);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isEditOpen, setIsEditModalOpen] = useState(false);
  const [userForEdit, setUserForEdit] = useState<UserDataType | null>(null);

  const { data: usersData, refetch } = useUsersListQuery({
    variables: {
      filter: {
        q: debouncedQ || undefined,
      },
      pagination: {
        offset: offset * pageSize,
        limit: pageSize,
        order: sortOrder || undefined,
      },
    },
  });

  const handleSortingUpdated = (value: Maybe<SortByProps<UserRow>>) => {
    setOffset(0);
    if (value) {
      const sort = {
        [value.sortingBy]: value?.sortOrder.toUpperCase(),
      };
      setSortOrder(sort);
    } else {
      setSortOrder(null);
    }
  };

  const onRowClick = ({ id }: { id: string }) => {
    const userData = users?.find((u) => u.id === id);
    setUserForEdit({
      id: String(userData?.id),
      phone: userData?.phone && formatPhoneNumber(userData.phone),
      email: userData?.email || '',
      name: userData?.name || '',
      nameEn: userData?.nameEn || '',
      createdAt:
        (userData?.createdAt &&
          dayjs(userData.createdAt).format('DD.MM.YYYY')) ||
        dayjs().format('DD.MM.YYYY'),
      country: userData?.country || undefined,
      govId: userData?.govId || '',
    });
    setIsEditModalOpen(true);
  };

  useEffect(() => {
    const items = usersData?.usersList?.items;
    const preparedUsers =
      items?.map((user) => ({
        ...user,
        id: String(user.id),
        phone: user?.phone && formatPhoneNumber(user.phone),
        createdAt:
          user?.createdAt && dayjs(user.createdAt).format('DD.MM.YYYY'),
      })) || [];

    if (!offset) {
      setTotal(usersData?.usersList?.total || 0);
    }

    if (offset && total) {
      setUsers([...users, ...preparedUsers]);
    } else {
      setUsers(preparedUsers);
    }
  }, [usersData]);

  useEffect(() => {
    if (inViewport && (offset + 1) * pageSize <= total) {
      setOffset(offset + 1);
    }
  }, [inViewport]);

  useEffect(() => {
    for (const entry of searchParams.entries()) {
      switch (entry[0]) {
        case 'q':
          setQ(entry[1]);
          setDebouncedQ(entry[1]);
          break;
        default:
          break;
      }
    }

    refetch();
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedQ(q);
      if (q) {
        searchParams.set('q', q);
      } else {
        searchParams.delete('q');
      }
      setSearchParams(searchParams);
    }, 300);
    return () => clearTimeout(timeoutId);
  }, [q]);

  const updateSearch = (value: string | undefined) => {
    setOffset(0);
    setQ(value);
  };

  return (
    <>
      <Modal className='userEditModal' isOpen={isEditOpen}>
        <ClientEditForm
          user={userForEdit}
          onCloseModal={() => setIsEditModalOpen(false)}
        />
      </Modal>
      <UsersHeader>
        <Text size='4xl' view='primary' weight='medium'>
          Пользователи
        </Text>
        <TextField
          type='text'
          placeholder='Поиск пользователя'
          form='round'
          width='full'
          size='s'
          value={q}
          leftSide={IconSearch}
          onChange={({ value }) => updateSearch(value || undefined)}
        />
      </UsersHeader>
      <UserStatCards />
      <TableContainer>
        <Table
          // @ts-ignore
          columns={usersColumns}
          rows={users}
          onSortBy={handleSortingUpdated}
          onRowClick={onRowClick}
        />
      </TableContainer>
    </>
  );
};

export default UsersPage;
