import { FC } from 'react';
import { useRoutes } from 'react-router-dom';
import { routes as clientRoutes } from 'app/routes/client-routes';
import { routes as adminRoutes } from 'app/routes/admin-routes';

const routes = process.env.REACT_APP_BUILD_TARGET === 'admin' ? adminRoutes : clientRoutes;

const AppRouter: FC = () => {
  const element = useRoutes(routes);

  return <>{element}</>;
};

export default AppRouter;
