import React, { FC, useState } from 'react';
import { SortByProps } from '@consta/uikit/Table';
import { Grid, GridItem } from '@consta/uikit/Grid';
import { Badge } from '@consta/uikit/Badge';
import { Card } from '@consta/uikit/Card';
import { Button } from '@consta/uikit/Button';
import { IconArrowRight } from '@consta/uikit/IconArrowRight';
import { SelectedFilters } from '@consta/uikit/__internal__/src/components/Table/filtering';
import { useNavigate } from 'react-router-dom';
import { PackageRow } from 'pages/packages/types';
import InfoField from 'features/detailed-package/info-field';
import { DeliveryStatus, Maybe, StockCode } from 'types';
import Margin from 'shared/ui/components/margin';
import Row from 'shared/ui/components/row';
import { statusesBadges, statusesLabels } from 'shared/constants/statuses.constants';
import { MobilePackagesContainer, MobileSortWindow } from './styled';

interface IProps {
  packages: PackageRow[];
  stockCode: StockCode;
  onChangeSort: (value: Maybe<SortByProps<PackageRow>>) => void;
  onChangeFilter: ({ status }: SelectedFilters) => void;
};

type Filter = {
  label: string,
  value: Maybe<DeliveryStatus>,
}

const filters: Filter[] = [
  {
    label: 'Все',
    value: null,
  },
  {
    label: 'Ожидаются',
    value: DeliveryStatus.Created,
  },
  {
    label: 'В пути',
    value: DeliveryStatus.InTransit,
  },
  {
    label: 'Готовы к выдаче',
    value: DeliveryStatus.ReadyForDelivery,
  },
];

type SortOption = {
  label: string;
  value: SortByProps<PackageRow>;
}

const sortOptions: SortOption[] = [
  {
    label: 'По дате добавления ↑',
    value: { sortingBy: 'createdAt', sortOrder: 'desc' },
  },
  {
    label: 'По дате добавления ↓',
    value: { sortingBy: 'createdAt', sortOrder: 'asc' },
  },
  {
    label: 'Сначала тяжелые',
    value: { sortingBy: 'weight', sortOrder: 'desc' },
  },
  {
    label: 'Сначала легкие',
    value: { sortingBy: 'weight', sortOrder: 'asc' },
  },
  {
    label: 'По стоимости доставки ↑',
    value: { sortingBy: 'deliveryCost', sortOrder: 'desc' },
  },
  {
    label: 'По стоимости доставки ↓',
    value: { sortingBy: 'deliveryCost', sortOrder: 'desc' },
  },
  {
    label: 'По количеству мест ↑',
    value: { sortingBy: 'packageCount', sortOrder: 'desc' },
  },
  {
    label: 'По количеству мест ↓',
    value: { sortingBy: 'packageCount', sortOrder: 'desc' },
  },
]

const MobilePackages: FC<IProps> = ({ packages, stockCode, onChangeSort, onChangeFilter }: IProps) => {
  const [status, setStatus] = useState<Maybe<DeliveryStatus>>(null);
  const [sort, setSort] = useState<Maybe<SortOption>>(null);
  const [showSortWindow, setShowSortWindow] = useState(false);

  const handleChangeStatus = (value: Maybe<DeliveryStatus>) => {
    setStatus(value);
    onChangeFilter({ status: { selected: value ? [value] : [] } });
  };

  const handleChangeFilter = (value: Maybe<SortOption>) => {
    setSort(value);
    setShowSortWindow(false);
    onChangeSort(value?.value || null);
  };

  const navigate = useNavigate();

  return (
    <MobilePackagesContainer>
      <Button
        view='clear'
        size='s'
        label={sort?.label || 'Сортировка'}
        onClick={() => setShowSortWindow(true)}
      />
      <Margin mb={12} mt={8}>
        <Row justify='flex-start' align='center' style={{flexWrap: 'wrap'}}>
          {filters.map(item => (
            <Margin key={item.label} mr={8} mb={8} style={{ width: 'unset' }}>
              <Button
                view={item.value === status ? 'primary' : 'ghost'}
                label={item.label}
                className={item.value === status ? 'buttonBlack' : undefined}
                size='s'
                form='round'
                onClick={() => handleChangeStatus(item.value)}
              />
            </Margin>
          ))}
        </Row>
      </Margin>
      {packages.map(item => (
        <Margin mb={12} key={item.id}>
          <Card
            verticalSpace='m'
            horizontalSpace='s'
            shadow={false}
            onClick={() => navigate(`/packages/${item.id}`)}
          >
            <Badge
              status={statusesBadges[item.status]}
              label={statusesLabels[item.status]}
            />
            <Margin mt={16} mb={20}>
              <Grid cols={2}>
                <GridItem>
                  <InfoField label='Трек-номер' value={item.track || ''} />
                </GridItem>
                <GridItem>
                  <InfoField label='Дата добавления' value={item.createdAt || ''} />
                </GridItem>
              </Grid>
            </Margin>
            <Margin mb={20}>
              <InfoField label='Описание' value={item.description || ''} />
            </Margin>
            <Grid cols={2}>
              <GridItem>
                {stockCode === StockCode.Kr
                  ? <InfoField
                      label='Количество мест'
                      value={item.packageCount ? `${item.packageCount}` : ''}
                    />
                  : <InfoField
                      label='Стоимость доставки'
                      value={item.deliveryCost ? `$${item.deliveryCost}` : ''}
                    />
                }
              </GridItem>
              <GridItem>
                <InfoField label='Вес' value={item.weight ? `${item.weight} кг` : ''} />
              </GridItem>
            </Grid>
          </Card>
        </Margin>
      ))}
      {showSortWindow && <MobileSortWindow>
        <Margin mb={20}>
          <Row justify='flex-end' align='center'>
            <Button view='clear'
              size='s'
              label='Отменить'
              onClick={() => handleChangeFilter(null)}
            />
          </Row>
        </Margin>
        {sortOptions
          .filter(item => !item.label.includes(stockCode === StockCode.Kr ? 'По стоимости доставки' : 'По количеству мест'))
          .map(item => (
            <Button
              key={item.label}
              className={sort?.label === item.label ? 'sortOption active' : 'sortOption'}
              view='clear'
              label={item.label}
              onClick={() => handleChangeFilter(item)}
              iconRight={IconArrowRight}
            />
          ))
        }
      </MobileSortWindow>}
    </MobilePackagesContainer>
  )
};

export default MobilePackages;