import React, { FC, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, GridItem } from '@consta/uikit/Grid';
import { Button } from '@consta/uikit/Button';
import { Card } from '@consta/uikit/Card';
import { Select } from '@consta/uikit/Select';
import { Text } from '@consta/uikit/Text';
import { TextField } from '@consta/uikit/TextField';
import {
  DetailedInformationContainer,
  DetailedInformationFormWrapper,
  ActionsWrapper,
} from 'pages/detailed-information/styled';
import { useUpdateUserMutation } from 'features/detailed-information/api/updateUser.generated';
import { CitizenshipItem } from 'entities/countries/types';
import { countries, govIdFieldProperties } from 'entities/countries/utils';
import { UserCountry } from 'types';
import CountryLabel from 'features/country-label';
import PhoneInput from 'features/phone-input';
import { useUser } from 'entities/user/index';
import Margin from 'shared/ui/components/margin';

import logo from '../../assets/logo.png';

const [defaultCountry] = countries;

const DetailedInformationPage: FC = () => {
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [nameEn, setNameEn] = useState('');
  const [surnameEn, setSurnameEn] = useState('');
  const [phone, setPhone] = useState('');
  const [phonePrefix, setPhonePrefix] =
    useState<CitizenshipItem>(defaultCountry);
  const [citizenship, setCitizenship] = useState<CitizenshipItem | undefined>();
  const [govId, setGovId] = useState('');

  const [nameError, setNameError] = useState(false);
  const [surnameError, setSurnameError] = useState(false);
  const [nameEnError, setNameEnError] = useState(false);
  const [surnameEnError, setSurnameEnError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [citizenshipError, setCitizenshipError] = useState(false);
  const [govIdError, setGovIdError] = useState(false);

  const { refetch: refetchUser } = useUser();

  const isMobile = window.innerWidth < 640;
  const isTablet = window.innerWidth < 1100 && window.innerWidth >= 640;
  const smallScreen = isMobile || isTablet;
  
  const navigate = useNavigate();
  const [updateUser] = useUpdateUserMutation();

  const onSubmit = async () => {
    if (!formDisabled) {
      const { data } = await updateUser({
        variables: {
          input: {
            name: `${name} ${surname}`,
            nameEn: `${nameEn} ${surnameEn}`,
            phone: `${phonePrefix.phonePrefix}${phone}`,
            country: citizenship?.value,
            govId,
          },
        },
      });

      await refetchUser();

      if (data?.updateUser) {
        navigate(data.updateUser.isPhoneConfirmed ? '/' : '/confirm-phone');
      }
    }
  };

  const handleNameChange = (value: string) => {
    const cleanValue = value.replace(/[^а-яё]/i, '');
    setName(cleanValue);
    setNameError(false);
  };

  const handleNameEnChange = (value: string) => {
    const cleanValue = value.replace(/[^A-Za-z]/gi, '');
    setNameEn(cleanValue);
    setNameEnError(false);
  };

  const handleSurnameChange = (value: string) => {
    const cleanValue = value.replace(/[^а-яё]/i, '');
    setSurname(cleanValue);
    setSurnameError(false);
  };

  const handleSurnameEnChange = (value: string) => {
    const cleanValue = value.replace(/[^A-Za-z]/gi, '');
    setSurnameEn(cleanValue);
    setSurnameEnError(false);
  };

  const handlePhoneChange = (value: string) => {
    if (value && value.length > phoneMaxLength) return;

    setPhone(value);
    setPhoneError(false);
  };

  const handlePhonePrefixChange = (item: CitizenshipItem | null) => {
    if (!item?.phonePrefix) return;

    setPhonePrefix(item);
  };

  const handleCitizenshipChange = (item: CitizenshipItem | null) => {
    if (!item?.value) return;

    setCitizenship(item);
    setGovId('');
    setCitizenshipError(false);
  };

  const handleGovIdChange = (value: string) => {
    if (value && value.length > govIdMaxLength) return;

    setGovId(value);
    setGovIdError(false);
  };

  const handleNameBlur = () => {
    if (!name) {
      setNameError(true);
    }
  };

  const handleNameEnBlur = () => {
    if (!nameEn) {
      setNameEnError(true);
    }
  };

  const handleSurnameBlur = () => {
    if (!surname) {
      setSurnameError(true);
    }
  };

  const handleSurnameEnBlur = () => {
    if (!surnameEn) {
      setSurnameEnError(true);
    }
  };

  const handlePhoneBlur = () => {
    if (!phone) {
      setPhoneError(true);
    }
  };

  const handleCitizenshipBlur = () => {
    if (!citizenship?.value) {
      setCitizenshipError(true);
    }
  };

  const { govIdTitle, govIdPlaceholder, govIdMaxLength } = govIdFieldProperties(
    citizenship || defaultCountry,
  );

  const phoneMaxLength = phonePrefix?.countryCode === UserCountry.Kg ? 9 : 10; // Kyrgyzstan 12 цифр в номере телефона (3 из них в коде)

  const handleGovIdBlur = () => {
    if (!govId || govId?.length !== govIdMaxLength) {
      setGovIdError(true);
    }
  };

  const formDisabled = useMemo(() => {
    return !name ||
      !surname ||
      !nameEn ||
      !surnameEn ||
      !phone ||
      !citizenship ||
      !govId ||
      govId?.length !== govIdMaxLength;
  }, [name, nameEn, surname, surnameEn, phone, citizenship, govId]);

  return (
    <DetailedInformationContainer>
      <Card shadow={false}>
        <DetailedInformationFormWrapper>
          <img src={logo} alt='logo' />
          <Margin mt={36} mb={12}>
            <Text size={isMobile ? 'xl' : '3xl'} view='primary' align='center'>
              Давайте познакомимся
            </Text>
          </Margin>
          <Margin mb={12}>
            <Text size={isMobile ? 'xs' : 's'} view='primary' align='center'>
              Для завершения регистрации заполните информацию о себе и
              подтвердите номер телефона.
            </Text>
          </Margin>
          <Margin mb={48}>
            <Text size={isMobile ? 'xs' : 's'} view='primary' align='center'>
              Эти данные понадобятся для заказа посылок через наш сервис.
            </Text>
          </Margin>
          <Grid
            cols={1}
            rowGap='xl'
            colGap='l'
            breakpoints={{
              m: {
                cols: 3,
                colGap: 'm',
              },
              s: {
                cols: 2,
                colGap: 'm',
              },
            }}
          >
            <GridItem order={isTablet ? -1 : 0}>
              <TextField
                label='Ваше имя латинскими буквами'
                type='text'
                placeholder='ivan'
                required
                form='round'
                width='full'
                size={smallScreen ? 's' : 'm'}
                value={nameEn}
                onChange={({ value }) => handleNameEnChange(value || '')}
                onBlur={handleNameEnBlur}
                status={nameEnError ? 'alert' : undefined}
                caption={nameEnError ? 'Поле не заполнено' : undefined}
              />
            </GridItem>
            <GridItem order={isTablet ? 0 : 0}>
              <TextField
                label='Ваше имя на кириллице'
                type='text'
                placeholder='Иван'
                required
                form='round'
                width='full'
                size={smallScreen ? 's' : 'm'}
                value={name}
                onChange={({ value }) => handleNameChange(value || '')}
                onBlur={handleNameBlur}
                status={nameError ? 'alert' : undefined}
                caption={nameError ? 'Поле не заполнено' : undefined}
              />
            </GridItem>
            <GridItem order={isTablet ? 1 : isMobile ? 1 : 0}>
              <PhoneInput
                smallScreen={smallScreen}
                countries={countries}
                phonePrefix={phonePrefix}
                phone={phone}
                phoneError={phoneError}
                handlePhonePrefixChange={handlePhonePrefixChange}
                handlePhoneChange={handlePhoneChange}
                handlePhoneBlur={handlePhoneBlur}
              />
            </GridItem>
            <GridItem order={isTablet ? -1 : 0}>
              <TextField
                label='Ваша фамилия латинскими буквами'
                type='text'
                placeholder='ivanov'
                required
                form='round'
                width='full'
                size={smallScreen ? 's' : 'm'}
                value={surnameEn}
                onChange={({ value }) => handleSurnameEnChange(value || '')}
                onBlur={handleSurnameEnBlur}
                status={surnameEnError ? 'alert' : undefined}
                caption={surnameEnError ? 'Поле не заполнено' : undefined}
              />
            </GridItem>
            <GridItem order={isTablet ? 0 : 0}>
              <TextField
                label='Ваша фамилия на кириллице'
                type='text'
                placeholder='Иванов'
                required
                form='round'
                width='full'
                size={smallScreen ? 's' : 'm'}
                value={surname}
                onChange={({ value }) => handleSurnameChange(value || '')}
                onBlur={handleSurnameBlur}
                status={surnameError ? 'alert' : undefined}
                caption={surnameError ? 'Поле не заполнено' : undefined}
              />
            </GridItem>
            <GridItem order={isTablet ? 1 : 1}>
              <Select
                label='Ваше гражданство'
                placeholder='Выберите варинат'
                items={countries}
                form='round'
                size={smallScreen ? 's' : 'm'}
                required
                value={citizenship}
                getItemLabel={(item: CitizenshipItem) => item.label || ''}
                getItemKey={(item: CitizenshipItem) => item.value}
                status={citizenshipError ? 'alert' : undefined}
                caption={citizenshipError ? 'Поле не заполнено' : undefined}
                onBlur={handleCitizenshipBlur}
                onChange={({ value }) => handleCitizenshipChange(value)}
                renderItem={({
                  item,
                  active,
                  hovered,
                  onClick,
                  onMouseEnter,
                }) => (
                  <CountryLabel
                    item={item}
                    active={active}
                    hovered={hovered}
                    onClick={onClick}
                    onMouseEnter={onMouseEnter}
                  />
                )}
              />
            </GridItem>
          </Grid>
          {citizenship ? (
            <Margin mt={24} mb={48}>
              <ActionsWrapper>
                <Grid cols={1} xAlign={isTablet ? 'right' : 'center'}>
                  <GridItem>
                    <TextField
                      label={govIdTitle}
                      type='number'
                      placeholder={govIdPlaceholder}
                      required
                      form='round'
                      width='full'
                      size={smallScreen ? 's' : 'm'}
                      value={govId}
                      incrementButtons={false}
                      onChange={({ value }) => handleGovIdChange(value || '')}
                      onBlur={handleGovIdBlur}
                      status={govIdError ? 'alert' : undefined}
                      caption={govIdError ? 'Поле не заполнено' : undefined}
                    />
                  </GridItem>
                </Grid>
              </ActionsWrapper>
            </Margin>
          ) : (
            <Margin mt={48} />
          )}
          <Margin>
            <ActionsWrapper>
              <Grid cols={1} xAlign='center'>
                <GridItem>
                  <Button
                    label='Завершить регистрацию'
                    form='round'
                    size='l'
                    width='full'
                    disabled={formDisabled}
                    onClick={onSubmit}
                  />
                </GridItem>
              </Grid>
            </ActionsWrapper>
          </Margin>
          <Margin mt={12} mb={isMobile ? 24 : 0}>
            <Text size='xs' view='ghost' align='center'>
              Нажимая на кнопку вы соглашаетесь с политикой
            </Text>
            <Text size='xs' view='ghost' align='center'>
              конфиденциальности и публичной офертой
            </Text>
          </Margin>
        </DetailedInformationFormWrapper>
      </Card>
    </DetailedInformationContainer>
  );
};

export default DetailedInformationPage;
