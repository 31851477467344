import styled from 'styled-components';

export const ModalHeader = styled.div`
  height: 70px;
  background: var(--color-bg-secondary);
  align-items: center;
  display: flex;
  padding-left: 24px;
  padding-right: 12px;
  justify-content: space-between;
`;

export const EditClientBodyWrapper = styled.div`
  height: 100%;
  .TextField-InputContainer_form_clearClear {
    border-top-width: 0;
  }
  .Select-SelectContainer_form_clearClear .Select-Control {
    border-top-width: 0;
  }
  .Grid {
    height: 100%;
  }
  .GridItem {
    padding: 24px;
    height: 100%;
  }
  .GridItem:first-child {
    border-right: 1px solid var(--color-bg-border);
  }
  .TextField-Body {
    margin-bottom: 24px;
  }
  .Select-Body {
    margin-bottom: 24px;
  }
  .infoContainer {
    margin-bottom: 24px;
  }
  .Text {
    margin-bottom: 6px;
  }
`;
