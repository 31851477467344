import styled from 'styled-components';

export const FlagWrapper = styled.div`
  width: 16px;
  height: 12px;
`;

export const PhoneFlagWrapper = styled.div`
  width: 20px;
  height: 15px;

  @media (max-width: 1100px) {
    width: 16px;
    height: 12px;
  }
`;
