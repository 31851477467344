import React from 'react';
import { RU, KZ, KG, US, EU, KR } from 'country-flag-icons/react/3x2'
import { StockCode, UserCountry } from 'types';
import { countriesMap } from 'shared/constants/countries.constants';

const Flag = ({ variant }: { variant: UserCountry | StockCode }) => {
    const flagMap = {
        ru: <RU title={countriesMap.ru} />,
        kz: <KZ title={countriesMap.kz} />,
        kg: <KG title={countriesMap.kg}/>,
        us: <US />,
        eu: <EU />,
        kr: <KR />,
    };

    return flagMap[variant];
};

export default Flag;