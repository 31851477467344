import React, { FC } from 'react';
import { Text } from '@consta/uikit/Text';
import Margin from 'shared/ui/components/margin';

interface IProps {
  label: string,
  value: string,
};

const InfoField: FC<IProps> = ({ label, value }: IProps) => {
  const isMobile = window.innerWidth < 640;
  const isTablet = window.innerWidth < 1100 && window.innerWidth >= 640;
  const smallScreen = isMobile || isTablet;

  return (
    <>
      <Margin mb={smallScreen ? 8 : 12}>
        <Text
          size={smallScreen ? isMobile ? '2xs' : 'm' : 'l'}
          view={isMobile ? 'secondary' : 'primary'}
          transform={isMobile ? 'uppercase' : undefined}
        >
          {label}
        </Text>
      </Margin>
      <Text size={isMobile ? '2xs' : 's'} view={isMobile ? 'primary' : 'secondary'}>
        {value}
      </Text>
    </>
  );
};

export default InfoField;